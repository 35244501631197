import {isEmpty} from '../util/common'
import { createActions, handleActions } from 'redux-actions'
/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    displayError: payload => payload,
    displayBtnFlg: payload => payload,
    close: () => ({}),
    checkOutClose: () => ({}),
  },
  { prefix: 'hoshino/error'},
)
/*********************************
 * REDUCER
 ********************************/
export const initialState = {
  isOpen: false,
  errorMessages: '',
  note: '',
  displayBtnFlg: null
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.displayError]: (state, action) => {
    let mes = action.payload
    mes = !isEmpty(mes) && mes.length > 500 ? mes.slice(0, 500) + '...' : mes

    return({
      ...state,
      isOpen: true,
      errorMessages: mes,
      note: action.note,
    })
  },

  [actions.displayBtnFlg]: (state, action) => {
    let flg = action.payload
    return({
      ...state,
      displayBtnFlg:flg
    })
  },

  [actions.close]: state => ({
    ...state,
    isOpen: false,
    errorMessages: '',
    note: '',
    displayBtnFlg: ''
  }),
}, initialState)

export default reducer
