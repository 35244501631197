import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
} from 'reactstrap'
import { containerStyle } from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import Btn from '../../components/atomics/Button'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import {actions as paymentAction} from '../../modules/payment'


class Instructions extends Component {
  render() {
    const {t, i18n} = this.props

    let title
    console.log(title)
    let test = 3
    if(test===1){
      title = t('カードを端末に差し込んでください')

    }else if(test===2){
      title = t('QRを端末にかざしてください')
    }else{
      title = t('現金支払いはフロントでの対応となります')
    }
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={4}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            {test !== 3?
              <MsgArea>
                <TestImg src="https://placehold.jp/cccccc/ffffff/700x300.png?text=%E3%82%A4%E3%83%A1%E3%83%BC%E3%82%B8" alt=""/>
              </MsgArea>
              :null}
            {test === 3?
              <div>
                <MsgArea>
                  <Msg>
                    <p>{t('現金支払いはフロントにて承ります')}</p>
                    <p>{t('レシートをお持ちの上、フロントカウンターへお越しください')}</p>
                  </Msg>
                </MsgArea>
                <div>
                  <Btn btnLabel={t('別の支払方法を選択')} btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' style={{right: "auto",}} OnClick={() => this.props.clickBtn('/checkin/payment')}/>
                  <Btn btnLabel={t('現金で支払う')} btnFloat='right' btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' OnClick={() => this.props.cashPayment()}/>
                </div>
              </div>
              :null}
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


Instructions.propTypes = {
}

const mapStateToProps = state => {
  return {
    selectedReserve: state.reserve.selectedReserve
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    cashPayment: () => {
      dispatch(paymentAction.cashPayment())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Instructions))

/* CSS定義 */

const TestImg = styled.img`
  width:80vw;
  margin:0 auto;
`
const MsgArea = styled.div`
  font-size:2.4rem;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
`
const Msg = styled.p`
  font-size:2.4rem;
  text-align: center;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`