import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
} from 'reactstrap'
import {containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'

class Completed extends Component {
  componentDidMount() {

    this.setState(
      {
        to_top_timer: setTimeout(() => {
          this.props.backTop()
        }, 3000)
      }
    )
  }
  render() {
    const {t} = this.props
    return (
      <section style={{backgroundColor:'#fff', height:'75vw', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={5}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <MsgArea style={{marginTop:'20vh', whiteSpace:'pre-line'}}>
              <p>{t('チェックイン手続きが完了しました')}</p>
              <p>{t('ごゆっくりおくつろぎください')}</p>
            </MsgArea>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


Completed.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    backTop: () => {
      dispatch(push('/checkin'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Completed))

/* CSS定義 */
const MsgArea = styled.div`
  font-size:3vw;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
  width:60vw;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`