import React, {Component} from 'react'
import styled from 'styled-components'
import {BACK_COLOR} from '../../constants/common'
import {Container} from 'reactstrap'

class CustomContainer extends Component {
  render() {
    const {children, ...rest} = this.props
    return (
      <StyledContainer {...rest}>
        {children}
      </StyledContainer>
    );
  }
}

CustomContainer.propTypes = {
}

export default CustomContainer

const StyledContainer = styled(Container)`
  height: ${props => props.height ? props.height: '100vh'};
  width: 100vw;
  display: flex;
  align-items: ${props => props.center ? 'center' : ''};
  padding-top: ${props => props.center ? '' : '5vh'};
  background: ${BACK_COLOR};
`