import { createActions, handleActions } from 'redux-actions'
import {LOGIN_URL} from '../constants/api-url'
import { select, put, takeEvery } from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {actions as MiddlewareAction} from './middleware'
/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    initLogin: () => ({}),
    login: payload => payload,
    successLogin: payload => payload,
    forceLogout: () => ({}),
  },
  { prefix: 'hoshino/auth'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  user: {},
}
const reducer = handleActions({
  [actions.initLogin]: () => initialState,
  [actions.successLogin]: (state, action) => ({...state, ...action.payload})
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* authSaga() {
  yield takeEvery(actions.login, login)
  yield takeEvery(actions.successLogin, successLogin)
}

function* login() {
  const {form: {login: {values}}} = yield select()
  const payload = {
    // PANDA_API_URLは設定から除外している
    // url: PANDA_API_URL + LOGIN_URL,
    method: 'POST',
    request: {...values},
    reqAuth: false,
    successCB: actions.successLogin,
  }
  yield put(MiddlewareAction.api(payload))
}

function* successLogin(action) {
  const {user} = action.payload
  localStorage.setItem('userid', user.id)
  yield put(push('/checkin'))
}