import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
} from 'reactstrap'
import {GREY_COLOR} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import { containerStyle } from '../../constants/common'
import Btn from '../../components/atomics/Button'
import Wizard from '../../components/atomics/StepWizard'
import {_formatYMD_UTC} from '../../util/common'
import IconBtn from '../../components/atomics/IconButton'
import {actions as confirmAction} from '../../modules/confirm'

class Confirm extends Component {
  render() {
    const {selectedReserve, t, i18n, flg, qrsearchflg} =this.props
    const mealInfo = MEAL_INFO.find(item => item.id === selectedReserve[0].detail.MealKbn)
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn(flg, qrsearchflg)}/>
          <Wizard index={2}/> 
          <IconBtn btnLabel={t('中止')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/cancel.png' OnClick={() => this.props.clickCancel('/checkin', msg)}/>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('宿泊情報を確認してください')}/>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <ConfirmBlock style={{boxSizing:'border-box'}}>
                <ConfirmHeader xs={3}>{t('氏名')}</ConfirmHeader>
                <ConfirmData xs={9}>{selectedReserve[0].ReservationGuestName} {t('様')}</ConfirmData>
              </ConfirmBlock>
              <ConfirmBlock>
                <ConfirmHeader xs={3}>{t('チェックイン日')}</ConfirmHeader>
                <ConfirmData xs={3}>{_formatYMD_UTC(selectedReserve[0].CheckInDate)}</ConfirmData>
                <ConfirmHeader xs={3}>{t('チェックアウト日')}</ConfirmHeader>
                <ConfirmData xs={3}>{_formatYMD_UTC(selectedReserve[0].CheckOutDate)}</ConfirmData>
              </ConfirmBlock>
              <ConfirmBlock>
                <ConfirmHeader xs={3}>{t('宿泊数')}</ConfirmHeader>
                <ConfirmData xs={3}>{selectedReserve[0].RoomNights}{t('泊')}</ConfirmData>
                <ConfirmHeader className={[i18n.language]} xs={3}>{t('宿泊人数')}</ConfirmHeader>
                <ConfirmData xs={3}>{selectedReserve[0].NumberOfGuests}{t('名')}</ConfirmData>
              </ConfirmBlock>
              <ConfirmBlock>
                <ConfirmHeader xs={3} style={{borderBottom:'none'}}>{t('御食事')}</ConfirmHeader>
                <ConfirmData xs={9} style={{width:'54vw', borderBottom:'1px solid ' + GREY_COLOR}}>{t(mealInfo.name)}</ConfirmData>
                {/*<ConfirmHeader style={{borderBottom:'1px solid ' + GREY_COLOR}}>夕食</ConfirmHeader>*/}
                {/*<ConfirmData style={{borderBottom:'1px solid ' + GREY_COLOR}}>{selectedReserve[0].NumberOfGuests}名</ConfirmData>*/}
              </ConfirmBlock>
            </ConfirmArea>
            <Btn btnLabel={t('次へ')} marginTop='10vh' OnClick={() => this.props.clickNextBtn('/checkin/edit')}/>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


Confirm.propTypes = {
}

const mapStateToProps = state => {
  return {
    selectedReserve: state.reserve.selectedReserve,
    flg: state.reserve.flg,
    qrsearchflg: state.reserve.qrsearchflg
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: (flg, qrsearchflg) => {
      if (flg === true) {
        dispatch(push('/checkin/search_result'))
      } else {
        if (qrsearchflg) {
          dispatch(push('/checkin/qr'))
        } else {
          dispatch(push('/checkin/search'))
        }
      }
    },
    clickNextBtn: path => {
      dispatch(push(path))
    },
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Confirm))

const MEAL_INFO = [
  {id: '00', name: '食事なし'},
  {id: '01', name: '朝食付き'},
  {id: '11', name: '朝食／夕食付'},
  {id: '10', name: '夕食付'},
]
/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
  // width: 80vw;
`
const ConfirmBlock= styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  // justify-content: space-between;
  list-style:none;
  margin:0;
  padding:0;
`
const ConfirmHeader = styled(Col)`
  background-color:${GREY_COLOR};
  border-bottom:1px solid #fff;
  color:#333;
  font-size:1.5rem;
  height:4.5vw;
  line-height:4.5vw;
  padding-left:1.5vh;
  width:18vw;
  &.en {
    font-size:1.3rem;
  }
`
const ConfirmData = styled(Col)`
  border-top:1px solid ${GREY_COLOR};
  border-right:1px solid ${GREY_COLOR};
  color:#333;
  font-size:1.5rem;
  height:4.5vw;
  line-height:4.5vw;
  padding-left:1.5vh;
  width:18vw;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
