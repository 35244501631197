import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {containerStyle} from '../../constants/common'
import { reduxForm } from 'redux-form'
import Wizard from '../../components/atomics/StepWizard'
import TitleHeader from '../../components/atomics/TitleHeader'
import {actions as reserveAction} from '../../modules/reserve'
import Btn from '../../components/atomics/Button'
import IconBtn from '../../components/atomics/IconButton'


class ConfirmPassport extends Component {

  render() {
    const {t, image, imageCount, sum} = this.props

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={3}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader marginTop='2vh' marginBottom='8vh' headerTitle={t('この写真でよろしいですか？')}/>
            </Row>
            <RowCenter>
              <div>
                <img src={image} style={{height: '500px', width: 'auto'}}></img>
              </div>
            </RowCenter>
            <div height='68vh'>
              <Btn btnLabel={t('いいえ')} style={{right: "auto"}} OnClick={()=>this.props.click_No()}/>
              <Btn btnLabel={t('はい')} OnClick={()=>this.props.click_Yes(imageCount, sum, image)}/>
            </div>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

ConfirmPassport.propTypes = {}

const mapStateToProps = state => {
  return {
    image: state.reserve.passportImage,
    imageCount: state.reserve.imageCount,
    sum: state.reserve.selectedReserve.map(reserve => reserve.NumberOfGuests).reduce((sum, num) => sum + num),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: path => dispatch(push(path)),
    clickCheckInButton: () => dispatch(reserveAction.checkIn()),
    click_No: () => {
      dispatch(push('/checkin/read_passport'))
    },
    click_Yes: (imageCount, sum, image) => {
      dispatch(reserveAction.imageCountup())
      // 画像をアップロード
      dispatch(reserveAction.imageUpload({imageCount, image}))
      if (imageCount+1 === sum){
        dispatch(reserveAction.checkIn())
      } else {
        dispatch(push('/checkin/read_passport'))
      }

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'ConfirmPassport',
    destroyOnUnmount: false,
  })(withTranslation()(ConfirmPassport)))

const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`