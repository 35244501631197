/***************************************
 *URL
 ***************************************/
/***ログイン***/
export const LOGIN_URL = '/api/login'
/**マスタ**/
export const GET_STORE_MASTER_URL = '/api/stores'
/**予約**/
export const GET_RESERVATION_URL = '/FindReservation'
export const GET_RESERVATION_DETAIL_URL = '/GetRegistrationInfo'
export const GET_ROOM_URL = '/FindRooms'
export const POST_CHECK_IN_URL = '/CheckIn'
export const GET_BALANCE_URL = '/GetBalance'
export const POST_PAYMENT_URL = '/PayBill'
export const GET_MEAL_COUPON_URL = '/GetMealCoupon'
export const PUT_RESERVATION_URL = '/ModifyStayData'
export const PUT_REGISTRATION_URL= '/UpdateRegistrationInfo'
export const POST_CHECK_OUT = '/CheckOut'
export const GET_MESSAGES_URL = '/GetMessages'