/***************************************************
 * 色
 **************************************************/
/* メイン */
export const BASE_COLOR = '#245C72'
export const SUB_COLOR = '#0A82B1'
export const ACC_COLOR = '#245C72'
export const LABEL_BG_COLOR = '#DDDDDD'

/* アクティブ・非アクティブ */
export const ACTIVE_COLOR = '#31A237'
export const INACTIVE_COLOR = '#DDDDDD'

/* フォント */
export const FONT_COLOR = '#333333'
/* ポジション */
export const E_ABURI_COLOR = '#0066FF'
export const J_ABURI_COLOR = '#FE96FE'
export const HYOUKETSU_COLOR = '#95F7FF'
export const TORA_COLOR = '#85FD55'
export const NIGIRI_COLOR = '#FFFF99'
export const DESERT_COLOR = '#FF9966'
export const FRY_COLOR = '#009900'
export const MAKI_COLOR = '#CC6600'

/* 経過時間 */
export const TIMEA_COLOR = '#CE1636'
export const TIMEB_COLOR = '#FFFF00'
export const TIMEC_COLOR = '#078407'


export const BACK_COLOR = '#fff'
export const WHITE_COLOR = '#fff'
export const GREY_COLOR = '#DDDDDD'
export const DARYGREY_COLOR = '#484747'
// export const FONT_COLOR = '#4d4d4d'
export const BROWN_COLOR = '#8e7970'
export const RED_COLOR = '#EF5350'
export const PINK_COLOR = '#d86695'
export const WEEKEND_COLOR = '#AC58FA'
export const ORANGE_COLOR = '#FE9A2E'
export const MONDAY_COLOR = '#31B404'
export const HOTHOUR_COLOR = '#EF5350'

/***************************************************
 * 色CD
 **************************************************/
// export const MONDAY_COLOR_CD = '0001'
// export const HAPPYRUSH_COLOR_CD = '0002'
// export const WEEKEND_COLOR_CD = '0003'
// export const RED_COLOR_CD = '0004'

/***************************************************
 * ターミナル
 **************************************************/
// export const TERMINAL_ID = 1

/***************************************************
 * 席タイプ
 **************************************************/
export const SEAT_TYPE_LIST = [
  {name: 'どこでも', code: 0},
  {name: 'カウンター', code: 1},
  {name: 'ボックス', code: 2},
]
export const containerStyle = {
  padding: 0,
}