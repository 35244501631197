import React, {Component} from 'react'
import styled from 'styled-components'
import {Card} from 'reactstrap'

class Paper extends Component {
  render() {
    const {children, height} = this.props
    return (
      <StyledCard height={height}>
        {children}
      </StyledCard>
    );
  }
}

Paper.propTypes = {
}

export default Paper

const StyledCard = styled(Card)`
  padding: 15px 25px;
  margin: 10px 0;
  height: ${props=>props.height};
}};
`