import React, {Component} from 'react'
import {SUB_COLOR} from '../../constants/common'
import {Card, CardBody} from 'reactstrap'

class CustomCard extends Component {
  render() {
    const {children, headerTitle, cardWidth, cardMargin, cardBorderColor, noBorder,cardBodyPadding, ...rest} = this.props
    const cardStyle = {
      width: cardWidth ? cardWidth : '100%',
      margin: cardMargin ? cardMargin : '5px 10px',
      borderColor: cardBorderColor ? cardBorderColor : `${SUB_COLOR}`,
      borderStyle: noBorder ? 'none' : '',
    }
    const cardBodyStyle = {
      padding: cardBodyPadding ? cardBodyPadding : '10px 0'
    }
    return (
      <Card style={cardStyle} {...rest}>
        {/* <CardHeader style={cardHeaderStyle}>{headerTitle}</CardHeader> */}
        <CardBody style={cardBodyStyle}>
          {children}
        </CardBody>
      </Card>
    );
  }
}

CustomCard.propTypes = {
}

export default CustomCard
