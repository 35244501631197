import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Button,
  Container,
  Col,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { actions as errorAction } from '../../modules/error'
import { actions as settingAction } from '../../modules/setting'
import { push } from 'connected-react-router'
import TitleHeader from '../../components/atomics/TitleHeader'
import { ACC_COLOR, SUB_COLOR, containerStyle } from '../../constants/common'
import packageJson from '../../../package.json'

class SettingSelect extends Component {
  componentDidMount() {
    this.props.start()
  }
  componentWillUnmount() {
    this.props.end()
  }

    state = { 
      count: 0, 
      interval: 0
    };

    clickDummyBtn = () => {
      this.setState({ count: ++this.state.count });
      if (this.state.count === 1) {
        this.count = setInterval(() => {
          this.setState({interval: this.state.interval + 1})
          if (this.state.interval >= 3) {
            this.setState({count: 0, interval: 0})
            clearInterval(this.count)
          }
        }, 1000)
      }
      if (this.state.count === 5) {
        this.props.clickBtn('/')
      }
    }

    render() {
      const { i18n, ipadAppVersion } = this.props
      return (
        <Contents>
          <div style={{ height: '10vh', display: 'flex' }}>
            <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
              <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
                <img
                  src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`}
                  alt=""
                  style={{ height: '4vw' }}
                />
              </Logo>
            </Row>
            <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
              <Button onClick={() => this.props.clickBtn('/')}>
                <IoMdArrowRoundBack />
              </Button>
            </Row>
          </div>
        
          <div style={{display: 'flex', margin: '9vh 0vh'}}>
            <TitleHeader
              headerWidth="100vw"
              marginTop="2vh"
              headerTitle='設定メニューの選択'
            />
          </div>

          <div style={{display: 'flex'}}>
            <Container fulid={true}>
              <Row>
                <Col xs={4}>
                  <TopBtn onClick={() => this.props.clickBtn('/key_manually/enter_information')}>
                    スマカギ発行
                  </TopBtn>
                </Col>
                <Col xs={4}>
                  <TopBtn onClick={() => this.props.clickBtn('/key_manually/delete_key_touch')}>
                    スマカギ削除
                  </TopBtn>
                </Col>
                <Col xs={4}>
                  <TopBtn onClick={() => this.props.clickBtn('/config/configpage')}>
                    プリンター設定
                  </TopBtn>
                </Col>
                <Col xs={4}>
                  <TopBtn onClick={() => this.props.clickBtn('/config/vega3000_config')}>
                    決済端末設定
                  </TopBtn>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            style={{
              height: '10vh',
              display: 'flex',
              position: 'absolute',
              bottom: '1vh',
              right: '5vh'
            }}
          >
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <p>アプリバージョン：{ipadAppVersion}</p>
                <p>Webバージョン：{packageJson.version}</p>
              </Col>
            </Row>
          </div>
        </Contents> 
      )
    }
}

const mapStateToProps = state => {
  return {
    ipadAppVersion: state.setting.ipadAppVersion,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      window.callbackVersion = message => {
        dispatch(settingAction.setIpadAppVersion(message))
      }

      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.system
      ) {
        window.webkit.messageHandlers.system.postMessage('version')
      }
    },
    end: () => {
      window.callbackVersion = message => {}
    },
    clickBtn: (path, flg) => {
      dispatch(errorAction.displayBtnFlg(flg))
      dispatch(push(path))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SettingSelect))

const Logo = styled.div`
  margin-right: auto;
`

const TopBtn = styled.li`
  background-color: ${SUB_COLOR};
  border-radius: 0.4vw;
  box-shadow: 0px 4px ${ACC_COLOR};
  color: #fff;
  font-size: 2em;
  height: 14vh;
  line-height: 14vh;
  text-align: center;
  list-style: none;
`

const Contents = styled.section`
  background-color: #fff;
  height: 100vh;
  overflow: hidden;

  .container .row > div {
    margin-bottom: 2em;
  }
`
