import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import { actions as noticeAction } from '../../modules/notice'
import { withTranslation } from 'react-i18next'

/**
 * 通知モーダル
 */
export class Notice extends Component {
  render() {
    let { isOpen, message = '', t} = this.props

    return (
      <section>
        <Modal
          backdrop="static"
          dialogClassName="error-modal"
          isOpen={isOpen}
          onHide={() => {
            this.props.close()
          }}
        >
          <ModalBody style={{ background: '#FFF', minHeight: '200px' }}>
            <div
              className="flex-v-center flex-h-center"
              style={{ fontSize: '1.2rem', minHeight: '200px' }}
            >
              <div>
                {t(message)}
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            style={{ background: '#FFF', margin: 'auto', textAlign: 'center', borderTop: '0 solid' }}
          >
            <Button color='link' onClick={() => this.props.closeNoticeModal()}>{t('閉じる')}</Button>
          </ModalFooter>
        </Modal>
      </section>
    )
  }
}

Notice.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    isOpen: state.notice.isOpen,
    message: state.notice.message,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(noticeAction.init())
    },
    closeNoticeModal: () => {
      dispatch(noticeAction.close())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Notice))
