import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Button,
  Form,
  Input,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { actions as errorAction } from '../../modules/error'
import { push } from 'connected-react-router'
import TitleHeader from '../../components/atomics/TitleHeader'
import { ACC_COLOR, SUB_COLOR, containerStyle } from '../../constants/common'
import { reduxForm, destroy as formDestroy } from 'redux-form'
import CustomInput from '../../components/atomics/CustomInput'
import Error from '../../components/molecules/Error'

class AdminCheckPassword extends Component {
    // password が一致した時の処理
    successPassword = () => {
        this.props.clickBtn('/config/setting_select')
    }
    // password が間違っている時の処理
    errorPassword = () => {
        this.props.errorPassword('passwordが一致しません')
    }

    state = { 
        count: 0, 
        interval: 0
    };

    clickDummyBtn = () => {
        this.setState({ count: ++this.state.count });
        if (this.state.count === 1) {
          this.count = setInterval(() => {
            this.setState({interval: this.state.interval + 1})
            if (this.state.interval >= 3) {
              this.setState({count: 0, interval: 0})
              clearInterval(this.count)
            }
          }, 1000)
        }
        if (this.state.count === 5) {
            this.props.clickBtn('/')
        }
      }

  render() {
      // isMathPassword の値は入力 password が正しければ true 違っていれば false
    const { i18n, isMathPassword } = this.props
    return (
        <section
        style={{ backgroundColor: '#fff', height: '100vh', overflow: 'hidden' }}>
            <div style={{height: '10vh', display: 'flex'}}>
                <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
                    <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
                        <img
                            src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`}
                            alt=""
                            style={{ height: '4vw' }}
                        />
                    </Logo>
                </Row>
                <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
                    <Button onClick={() => this.props.clickBtn('/')}>
                        <IoMdArrowRoundBack />
                    </Button>
                </Row>
            </div>
            <div style={{display: 'flex', margin: '9vh 0vh'}}>
                <TitleHeader
                    headerWidth="100vw"
                    marginTop="2vh"
                    headerTitle='パスワード入力画面'
                />
            </div>
            <Form style={{width:'50vw', margin: '2vh auto'}}>
                <CustomInput bordered="true" name="Password" component="input" type="password" placeholder="password"></CustomInput>
            </Form>

            <TopBtn onClick={ () => {
                isMathPassword ? this.successPassword() : this.errorPassword()
            }}>
                OK
            </TopBtn>
            {/* Erro クラスの中の mapStateToProps で isOpen などの state の値の変更を監視しているためこのクラスの mapStateToProps で値の変更を監視し、変更後の値を Errorモーダルに渡す必要がない */}
            <Error/>
        </section> 
    )
  }
}

const mapStateToProps = state => {
    let password = (state.form.AdminCheckPassword && state.form.AdminCheckPassword.values) ? state.form.AdminCheckPassword.values.Password : ''
  return {
    isMathPassword: state.setting.cico.setting_password[0].value_string == password,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: (path, flg) => {
        dispatch(errorAction.displayBtnFlg(flg))
        dispatch(push(path))
    },

    errorPassword: (msg) => {
        dispatch(errorAction.displayError(msg))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
      form: 'AdminCheckPassword',
      //これがあると画面遷移後もテキストに入力内容が残り続ける
    //   destroyOnUnmount: false,
    })(withTranslation()(AdminCheckPassword)))

const Logo = styled.div`
  margin-right: auto;
`

const TopBtn = styled.li`
  background-color: ${SUB_COLOR};
  border-radius: 0.4vw;
  box-shadow: 0px 4px ${ACC_COLOR};
  color: #fff;
  font-size: 2em;
  height: 10vh;
  line-height: 10vh;
  text-align: center;
  max-width: 25vw;
  width: 25vw;
  list-style: none;
  margin: 15vh auto 0;
`