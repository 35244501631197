import {IS_PRO_ENV, NODERED_URL} from './api-config'

/** 施設ID */
const DEV_FACILITY_ID = '003'
const PRO_FACILITY_ID = '203'

/** TAP Client ID */
const DEV_TAP_CLIENT_ID = 'NKHR001001'
const PRO_TAP_CLIENT_ID = 'tcitsuchiura'

/** CS案内 施設頭文字アルファベット */
const DEV_FACILITY_INITIAL = 'R'
const PRO_FACILITY_INITIAL = 'BT'

/**
 * 施設ID
 */
export const FACILITY_ID = (() => {
  if (IS_PRO_ENV) {
    return PRO_FACILITY_ID
  }
  return DEV_FACILITY_ID
})()

export const TAP_CLIENT_ID = (() => {
  if (IS_PRO_ENV) {
    return PRO_TAP_CLIENT_ID
  }
  return DEV_TAP_CLIENT_ID
})()

export const FACILITY_INITIAL = (() => {
  if (IS_PRO_ENV) {
    return PRO_FACILITY_INITIAL
  }
  return DEV_FACILITY_INITIAL
})()

/**
 * TODO: 設定の置き場所、ファイル名を直したい
 */
export const TAP_REQUEST_HEADER = (url) => {
  let regex = new RegExp('^' + NODERED_URL)

  if (url.match(regex)) {
    return {
      'X-TAP-CLIENT-ID': TAP_CLIENT_ID,
      'X-TAP-HOTEL-CODE': FACILITY_ID,
    }
  }

  return {}

}
