import React, {Component} from 'react'
import styled from 'styled-components'
import {GREY_COLOR, SUB_COLOR} from '../../constants/common'
import { Field } from 'redux-form'

class CustomInput extends Component {
  render() {
    const {children, ...rest} = this.props
    return (
      <StyledField {...rest}>
        {children}
      </StyledField>
    );
  }
}

CustomInput.propTypes = {
}

export default CustomInput

const StyledField = styled(Field)`
  outline: none;
  width: ${props => props.width ? props.width : '100%'};
  // border: solid 2px ${SUB_COLOR};
  border: ${props => props.bordered ? `solid 1px ${GREY_COLOR}` : 'none'};
  border-radius: 3px;
  padding: 5px 15px;
  height: 4.5vw;
  font-size: 1.5rem;
`