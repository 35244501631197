import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container, Button } from 'reactstrap'
import { IoIosRefresh } from 'react-icons/io'
import { IoIosSettings } from 'react-icons/io'
import { IoIosKey } from 'react-icons/io'
import { ACC_COLOR, SUB_COLOR, containerStyle } from '../../constants/common'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { destroy as formDestroy } from 'redux-form'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import { RowCenter } from '../../util/styled'
import { actions as reserveAction } from '../../modules/reserve'
import { actions as paymentAction } from '../../modules/payment'
import { actions as settingAction } from '../../modules/setting'
import { actions as errorAction } from '../../modules/error'

class Top extends Component {
  componentDidMount() {
    this.props.initReserve()
    this.props.startTop()
  }
  componentWillUnmount() {
    this.props.endTop()
  }

  render() {
    const { t, i18n } = this.props
    return (
      <section
        style={{ backgroundColor: '#fff', height: '100vh', overflow: 'hidden' }}
      >
        <div style={{ height: '10vh', display: 'flex' }}>
          <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
            <Logo className={[i18n.language]}>
              <img
                src={`https://dfc78s572732n.cloudfront.net/logo/black/ja/logo-bk3.png`}
                alt=""
                style={{ height: '4vw' }}
              />
            </Logo>
          </Row>
          <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
            <Button
              onClick={() => this.props.clickBtn('/config/admin_check_password')}
            >
              <IoIosSettings />
            </Button>
          </Row>
          <Row style={{ position: 'absolute', top: '3vh', right: '5vh' }}>
            <Button
              onClick={() => {
                window.location.reload(true);
              }}
            >
              <IoIosRefresh />
            </Button>
          </Row>
        </div>
        <Line />
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader
                headerWidth="100vw"
                marginTop="2vh"
                headerTitle='ご希望の操作を選択してください'
              />
            </Row>
            <SelectBtnArea style={{ marginTop: '7.5vh' }}>
              <TopBtn onClick={() => this.props.clickBtn('/checkin')}>
                チェックイン
              </TopBtn>
              <TopBtn
                onClick={() =>
                  this.props.clickBtn('/checkout/key-touch', false)
                }
              >
                チェックアウト
              </TopBtn>
            </SelectBtnArea>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}
Top.propTypes = {}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: (path, flg) => {
      dispatch(errorAction.displayBtnFlg(flg))
      dispatch(push(path))
    },
    initReserve: () => {
      dispatch(formDestroy('customerEdit', 'customerEditOption'))
      dispatch(reserveAction.initReserve())
      dispatch(paymentAction.initReserve())
      dispatch(settingAction.initSumakagiTenants())
      dispatch(settingAction.initSettings())
    },
    startTop: () => {
    },
    endTop: () => {
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Top))

/* CSS定義 */
const clear_both = {
  clear: 'both'
}

const Logo = styled.div`
  margin-right: auto;
`
const MainArea = styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  max-width: 75vw;
`
const SelectBtnArea = styled(MainArea)`
  padding: 0px;
  margin: 0 auto;
`
const TopBtn = styled.li`
  background-color: ${SUB_COLOR};
  border-radius: 0.4vw;
  box-shadow: 0px 4px ${ACC_COLOR};
  color: #fff;
  font-size: 2em;
  height: 14vh;
  line-height: 14vh;
  text-align: center;
  max-width: 30vw;
  width: 30vw;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top: 0;
  opacity: 0;
`
