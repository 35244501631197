import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {
  Container,
  Card,
  Button,
} from 'reactstrap'
import { containerStyle } from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {actions as keyIssueAction} from '../../modules/keyIssue'
import {actions as errorAction} from '../../modules/error'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import rollbar from '../../modules/rollbar'


class RoomKeyIssue extends Component {

  componentDidMount() {
    this.props.initKeyIssue()
    this.props.requestCardId()
    this.video1.play()
    this.video2.play()
    this.video3.play()
    this.video4.play()
  }

  componentWillUnmount() {
    this.props.end()
  }

  
  render() {
    const {
      roomNo, 
      roomKeyCount,
      roomCount,
      num, 
      count,
      messages,
      t,
      i18n,
      reserve,
      registerFlg,
      movieStatus,
      roomKeyNum,
      roomNum,
    } = this.props

    const isApri = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.icCard

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={5}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle} style={{display:'flex'}}>
            <MsgArea style={{whiteSpace: 'pre-line'}} className={[i18n.language]}>
              <p style={circle}>{t('お部屋')}{roomKeyNum}</p>
              {reserve.isReceipt === true
                ? <p style={{marginTop:'2vh'}}>{t('レシートをお受け取り\nください')}</p>
                : <p style={{marginTop:'2vh'}}>{t('{roomNo}号室の{roomKeyCount}枚目のルームキーを発行します', { roomNo: roomNo, roomKeyCount: roomKeyCount })}</p>
              }
              {messages.map((message, key) => (
                <p key={key} style={{fontSize:'1.4rem', lineHeight:'1.7'}}>{t(message)}</p>
              ))}
              {registerFlg === true
                ? <p><img src={`https://dfc78s572732n.cloudfront.net/icon/20200131/loading.gif`} alt=""/></p>
                : ''
              }
            </MsgArea>
              <AnimationArea style={{display: movieStatus === 1 ? 'block' : 'none'}}>
                <video muted loop playsInline width='500' ref={ref => this.video1 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkin_1.mp4" type="video/mp4" />
                </video>
              </AnimationArea>
              <AnimationArea style={{display: movieStatus === 2 ? 'block' : 'none'}}>
                <video muted loop playsInline width='500' ref={ref => this.video2 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkin_2.mp4" type="video/mp4" />
                </video>
              </AnimationArea>
              <AnimationArea style={{display: movieStatus === 3 ? 'block' : 'none'}}>
                <video muted loop playsInline width='500' ref={ref => this.video3 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkin_3.mp4" type="video/mp4" />
                </video>
              </AnimationArea>
              <AnimationArea style={{display: movieStatus === 4 ? 'block' : 'none'}}>
                <video muted loop playsInline width='500' ref={ref => this.video4 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkin_4.mp4" type="video/mp4" />
                </video>
              </AnimationArea>
            {!isApri && (
              <Card
                style={{
                  position: 'absolute',
                  right: '0',
                  bottom: '0',
                  padding: '20px',
                  zIndex: '9999',
                }}
              >
                <p>カードタッチ</p>
                <p><Button onClick={() => window.callbackGetId('12345671')}>12345671</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345672')}>12345672</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345673')}>12345673</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345674')}>12345674</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345675')}>12345675</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345676')}>12345676</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345677')}>12345677</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345678')}>12345678</Button></p>
                <p><Button onClick={() => window.callbackGetId('12345679')}>12345679</Button></p>
                <hr/>
                <p><Button onClick={() => window.callbackRemoveCard()}>カード取り外し</Button></p>
                <hr/>
                <p><Link to='/checkin/completed'>開発用完了</Link></p>
              </Card>
            )}
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


RoomKeyIssue.propTypes = {
}

const mapStateToProps = state => {
  let roomNo = ''
  let roomAccountID = 0
  let roomNum = 0
  let roomKeyCount = 0
  let roomKeyNum = 0
  let reserve = {}
  if (state.keyIssue.list.length && 0 < state.keyIssue.count && state.keyIssue.count <= state.keyIssue.list.length) {
    reserve = state.keyIssue.list[state.keyIssue.count - 1]
    roomNo = reserve.room.RoomNo
    roomAccountID = reserve.RoomAccountID
    roomNum = state.reserve.selectedReserve
    roomKeyNum = roomNum.findIndex(item => item.RoomAccountID === roomAccountID) + 1
    roomKeyCount = state.keyIssue.list.slice(0, state.keyIssue.count).filter(item => item.room.RoomNo === roomNo && !item.isReceipt).length
  }

  return {
    roomNo,
    roomAccountID,
    roomKeyCount,
    num: state.keyIssue.list.length,
    count: state.keyIssue.count,
    messages: state.keyIssue.messages,
    reserve,
    registerFlg: state.keyIssue.registerFlg,
    movieStatus: state.keyIssue.movieStatus,
    roomNum,
    roomKeyNum,
    isManual: state.keyIssue.isManual
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: path => {
      dispatch(push(path))
    },
    initKeyIssue: () => {
      dispatch(keyIssueAction.initKeyIssue())
    },
    requestCardId: () => {
      // TODO:フラッシュメッセージがほしい
      dispatch(keyIssueAction.clearMessage())
      // カード読み取り依頼
      dispatch(keyIssueAction.requestCardId())

      // カード読み取り通知受け側
      // NOTE: redux-sagaの中で呼び出せなかったので、ここで定義しておく
      // (dispatchがない。 put,callだと実行できなかった。)
      window.callbackGetId = (uid) => {
        console.log(uid)
        rollbar.debug('チェックイン カードタッチ')

        // カード発行
        dispatch(keyIssueAction.clearMessage())
        dispatch(keyIssueAction.storeCard(uid))
      }
      window.callbackRemoveCard = () => {
        rollbar.debug('チェックイン カード取り外し')

        // 次のカード読み取り
        dispatch(errorAction.close())
        dispatch(keyIssueAction.clearMessage())
        dispatch(keyIssueAction.requestCardId())
      }
      window.callbackTimeout = () => {
        // 再度読み取り
        dispatch(keyIssueAction.clearMessage())
        dispatch(keyIssueAction.requestCardId())
      }
      window.callbackError = (msg) => {
        console.log(msg)
        rollbar.debug('チェックイン カードエラー')

        dispatch(errorAction.displayError(msg))
        dispatch(keyIssueAction.clearMessage())
        dispatch(keyIssueAction.requestCardId())
      }
    },
    end: () => {
      dispatch(keyIssueAction.setIsManual(false))

      // カード読み取りのコールバックを消しておく
      window.callbackGetId = (uid) => {
        console.log(uid)
        rollbar.debug('チェックイン 閉じ済みcallbackGetId')
      }
      window.callbackRemoveCard = () => {
        rollbar.debug('チェックイン 閉じ済みcallbackRemoveCard')
      }
      window.callbackTimeout = () => {
        rollbar.debug('チェックイン 閉じ済みcallbackTimeout')
      }
      window.callbackError = (msg) => {
        console.log(msg)
        rollbar.debug('チェックイン 閉じ済みcallbackError')
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RoomKeyIssue))

/* CSS定義 */
const MsgArea = styled.div`
  font-size:2.5rem;
  line-height:1.7;
  margin:6vw 2vw;
  text-align:center;
  width:60vw;
  height:60vh;
  &.en {
    font-size:2.4rem;
  }
`
const AnimationArea = styled.div`
  margin:6vw 2vw;
  width:60vw;
  height:60vh;
`
const clear_both = {
  clear:'both',
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`

const circle = {
  width: '10vh',
  height: '10vh',
  borderRadius: '50%',
  backgroundColor: '#245C72',
  color: '#fff',
  lineHeight: '10vh',
  fontSize: '1.2rem',
  margin: '0 auto',
}