import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
} from 'reactstrap'
import { containerStyle } from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import Btn from '../../components/atomics/Button'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import {actions as reserveAction} from '../../modules/reserve'
import {actions as confirmAction} from '../../modules/confirm'


class SelectPayment extends Component {
  render() {
    const {searchResult = [], selectedReserve, t, selectAllRoom} = this.props    
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={1}/> 
          <IconBtn btnLabel={t('中止')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/cancel.png' OnClick={() => this.props.clickCancel('/checkin', msg)}/>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
              <div>
                <MsgArea>
                  <Msg>
                    <p>{t('複数のお部屋をご予約いただいております')}</p>
                    <p>{t('お支払い方法を選択してください')}</p>
                  </Msg>
                </MsgArea>
                <div>
                  <Btn btnLabel={t('部屋ごとに支払う')} btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' style={{right: "auto"}} OnClick={() => this.props.clickBtn('/checkin/search_result')}/>
                  <Btn btnLabel={t('全部屋まとめて支払う')} btnFloat='right' btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' OnClick={() => this.props.clickNextBtn('/checkin/reconfirm')}/>
                </div>
              </div>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


SelectPayment.propTypes = {
}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult,
    selectedReserve: state.reserve.selectedReserve,
    initialValues: {},
    selectAllRoom: state.reserve.selectAllRoom,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchReserve: searchType => dispatch(reserveAction.searchReserve(searchType)),
    clickReserveRow: item => {
      dispatch(reserveAction.selectReserve(item))
    },
    clickNextBtn: () => {
      // 部屋選択画面を通ったかのフラグ
      dispatch(reserveAction.roomSetflg(true))
      dispatch(reserveAction.setSelectAllRoom(false))

      dispatch(reserveAction.searchRoom())
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectPayment))

/* CSS定義 */

const MsgArea = styled.div`
  font-size:2.4rem;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
`
const Msg = styled.p`
  font-size:2.4rem;
  text-align: center;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
