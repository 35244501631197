import React, {Component} from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next';

class StepWizard extends Component {
  

  render() {
    const {index, t, i18n} = this.props
 
    return (
      <section style={{margin:'0 auto', width:'100%', textAlign:'center' }}>
        <BaseArea className={[i18n.language]} >
          <Wizard className={[i18n.language]} style={index === 1 ? thisPage : {}}>
            <NavLabel className={[i18n.language]}>{t('予約検索')}</NavLabel>
            <NavNum>
              {index === 1
                ? <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-BL.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
                : <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-GY.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
              }
            </NavNum>
          </Wizard>
          <Wizard className={[i18n.language]} style={index === 2 ? thisPage : {}}>
            <NavLabel className={[i18n.language]}>{t('予約確認')}</NavLabel>
            <NavNum >
              {index === 2
                ? <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-BL.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
                : <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-GY.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
              }
            </NavNum>
          </Wizard>
          <Wizard className={[i18n.language]} style={index === 3 ? thisPage : {}}>
            <NavLabel className={[i18n.language]}>{t('お客様情報入力')}</NavLabel>
            <NavNum >
              {index === 3
                ? <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-BL.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
                : <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-GY.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
              }
            </NavNum>
          </Wizard>
          <Wizard className={[i18n.language]} style={index === 4 ? thisPage : {}}>
            <NavLabel className={[i18n.language]}>{t('お支払い')}</NavLabel>
            <NavNum >
              {index === 4
                ? <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-BL.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
                : <img src= 'https://dfc78s572732n.cloudfront.net/triangle_icon/20191210/triangle-GY.png' alt="" style={{height:'1.5vh', paddingBottom:'0.2vh'}}/>
              } 
            </NavNum>
          </Wizard>
          <Wizard className={[i18n.language]} style={index === 5 ? thisPage : {}}>
            <NavLabel className={[i18n.language]}>{t('チェックイン完了')}</NavLabel>
            <NavNum ></NavNum>
          </Wizard>
        </BaseArea>
      </section>
    );
  }
}

StepWizard.propTypes = {
}

export default (withTranslation()(StepWizard))

const BaseArea = styled.div`
  overflow: hidden;
  margin: 4vh auto;
  display: flex;
  justify-content: center;
  &.en {
    margin: 3vh auto;
  }
`
const Wizard = styled.ul`
  float: left;
  list-style: none;
  color: #DDDDDD;
  font-weight: bold;
  font-family: 'ヒラギノ角ゴ ProN';
  margin: 0 1.8vw;
  display: flex;
  padding: 0;
  hright: 10vh;
  width: auto;
  align-items: center;
  &.en {
    margin: 0 1vw;
  }
`
const NavNum = styled.li`
  text-align: center;
  margin-left: 1.5vw;
`
const NavLabel = styled.li`
  letter-spacing: 0.3vw;
  font-size: 0.9rem;
  &.en {
    width: 10vw;
    text-align: center;
    letter-spacing: 0.05rem;
  }
`
const thisPage = {
  color: '#245C72'
}

