import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery } from 'redux-saga/effects'
/***********************************************************
 * Action Creators
 ***********************************************************/
export const actions = createActions(
  {
    init: () => ({}),
    openLoading: payload => payload,
    closeLoading: () => ({}),
    onContinue: () => ({}),
    offContinue: () => ({}),
  },
  { prefix: 'hoshino/loading'},
)
/***********************************************************
 * Reducer
 ***********************************************************/
const initialState = {
  isOpen: false,
  isContinue: false,
  isSmall: false,
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.openLoading]: (state, action) => ({
    ...state,
    isOpen: true,
    isSmall: action.payload
  }),
  [actions.onContinue]: state => ({
    ...state,
    is_continue: true,
  }),
  [actions.offContinue]: () => initialState,
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* loadingSaga() {
  yield takeEvery(actions.closeLoading, closeLoading)
}

function* closeLoading(action) {
  const {loading} = yield select()
  if (!loading.is_continue) {
    yield put(actions.init())
  }
}