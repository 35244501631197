import { createActions, handleActions } from 'redux-actions'
import { SUMAKAGI_URL } from '../constants/api-config'
import {actions as errorAction} from './error'
import {actions as MiddlewareAction, api} from './middleware'
import { select, put, takeEvery, call } from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {printReceipt, printTicket} from './receipt'
import {delay} from '@redux-saga/core/effects';

/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    initKeyIssue: () => ({}),
    setIsManual: payload => payload,
    initList: payload => payload,
    requestCardId: () => ({}),
    storeCard: payload => payload,
    succesStoreCard: payload => payload,
    failedStoreCard: json => json,
    storeRoomJobs: payload => payload,
    successStoreRoomJobs: payload => payload,
    storeSharedJobs: payload => payload,
    successStoreSharedJobs: payload => payload,
    requestRemoveCard: () => ({}),
    nextCount: () => ({}),
    setCount: payload => payload,
    setMessage: payload => payload,
    clearMessage: () => ({}),
    setIsSuccess: payload => payload,
    registerFlg: payload => payload,
    setMovieStatus: payload => payload,
  },
  { prefix: 'hoshino/keyIssue'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  list: [],
  count: 0,
  messages: ['カードキーをボックスから1枚取って\nICリーダーにかざしてください'],
  isSuccess: true,
  registerFlg: false,
  MovieStatus: 1,
  isManual: false,
}
const reducer = handleActions({
  [actions.initKeyIssue]: (state) => ({
    ...initialState,
    isManual: state.isManual,
  }),
  [actions.setIsManual]: (state, action) => ({
    ...state,
    isManual: action.payload,
  }),
  [actions.initList]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [actions.nextCount]: (state, action) => ({
    ...state,
    count: state.count + 1,
  }),
  [actions.setCount]: (state, action) => ({
    ...state,
    count: action.payload,
  }),
  [actions.setMessage]: (state, action) => ({
    ...state,
    messages: state.messages.concat([action.payload]),
  }),
  [actions.clearMessage]: (state, action) => ({
    ...state,
    messages: [],
  }),
  [actions.setIsSuccess]: (state, action) => ({
    ...state,
    isSuccess: action.payload,
  }),
  [actions.registerFlg]: (state, action) => ({
    ...state,
    registerFlg: action.payload,
  }),
  [actions.setMovieStatus]: (state, action) => ({
    ...state,
    movieStatus: action.payload, 
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* keyIssueSaga() {
  yield takeEvery(actions.initKeyIssue, initKeyIssue)
  yield takeEvery(actions.requestCardId, requestCardId)
  yield takeEvery(actions.requestRemoveCard, requestRemoveCard)
  yield takeEvery(actions.storeCard, storeCard)
  yield takeEvery(actions.succesStoreCard, succesStoreCard)
  yield takeEvery(actions.failedStoreCard, failedStoreCard)
  yield takeEvery(actions.storeRoomJobs, storeRoomJobs)
  yield takeEvery(actions.successStoreRoomJobs, successStoreRoomJobs)
  yield takeEvery(actions.storeSharedJobs, storeSharedJobs)
  yield takeEvery(actions.successStoreSharedJobs, successStoreSharedJobs)
}

function* initKeyIssue() {
  const {keyIssue: {isManual}} = yield select()
  if (isManual) {
    return yield call(initManualKeyIssue)
  }

  const {reserve: {selectedReserve}} = yield select()

  let list = []
  selectedReserve.forEach(reserve => {
    let num = (reserve.detail.Person_M + reserve.detail.Person_F) >= 2 ? 2 : 1
    for (let i = 0; i < num; i++) {
      list.push(reserve)
    }
    list.push({...reserve, isReceipt:true})
  })

  yield put(actions.initList(list))
}

function* initManualKeyIssue() {
  const {
    form: {
      EnterInformation: {
        values: {
          ReserveNo,
          RoomNo,
          RoomKeyNo,
        }
      }
    }
  } = yield select()

  let list = []
  for (let i = 0; i < RoomKeyNo; i++) {
    list.push({
      ReserveNo,
      room: {
        RoomNo
      }
    })
  }

  yield put(actions.initList(list))
}

function* requestCardId() {
  const {
    keyIssue: {
      isSuccess,
      isManual,
    },
  } = yield select()

  if (isSuccess === true) {
    yield put(actions.nextCount())
  }
  
  yield put(actions.setIsSuccess(''))
  const {
    keyIssue: {
      list,
      count,
    },
  } = yield select()
  const reserve = list[count - 1]

  if (reserve && reserve.isReceipt === true) {
    //領収書を印刷する動画(動画checkin4)を再生する
    yield put(actions.setMovieStatus(4))
    yield put(actions.setMessage('お部屋案内、お食事券、\n領収書を発行中です'))
    yield call(printReceipt, {payload:{reserve}})
    yield call(printTicket, {payload:{reserve}})
    yield delay(3000)

    // カード発行枚数
    if (count >= list.length) {
      yield put(push('/checkin/completed'))
      return
    }
    yield delay(2000)
    yield put(actions.nextCount())
    yield put(actions.clearMessage())
  }

  // 手動のときは領収書発行が入らない
  if (isManual && count > list.length) {
    yield put(actions.setIsManual(false))
    yield put(push('/key_manually/enter_information'))
    return
  }

  //カードキーをかざす動画(動画checkin1)を再生する
  yield put(actions.setMovieStatus(1))
  yield put(actions.setMessage('カードキーをボックスから1枚取って\nICリーダーにかざしてください')) 


  // アプリ外から
  if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.icCard) {
    console.log('アプリ連携なし icCard')
    return
  }
  window.webkit.messageHandlers.icCard.postMessage('getId')
}

function* storeCard(action) {
  yield put(actions.registerFlg(true))
  //カードキーを登録している動画(動画checkin2)を再生する
  yield put(actions.setMovieStatus(2))
  yield put(actions.setMessage('カードを動かさないでください'))

  const {
    keyIssue: {
      list,
      count
    },
  } = yield select()
  const uid = action.payload
  const reserve = list[count - 1]

  // カード登録
  const URL = `${SUMAKAGI_URL}/cards/${uid}`
  console.log(URL)
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      card_name: `${reserve.ReserveNo}_${reserve.room.RoomNo}`
    },
    successCB: {cb: actions.succesStoreCard, args: {uid}},
    failedCB: {cb: actions.failedStoreCard, args: {uid}},
    doNotDisplayError: true,
    openLoadingFlag: 4,
  }
  yield put(MiddlewareAction.api(payload))
}

function* succesStoreCard(action) {
  yield put(actions.storeRoomJobs(action.payload))
}

function* failedStoreCard(action) {
  yield put(actions.clearMessage())
 

  let {
    json: {
      error: {code, message}
    },
    // uid,
  } = action.payload

  yield put(errorAction.displayError(`${code} ${message}\n別のカードと取り替えてください`))

  // カード取り外し依頼
  yield put(actions.registerFlg(false))
  yield put(actions.requestRemoveCard())
}

function* storeRoomJobs(action) {
  const {
    keyIssue: {
      list,
      count
    },
    setting: {
      sumakagiTenants
    }
  } = yield select()
  const {uid} = action.payload
  const reserve = list[count - 1]

  // TODO: テナント番号
  let roomToTenant = sumakagiTenants.find(item => item.common_flag == 0 && (item.room_no == '*' || item.room_no == reserve.room.RoomNo))
  if (!roomToTenant) {
    yield put(actions.clearMessage())
    yield put(actions.setMessage('テナントの設定が行われていない'))
    return
  }

  // カードキー紐付け
  const URL = `${SUMAKAGI_URL}/jobs/cards/${uid}/tenants/${roomToTenant.tenant_id}`
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      command: {
        type: 'card',
        value: 'link',
      }
    },
    successCB: {cb: actions.successStoreRoomJobs, args:{uid}},
    failedCB: {cb: actions.failedStoreCard, args: {uid}},
    doNotDisplayError: true,
    openLoadingFlag: 4,
  }
  yield put(MiddlewareAction.api(payload))
}

function* successStoreRoomJobs(action) {
  yield put(actions.storeSharedJobs(action.payload))
  
}

function* storeSharedJobs(action) {
  const {
    setting: {
      sumakagiTenants
    }
  } = yield select()
  let {uid} = action.payload

  // カードキー紐付け
  const sharedTenant = sumakagiTenants.find(item => item.common_flag == 1)
  if (!sharedTenant) {
    yield put(actions.clearMessage())
    yield put(actions.setMessage('テナントの設定が行われていない'))
    return
  }
  const URL = `${SUMAKAGI_URL}/jobs/cards/${uid}/tenants/${sharedTenant.tenant_id}`
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      command: {
        type: 'card',
        value: 'link',
      }
    },
    successCB: {cb: actions.successStoreSharedJobs, args:{uid}},
    failedCB: {cb: actions.failedStoreCard, args: {uid}},
    doNotDisplayError: true,
    openLoadingFlag: 4,
  }
  yield put(MiddlewareAction.api(payload))
}

function* successStoreSharedJobs(action) {
  console.log('successStoreSharedJobs')
  console.log(action)

  const {
    keyIssue: {
      list,
      count
    },
  } = yield select()

  // カード取り外し依頼
  yield put(actions.clearMessage())
  yield put(actions.setIsSuccess(true))
  yield put(actions.requestRemoveCard())
  yield put(actions.registerFlg(false))
}

function* requestRemoveCard() {
  //カードキーを外す動画(動画checkin3)を再生する
  yield put(actions.setMovieStatus(3))
  yield put(actions.setMessage('カードの発行が完了しました\nカードを外してください'))
  yield put(actions.registerFlg(false))

  // アプリ外から
  if (!window.webkit || !window.webkit.messageHandlers || !window.webkit.messageHandlers.icCard) {
    console.log('アプリ連携なし icCard')
    return
  }
  
  window.webkit.messageHandlers.icCard.postMessage('removeCard')
}
