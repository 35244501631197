import Keyboard from 'react-simple-keyboard';
import React, {Component} from 'react'

class KanaAlphaKeyboard extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      layout: props.layoutName || 'kana'
    };
  }

  render() {
    return <Keyboard 
      layout={{
        'kana': [
          'ャ ァ パ バ ダ ザ ガ ワ ラ ヤ マ ハ ナ タ サ カ ア',
          'ュ ィ ピ ビ ヂ ジ ギ ヲ リ ユ ミ ヒ ニ チ シ キ イ',
          'ョ ゥ プ ブ ヅ ズ グ ン ル ヨ ム フ ヌ ツ ス ク ウ',
          'ッ ェ ペ ベ デ ゼ ゲ {} レ {} メ ヘ ネ テ セ ケ エ',
          'ー ォ ポ ボ ド ゾ ゴ {} ロ {} モ ホ ノ ト ソ コ オ',
          '{} {alpha} {space} {bksp}'
        ],
        'alpha': [
          '` 1 2 3 4 5 6 7 8 9 0 - = {}',
          '{} q w e r t y u i o p [ ] \\',
          '{} a s d f g h j k l ; \' {}',
          '{} z x c v b n m , . / {}',
          '{shift} {kana} {space} {bksp}'
        ],
        'alpha-shift': [
          '~ ! @ # $ % ^ & * ( ) _ + {}',
          '{} Q W E R T Y U I O P { } |',
          '{} A S D F G H J K L : " {}',
          '{} Z X C V B N M < > ? {}',
          '{shift} {kana} {space} {bksp}'
        ]
      }}
      display={{
        '{bksp}': this.state.layout === 'kana' ? '削除' : 'Back space',
        '{space}': this.state.layout === 'kana' ? 'スペース' : 'Space',
        '{shift}': 'A/a',
        '{alpha}': 'ABC',
        '{kana}': 'カナ',
      }}
      onKeyPress={(button) => {
        switch (button) {
          case '{alpha}':
            this.setState({layout: 'alpha'})
            break;
          case '{kana}':
            this.setState({layout: 'kana'})
            break;
          case '{shift}':
            if (this.state.layout.endsWith('-shift')) {
              this.setState({layout: this.state.layout.substring(0, this.state.layout.lastIndexOf('-shift'))})
            } else {
              this.setState({layout: this.state.layout + '-shift'})
            }
            break;
          default:
            break;
        }
      }}
      {...this.props}
      layoutName={this.state.layout}
    />
  }
}

export default KanaAlphaKeyboard
