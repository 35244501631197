import React, {Component} from 'react'
import {ACC_COLOR} from '../../constants/common'
import styled from 'styled-components'


class TitleHeader extends Component {
  render() {
    const {headerTitle, fontSize, headerHeight, marginTop, marginBottom, headerWidth} = this.props
    const title = {
      // backgroundColor:BASE_COLOR,
      color:'#333',
      fontSize:fontSize?fontSize:'2.7rem',
      height:headerHeight?headerHeight:'6vw',
      lineHeight:headerHeight?headerHeight:'6vw',
      marginTop:marginTop?marginTop:'2vh',
      marginBottom:marginBottom?marginBottom:'',
      marginLeft:'auto',
      marginRight:'auto',
      textAlign:'center',
      width:headerWidth?headerWidth:'80vw'
    }
    return (
      <TitleBody style={title}><p>{headerTitle}</p></TitleBody>
    );
  }
}

TitleHeader.propTypes = {
}

export default TitleHeader

const TitleBody = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  &:before{
    content: '';
    position: absolute;
    bottom: -15px;
    display: inline-block;
    width: 60px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: ${ACC_COLOR};
    border-radius: 2px;
  }
`
