import Keyboard from 'react-simple-keyboard';
import React, {Component} from 'react'

class AlphaKeyboard extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      layout: props.layout || 'alpha'
    };
  }

  render() {
    return <Keyboard 
      layout={{
        'alpha': [
          '` 1 2 3 4 5 6 7 8 9 0 - = {}',
          '{} q w e r t y u i o p [ ] \\',
          '{} a s d f g h j k l ; \' {}',
          '{} z x c v b n m , . / {}',
          '{shift} {space} {bksp}'
        ],
        'alpha-shift': [
          '~ ! @ # $ % ^ & * ( ) _ + {}',
          '{} Q W E R T Y U I O P { } |',
          '{} A S D F G H J K L : " {}',
          '{} Z X C V B N M < > ? {}',
          '{shift} {space} {bksp}'
        ]
      }}
      display={{
        '{bksp}': this.props.language === 'ja' ? '削除' : 'Back space',
        '{space}': this.props.language === 'ja' ? 'スペース' : 'Space',
        '{shift}': 'A/a',
      }}
      onKeyPress={(button) => {
        switch (button) {
          case '{shift}':
            if (this.state.layout.endsWith('-shift')) {
              this.setState({layout: this.state.layout.substring(0, this.state.layout.lastIndexOf('-shift'))})
            } else {
              this.setState({layout: this.state.layout + '-shift'})
            }
            break;
          default:
            break;
        }
      }}
      {...this.props}
      layoutName={this.state.layout}
    />
  }
}

export default AlphaKeyboard
