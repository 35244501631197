import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {actions as loadingAction} from '../../modules/loading'
import styled from 'styled-components'
import {Spinner} from 'reactstrap'

// ローディング画面ラッパー
export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  color: #000;
  background: #fff;
  z-index: 9999;
`

//ローディング画面
export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

/**
 * ローディング
 */
class LoadingComponent extends Component {

  componentDidMount() {
    this.props.init()
  }

  render() {
    let {isOpen, isSmall = false} = this.props

    if (isOpen) {
      if (!isSmall) {
        return (
          <LoadingWrapper>
            <Loading>
              <Spinner color="danger" size={'lg'} style={{width: '10rem', height: '10rem'}}/>
            </Loading>
          </LoadingWrapper>
        )
      } else {
        return (
          <Spinner color="danger" style={{position: 'fixed', top: 0, right: 0, width: '2rem', height: '2rem'}}/>
        )
      }
    } else {
      return null
    }
  }
}

LoadingComponent.propTypes = {
  isOpen: PropTypes.bool,
  isSmall: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    isOpen: state.loading.isOpen,
    isSmall: state.loading.isSmall
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(loadingAction.init())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoadingComponent)
