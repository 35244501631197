import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {
  Col,
  Row,
} from 'reactstrap'
import {push} from 'connected-react-router'
import {BASE_COLOR, WHITE_COLOR} from '../../constants/common'

/**
 * ヘッダー
 */
class Header extends Component {

  render() {

    const {
      HeaderMiddle, HeaderRight, //要素
      MiddleAreaStyle,
      headerTitle,
      HeaderBkColor = BASE_COLOR, HeaderColor, HeaderHeight = '10vh', HeaderWidth,
      disable_click_logo = false
    } = this.props


    return (
      <HeaderArea headerBkColor={HeaderBkColor}
        headerColor={HeaderColor}
        headerHeight={HeaderHeight}
        headerWidth={HeaderWidth}>
        <Rows>
          <Col>
            <LeftArea onClick={() => {
              if (!disable_click_logo) {
                this.props.clickLogo()
              }
            }}>
              {/*<HeaderLogoImg src={ImgUrl} ImgWidth={isEmpty(ImgWidth)?'7vw':ImgWidth}/>*/}
              <div style={HeaderStyle}>
                {headerTitle}
              </div>
            </LeftArea>
          </Col>
          <Col>
            <MiddleArea style={MiddleAreaStyle}>
              {/*AppTitle*/}
              {HeaderMiddle}
            </MiddleArea>
          </Col>
          <Col>
            <RightArea>
              {HeaderRight}
            </RightArea>
          </Col>
        </Rows>
      </HeaderArea>
    );
  }
}

Header.propTypes = {
  disable_click_logo: PropTypes.bool
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    clickLogo: () => {
      dispatch(push('/main-menu'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

/* headerのベース*/
const HeaderArea = styled.section`
  background-color:${props => props.headerBkColor};
  color: ${WHITE_COLOR};
  height:${props => props.headerHeight};
  width:${props => props.headerWidth};
`

/* headerを三等分にする
*  LeftArea | MiddleArea | RightArea
*/
const LeftArea = styled.div`
  width: 100%;
    `

const MiddleArea = styled.div`
    
    `
const RightArea = styled.div`
      overflow:hidden;
    `

const HeaderStyle = {
  fontSize: '2rem',
  margin: '2vh',
  marginLeft: '4vw',
}

const Rows = styled(Row)`
      margin-right:0!important;
    `
