import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import {containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next';
import CustomContainer from '../../components/atomics/CustomContainer'
import Btn from '../../components/atomics/Button'


class ChechoutCompleted extends Component {
  
  componentDidMount() {
    //10秒後にTOP画面に戻す
    this.setState(
      {
        to_top_timer: setTimeout(() => {
          this.props.nextPage()
        }, 10000)
      }
    )
    this.video1.play()
  }

  componentWillUnmount() {
    clearTimeout(this.state.to_top_timer)
  }
  
  render() {
    const {t} = this.props
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}></Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <MsgArea style={{marginTop:'13.5vh'}}>
                  <p>{t('カードキーを返却BOXへ返却してください')}</p>
                </MsgArea>
                <AnimationArea>
                  <video loop muted playsInline width='500' id="video1" ref={ref => this.video1 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkout_2.mp4" type="video/mp4" />
                  </video>
                </AnimationArea>
              </div>
            </ConfirmArea>
            <Btn btnLabel={t('次へ')} marginTop='10vh' OnClick={() => this.props.clickBtn('/checkout/completed')}/>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


ChechoutCompleted.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    backTop: () => {
      dispatch(push('/checkout/key-touch'))
    },
    nextPage: () => {
      dispatch(push('/checkout/completed'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChechoutCompleted))

/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
`

const MsgArea = styled.div`
  font-size:2.7rem;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
`
const AnimationArea = styled.div`
  // margin:6vw 2vw;
  // width:60vw;
  // height:60vh;
  text-align:center;
`