import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
} from 'reactstrap'
import { reduxForm, change } from 'redux-form'
import styled from 'styled-components'
import { containerStyle } from '../../constants/common'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import CustomCard from '../../components/atomics/CustomCard'
import CustomInput from '../../components/atomics/CustomInput'
import TitleHeader from '../../components/atomics/TitleHeader'
import Btn from '../../components/atomics/Button'
import {actions as reserveAction} from '../../modules/reserve'
import {RowCenter} from '../../util/styled'
import {isEmpty} from '../../util/common'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import KanaAlphaKeyboard from '../../components/atomics/KanaAlphaKeyboard'
import AlphaKeyboard from '../../components/atomics/AlphaKeyboard'

/**
 * 予約検索
 */
class ReserveSearch extends Component {
  componentDidMount() {
    this.props.init()
  }

  render() {
    const {
      formValues = {},
      searchType,
      i18n,
      t,
    } = this.props

    return (
      <section　style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')}  btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn('/checkin')}/>
          <Wizard index={1}/> 
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={{containerStyle}}>
            <Row style={clear_both}>
              {searchType === 'name'
                ? <TitleHeader headerWidth='100vw' marginTop='2vh' fontSize='2.65rem' headerTitle={t('ご予約のお名前をフルネームで入力してください')}/>
                : <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('予約番号を入力してください')}/>
              }
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <Row>
                  <Col xs={12}>
                    <InputArea>
                      {searchType === 'name' ?
                        <Row>
                          <Col xs={12}>
                            <RowCenter>
                              <CustomCard cardWidth={'97%'} cardBodyPadding={'0'} cardBorderColor={'#888888'}>
                                <CustomInput name="name" component="input" type="text" placeholder="" fontSize="1.5em" onChange={e => this.kanaKeyboard.setInput(e.target.value)} readOnly={true}/>
                              </CustomCard>
                              <KanaAlphaKeyboard 
                                onChange={input => this.props.dispatch(change('reserveSearch', 'name', input))}
                                keyboardRef={r => (this.kanaKeyboard = r)}
                                layoutName={i18n.language === 'ja' ? 'kana' : 'alpha'}
                                language={i18n.language}
                              />
                            </RowCenter>
                            <Btn disabled={isEmpty(formValues.name)} btnLabel={t('次へ')} marginTop='10vh' OnClick={() => this.props.searchReserve('name')}/>
                          </Col>
                        </Row>
                        :
                        <Row>
                          <Col xs={12}>
                            <RowCenter>
                              <CustomCard cardWidth={'97%'} cardBodyPadding={'0'} cardBorderColor={'#888888'}>
                                <CustomInput name="reserve_num" component="input" type="text" placeholder="" fontSize="1.5em" onChange={e => this.alphaKeyboard.setInput(e.target.value)} readOnly={true} />
                              </CustomCard>
                              <AlphaKeyboard 
                                onChange={input => this.props.dispatch(change('reserveSearch', 'reserve_num', input))}
                                keyboardRef={r => (this.alphaKeyboard = r)}
                                language={i18n.language}
                              />
                            </RowCenter>
                            <Btn disabled={isEmpty(formValues.reserve_num)} btnLabel={t('次へ')} marginTop='10vh' OnClick={() => this.props.searchReserve('reserve_num')}/>
                          </Col>
                        </Row>}
                    </InputArea>
                  </Col>
                </Row>
                {/* <Button disabled={isEmpty(formValues.name)} onClick={()=>this.props.searchReserve('name')}>お名前で検索</Button> */}
              </div>
            </ConfirmArea>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

ReserveSearch.propTypes = {}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult,
    searchType: state.reserve.searchType,
    formValues: state.form.reserveSearch ? state.form.reserveSearch.values : {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reserveAction.refreshReserve())
    },
    searchReserve: searchType => {
      console.log('searchReserve1')
      dispatch(reserveAction.findReserve({searchType}))
    },
    clickReserveRow: item => {
      dispatch(reserveAction.selectReserve(item))
    },
    clickBtn: path => {
      dispatch(push(path))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'reserveSearch'
  })(
    withTranslation()(ReserveSearch)
  )
)

/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
`
const InputArea = styled.div`
    margin-top:1vh;
`
const TitleArea = styled.div`
  &.en {
    font-size:1.8rem;
  }
`
// const Title = styled.div`
//   background-color:${BASE_COLOR};
//   color:#fff;
//   font-size:1.5em;
//   height:6vw;
//   line-height:6vw;
//   margin-top:2vw;
//   width: 100vw;
// `
// const margin_L1 = {
//   marginLeft:'1vw'
// }
// const margin_L4 = {
//   marginLeft:'6vw'
// }

// const CustomCard = {
//   padding: 'initial'
// }

// const selected ={
//   backgroundColor:ACC_COLOR,
//   color:'#fff'
// }
// const qrButtonStyle = {
//   background: ACC_COLOR,
//   border: ACC_COLOR,
//   height: '70px',
//   width: '150px',
// }

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
