import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
  Card, CardBody,
  Button,
} from 'reactstrap'
import {containerStyle, GREY_COLOR, SUB_COLOR, ACC_COLOR} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import { RowCenter } from '../../util/styled'
import {actions as paymentAction} from '../../modules/payment'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import {actions as confirmAction} from '../../modules/confirm'
import {actions as loadingAction} from '../../modules/loading'
import {actions as errorAction} from '../../modules/error'
import Btn from '../../components/atomics/Button'
import { reduxForm, change } from 'redux-form'
import PayMethodSelect from './Payment/PayMethodSelect'
import PayCreditSequence from './Payment/PayCreditSequence'
import PayGinrenSequence from './Payment/PayGinrenSequence'
import PayFailure from './Payment/PayFailure'
import * as vega3000 from '../../modules/vega3000'

class Payment extends Component {
  componentWillUnmount() {
    this.props.end()
  }

  render() {
    const {
      selectedReserve,
      method,
      type,
      sequence,
      message,
      credit,
      t
    } = this.props
    let totalBalance = 0
    selectedReserve.forEach(item => totalBalance += Number(item.balance.Balance))
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden', whiteSpace:'pre-line'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={4}/> 
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <RowCenter style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('お支払い方法を選択してください')}/>
            </RowCenter>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <Total>
                <TotalLabel>{t('合計金額')}</TotalLabel>
                <TotalFee>¥{totalBalance.toLocaleString()}</TotalFee>
              </Total>
              <PaymentArea>
                {/* QRコード決済なしver */}
                <SelectPayment style={{width:'35vw'}}>
                  <div style={{width:'20vw', margin:'0 auto', padding:'2vh'}}>
                    <SelectBtnArea style={{display:'block'}}>
                      <PaymentBtn onClick={()=>this.props.selectPayment('credit')}>{t('クレジットカード')}</PaymentBtn>
                    </SelectBtnArea>
                    <CardLogoArea>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/visa.png" alt="" /></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/master_logo.png" alt=""/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/jcb_logo.png" alt=""/></CardLogo>
                    </CardLogoArea>
                    <CardLogoArea style={{marginTop:'1vh'}}>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/amex_logo.png" alt="" style={{width:'4vw', height:'4vw'}}/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/diners_logo.png" alt=""/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/ginren-logo.gif" alt=""/></CardLogo>
                    </CardLogoArea>
                  </div>
                </SelectPayment>
                <SelectPayment style={{width:'35vw'}}>
                  <div style={{width:'20vw', margin:'0 auto', padding:'2vh'}}>
                    <SelectBtnArea style={{display:'block'}}>
                      <PaymentBtn onClick={()=>this.props.clickBtn('/checkin/Instructions')}>{t('現金')}</PaymentBtn>
                    </SelectBtnArea>
                    <ImageArea>
                      <div style={{fontSize:'1vw'}}>{t('※フロントにて決済致します')}</div>
                      <Logo><img src="https://dfc78s572732n.cloudfront.net/card_logo/cash.png" alt="" style={{width:'15vw'}}/></Logo>
                    </ImageArea>
                  </div>
                </SelectPayment>
                {/* QRコード決済ありver */}
                {/* <SelectPayment>
                <div style={{margin:'0 auto',padding:'2vh'}}>
                  <SelectBtnArea style={{display:'block'}}>
                    <PaymentBtn onClick={()=>this.props.postPayment()}>{t('クレジットカード')}</PaymentBtn>
                  </SelectBtnArea>
                    <CardLogoArea>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/visa.png" alt="" /></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/master_logo.png" alt=""/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/jcb_logo.png" alt=""/></CardLogo>
                    </CardLogoArea>
                    <CardLogoArea style={{marginTop:'1vh'}}>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/amex_logo.png" alt="" style={{width:'4vw', height:'4vw'}}/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/diners_logo.png" alt=""/></CardLogo>
                    </CardLogoArea>
                </div>
              </SelectPayment>
              <SelectPayment>
                <div style={{margin:'0 auto', padding:'2vh'}}>
                  <SelectBtnArea style={{display:'block'}}>
                    <PaymentBtn onClick={()=>this.props.clickBtn('/checkin/Instructions')}>{t('QRコード')}</PaymentBtn>
                  </SelectBtnArea>
                  <Logo><img src="https://dfc78s572732n.cloudfront.net/card_logo/paypay_logo.png" alt="" style={{width:'10vw'}}/></Logo>
                  <Logo><img src="https://dfc78s572732n.cloudfront.net/card_logo/linepay_logo.png" alt="" style={{width:'10vw'}}/></Logo>
                </div>
              </SelectPayment>
              <SelectPayment>
                <div style={{margin:'0 auto', padding:'2vh'}}>
                  <SelectBtnArea style={{display:'block'}}>
                    <PaymentBtn onClick={()=>this.props.clickBtn('/checkin/Instructions')}>{t('現金')}</PaymentBtn>
                  </SelectBtnArea>
                    <ImageArea>
                      <div style={{fontSize:'1vw'}}>{t('※フロントにて決済致します')}</div>
                      <Logo><img src="https://dfc78s572732n.cloudfront.net/card_logo/cash.png" alt="" style={{width:'15vw'}}/></Logo>
                    </ImageArea>
                </div>
              </SelectPayment> */}
              </PaymentArea>
            </ConfirmArea>
          </Container>
        </CustomContainer>
        {method == 'credit' && type === '' && <PayMethodSelect onSelect={type => { this.props.postPayment(type) }}/>}
        {method == 'credit' && type === 'credit' && sequence !== 'failure' && <PayCreditSequence sequence={sequence} brand={credit.cardBrandName} amount={credit.amount}/>}
        {method == 'credit' && type === 'credit' && sequence === 'failure' && <PayFailure title={t('クレジット支払い')} message={message}/>}
        {method == 'credit' && type === 'ginren' && sequence !== 'failure' && <PayGinrenSequence sequence={sequence} brand={credit.cardBrandName} amount={credit.amount}/>}
        {method == 'credit' && type === 'ginren' && sequence === 'failure' && <PayFailure title={t('銀聯支払い')} message={message}/>}

        {(!vega3000.hasNative && type) && (
          <Card style={{position: 'absolute', bottom: 0, right: 0, zIndex: '99999'}}>
            <CardBody>
              <p>テスト用</p>
              <p><Button onClick={e => { vega3000.debugTransition('001') }}>キャンセル</Button></p>
              <p><Button onClick={e => { vega3000.debugTransition('002') }}>カード読込</Button></p>
              <p><Button onClick={e => { vega3000.debugTransition('006') }}>暗証番号</Button></p>
              <p><Button onClick={e => { vega3000.debugTransition('007') }}>通信中</Button></p>
              <p><Button onClick={e => {
                vega3000.debugSuccess({
                  datetime: moment().format('YYYYMMDDHHmmss'),
                  amount: totalBalance,
                  tax: 0,
                  icBrand: 'Visa',
                  customerNumber: '123456',
                  slipNumber: '1234567890'
                })
              }}>決済成功</Button></p>
              <p><Button onClick={e => { this.props.dispatch(change('payment', 'sequence', 'complete')) }}>*決済完了</Button></p>
              <p><Button onClick={e => {
                vega3000.debugFailure('001', 'お取り扱いできません\r\nクレジット/金融機関の受付\r\nを休止しています')
              }}>決済失敗</Button></p>
            </CardBody>
          </Card>
        )}
      </section>
    );
  }
}


Payment.propTypes = {
}

const mapStateToProps = state => {
  return {
    method: state.form.payment && state.form.payment.values.method,
    type: state.form.payment && state.form.payment.values.type,
    sequence: state.form.payment && state.form.payment.values.sequence,
    message: state.form.payment && state.form.payment.values.message,
    selectedReserve: state.reserve.selectedReserve,
    credit: state.payment.paymentResult ? state.payment.paymentResult.credit : {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    end: () => {
      vega3000.stop()
    },
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    },
    selectPayment: (method) => {
      dispatch(change('payment', 'method', method))
      dispatch(change('payment', 'type', ''))
      dispatch(change('payment', 'sequence', ''))
    },
    postPayment: (type) => {
      if (type === '') {
        dispatch(change('payment', 'method', ''))
        dispatch(change('payment', 'type', ''))
        dispatch(change('payment', 'sequence', ''))
        return
      }
      dispatch(change('payment', 'type', type))

      // Vega3000カード読込画面までローディング
      dispatch(loadingAction.openLoading())
      dispatch(loadingAction.onContinue())

      // 決済完了時の遷移制御フラグ
      let isSucceeded = false

      dispatch(paymentAction.requestTerminalPayment((amount, tax) => {
        vega3000
          .start(type, amount, tax)
          .success((result) => {
            isSucceeded = true

            let credit = {
              date: result.datetime,
              amount: Number(result.amount) + Number(result.tax),
              cardBrandName: result.icBrand,
              cardSuffix: result.customerNumber,
              approvalCode: result.approvalNumber,
              humanReadableIdentifier: result.slipNumber,
            }
    
            dispatch(paymentAction.postPayment({credit}))
            dispatch(change('payment', 'sequence', 'complete'))
          })
          .failure((result) => {
            // 失敗
            dispatch(change('payment', 'sequence', 'failure'))
            dispatch(change('payment', 'message', result.message))
          })
          .cancel(() => {
            // キャンセル
          })
          .transition((page) => {
            dispatch(loadingAction.offContinue())

            switch (page) {
              case '001':
                // メイン画面
                if (isSucceeded) {
                  // 完了後はカギ発行へ進む
                  dispatch(paymentAction.sendKeyIssue())
                  break;
                }

                vega3000.stop()
                dispatch(change('payment', 'method', ''))
                dispatch(change('payment', 'sequence', ''))
                dispatch(change('payment', 'type', ''))
                break
              case '002':
              case '100':
                // カード読込画面
                dispatch(change('payment', 'sequence', 'read'))
                break
              case '006':
              case '101':
                // 暗証番号入力画面
                dispatch(change('payment', 'sequence', 'pin'))
                break
              case '007':
              case '102':
                // 通信中画面
                dispatch(change('payment', 'sequence', 'connecting'))
                break
            }
          })
          .error((message) => {
            dispatch(loadingAction.offContinue())
            dispatch(change('payment', 'method', ''))
            dispatch(change('payment', 'type', ''))
            dispatch(change('payment', 'sequence', ''))
            dispatch(errorAction.displayError(message))
          })

        if (!vega3000.hasNative) {
          // ブラウザテスト用
          setTimeout(() => {
            vega3000.debugTransition('002')
          }, 1000)
          return
        }
      }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'payment',
    initialValues: {
      method: '',
      type: '',
      sequence: '',
      message: '',
    }
  })(
    withTranslation()(Payment)
  )
)

/* CSS定義 */
const ConfirmArea = styled.div`
  margin:0 auto;
  // width: 80vw;
`
const Logo = styled.div`
  max-width: 28vw;
  margin: 0 auto;
  padding: 10px;
`
const SelectBtnArea = styled.div`
  padding: 0px;
  margin: 0 auto;
`
const CardLogoArea = styled.div`
  display:flex;
  padding:10px;
`
const CardLogo = styled.div`
  display:flex;
  padding:5px;
`
const ImageArea = styled.div`
  padding: 0px;
  margin: 0 auto;
`
const LogoImg = styled.img`
  width:calc(100vw/25);
  height:calc(100vw/35);
`
const Total = styled.ul`
  display: flex;
  list-style-type:none;
  width:50vw;
  margin: 3vh auto 0 auto;
  height: 4.5vw;
`
const TotalLabel = styled.li`
  background-color:${GREY_COLOR};
  padding:1vw;
  text-align:center;
  width: 20vw;
  font-size:1.5em;
`
const TotalFee = styled.li`
  border:1px solid ${GREY_COLOR};
  text-align:center;
  padding: 1vw;
  width: 20vw;
  font-size:1.5em;
`
const PaymentArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin:7vw auto;
  max-width:80vw;
  min-height:30vh;
  text-align:center;
  list-style-type:none;
  padding:0;
`
const SelectPayment = styled.div`
  border:1px solid #484747;
  min-width:20vw;
`
const PaymentLabel = styled.p`
  font-size:2vw;
  margin-top:1.5vw;
  font-size:1.5em;
`

const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
const PaymentBtn = styled.div`
  background-color: ${SUB_COLOR};
  border-radius: 0.4vw;
  box-shadow: 0px 4px ${ACC_COLOR};
  color: #fff;
  font-size: 1.5em;
  float: right;
  height: 4.5vw;
  line-height: 4.5vw;
  text-align: center;
  width: 17vw;
  margin-bottom:2vh;
`