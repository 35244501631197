import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery, all } from 'redux-saga/effects'
import { SUMAKAGI_TENANTS_API, SETTINGS_API } from '../constants/api-config'
import { FACILITY_ID } from '../constants/settings'
import { api } from './middleware'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    setIpadAppVersion: payload => payload,
    initSumakagiTenants: () => ({}),
    setSumakagiTenants: payload => payload,
    initSettings: () => ({}),
    setSettings: payload => payload,
    setParams: payload => payload,
  },
  { prefix: 'hoshino/setting'},
)
/*********************************
 * REDUCER
 ********************************/
export const initialState = {
  ipadAppVersion: '',
  epsonUrl: '',
  vega3000Url: '',
  sumakagiTenants: [],
  cico: {
    printer: [],
  },
}

const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.setIpadAppVersion]: (state, action) => ({...state, ipadAppVersion: action.payload}),
  [actions.setSumakagiTenants]: (state, action) => ({...state, sumakagiTenants: action.payload}),
  [actions.setSettings]: (state, action) => ({
    ...state,
    ...action.payload.reduce((group, setting) => {
      if (!group[setting.key1]) {
        group[setting.key1] = {}
      }
      if (!group[setting.key1][setting.key2]) {
        group[setting.key1][setting.key2] = []
      }
      group[setting.key1][setting.key2].push(setting)

      return group
    }, {})
  }),
  [actions.setParams]: (state, action) => ({...state, ...action.payload}),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* settingSaga() {
  yield takeEvery(actions.initSumakagiTenants, initSumakagiTenants)
  yield takeEvery(actions.initSettings, initSettings)
}

function* initSumakagiTenants() {
  const URL = `${SUMAKAGI_TENANTS_API}?facility_id=${FACILITY_ID}`
  const payload = {
    url: URL,
    method: 'GET',
  }
  const sumakagiTenants = yield call(api, {payload})
  if (!sumakagiTenants) {
    return
  }

  yield put(actions.setSumakagiTenants(sumakagiTenants))
}

function* initSettings() {
  const URL = `${SETTINGS_API}?facility_id=${FACILITY_ID}`
  const payload = {
    url: URL,
    method: 'GET',
  }
  const settings = yield call(api, {payload})
  console.log(settings)
  if (!settings) {
    return
  }

  yield put(actions.setSettings(settings))
}
