import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import {containerStyle} from '../../constants/common'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import TitleHeader from '../../components/atomics/TitleHeader'
import Btn from '../../components/atomics/Button'
import {actions as reserveAction} from '../../modules/reserve'
import '../../css/SearchResult.css'
import Wizard from '../../components/atomics/StepWizard'
import IconBtn from '../../components/atomics/IconButton'
import {actions as confirmAction} from '../../modules/confirm'

/**
 * 予約検索
 */
class SearchResult extends Component {


  render() {
    const {searchResult = [], selectedReserve, t} = this.props    
    const isNotSelected = selectedReserve.length === 0
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')

    return (
      <section　style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn('/checkin/select_payment')}/>
          <Wizard index={1}/>
          <IconBtn btnLabel={t('中止')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/cancel.png' OnClick={() => this.props.clickCancel('/checkin', msg)}/>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('まとめてお支払いするお部屋を選択してください')}/>
            </Row>
            <div className="check_room">
              <label style={{marginTop:'5vh', paddingLeft:'10px'}}>{t('選択されているお部屋')}<RoomCount>{selectedReserve.length}/{searchResult.length}</RoomCount></label>
            </div>
            <div className="cp_ipcheck" style={{marginTop:'0'}}>
              {searchResult.map((item, key) => {
                return (
                  <div className="box" key={key}>
                    <input type="checkbox" id={item.RoomAccountID} onClick={()=>this.props.clickReserveRow(item)} checked={selectedReserve.some (reserve => reserve.RoomAccountID === item.RoomAccountID)} onChange={()=>({})}/>
                    <label htmlFor={item.RoomAccountID} >{t('お部屋')}{key + 1}<NumberPeople>{t('人数')}：{item.NumberOfGuests}{t('名')}</NumberPeople><RoomTypeName>{t('タイプ')}：{item.RoomTypeName}</RoomTypeName></label>
                  </div>
                )
              })}
            </div>
            <Btn disabled={isNotSelected} btnLabel={t('次へ')}  btnFloat='right' marginTop='5vh' OnClick={()=>this.props.clickNextBtn()}/>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

SearchResult.propTypes = {}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult,
    selectedReserve: state.reserve.selectedReserve,
    initialValues: {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchReserve: searchType => dispatch(reserveAction.searchReserve(searchType)),
    clickReserveRow: item => {
      dispatch(reserveAction.selectReserve(item))
    },
    clickNextBtn: () => {
      // 部屋選択画面を通ったかのフラグ
      dispatch(reserveAction.roomSetflg(true))

      dispatch(reserveAction.searchRoom())
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchResult))
const RoomCount = styled.span`
  float: right;
  margin-left: 2vw;
`
const NumberPeople = styled.span`
  float: right;
  margin-right: 3vw;
  margin-left: auto;
`
const RoomTypeName = styled.span`
  float: right;
  margin-right: 3vw;
  margin-left: auto;
`

const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`