import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as form } from 'redux-form'
import notice from './notice'
import error from './error'
import loading from './loading'
import confirm from './confirm'
import auth from './auth'
import master from './master'
import numericpad from './numericpad'
import setting from './setting'
import reserve from './reserve'
import payment from './payment'
import keyIssue from './keyIssue'
import checkOut from './checkOut'
import keysManually from './keysManually'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  notice,
  error,
  confirm,
  loading,
  master,
  numericpad,
  form,
  setting,
  reserve,
  payment,
  keyIssue,
  checkOut,
  keysManually,
})
