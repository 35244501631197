import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import {ACC_COLOR, SUB_COLOR, containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next';
import { destroy as formDestroy } from 'redux-form'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {actions as reserveAction} from '../../modules/reserve'
import {actions as paymentAction} from '../../modules/payment'

class Checkin extends Component {
  componentDidMount() {
    this.props.initReserve()
  }
  state = { 
    count: 0, 
    interval: 0
  };

  //ロゴを5回クリックでTOPへ遷移
  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.history.push('/')
    }
  }

  render() {
    const { t, i18n } = this.props
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <Row style={{position:'absolute', top:'3vh', left:'5vh'}}>
            <Logo className={[i18n.language]} onClick={this.clickDummyBtn}><img src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`} alt="" style={{height:'4vw'}}/></Logo>
          </Row>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('チェックイン方法を選択してください')}/>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
            <div height='68vh'>
              <SelectBtnArea>
                <SelectBtn style={{width: '40vw'}}>
                  <CheckinBtn style={{height: '28vh', width: '40vw', lineHeight:'28vh', fontSize:'4.5rem'}} onClick={()=>this.props.clickQRBtn()}>{t('QRコード')}</CheckinBtn>
                </SelectBtn>
                <SelectBtn style={{flexDirection: 'column'}}>
                  <CheckinBtn className={[i18n.language]} onClick={()=>this.props.clickBtn('name')}>{t('お名前')}</CheckinBtn>
                  <CheckinBtn className={[i18n.language]} onClick={()=>this.props.clickBtn('reserve_num')}>{t('予約番号')}</CheckinBtn>
                </SelectBtn>
              </SelectBtnArea>
              <RowCenter>
                <p style={{fontSize:'1.5em', marginTop:'8vh', whiteSpace: 'pre-line'}}>{t('各種ご優待券やギフト券でご予約のお客様はフロントカウンターまでお越しください')}</p>
              </RowCenter>
              <LanguageArea className={[i18n.language]}>
                <LanguageBtn style={i18n.language === 'ja' ? selected : {}} onClick={()=>{ i18n.changeLanguage('ja') }}>日本語</LanguageBtn>
                <LanguageBtn style={i18n.language === 'en' ? selected : {}} onClick={()=>{ i18n.changeLanguage('en') }}>ENGLISH</LanguageBtn>
              </LanguageArea>
            </div>
            </ConfirmArea>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}

Checkin.propTypes = {
}
const mapStateToProps = state => {
}
const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: searchType => {
      dispatch(reserveAction.setSearchType(searchType))
      dispatch(push('/checkin/search'))
    },
    clickQRBtn: () => {
      dispatch(push('/checkin/qr'))
    },
    initReserve: () => {
      dispatch(formDestroy(
        'customerEdit',
        'customerEditOption',
      ))
      dispatch(reserveAction.initReserve())
      dispatch(paymentAction.initReserve())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(Checkin)
)

/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
  // width: 80vw;
`
const Logo = styled.div`
  margin-right: auto;
`
const MainArea = styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  list-style:none;
  max-width:75vw;
`
const RecommendArea = styled.div`
  font-size:1.5rem;
  margin: 0 3vw;
`
const RecommendHeader = styled.div`
  max-width:20vw;
  text-align:center;
  color:#245C72;
`
const SelectBtnArea = styled(MainArea)`
  padding: 0px;
  margin: 0 auto;
`

const SelectBtn = styled(MainArea)`
  padding: 0px;
  width: 25vw;
`

const LanguageArea = styled(MainArea)`
  padding: 0;
  margin: 11vh auto 0 auto;
  justify-content: space-evenly;
`
const CheckinBtn = styled.li`
  background-color:${SUB_COLOR};
  border-radius:0.4vw;
  box-shadow:0px 4px ${ACC_COLOR};
  color:#fff;
  font-size:2.5rem;
  height:12vh;
  line-height:12vh;
  text-align:center;
  width:25vw;
  &.en {
    font-size:1.8rem;
  }
`
const LanguageBtn = styled.li`
  background-color:#DDDDDD;
  color:#fff;
  text-align:center;
  height:5vh;
  line-height:5vh;
  width:10vw;
  bottom:5vh;
`
const selected ={
  backgroundColor:'#31A237',
  color:'#fff',
  fontWeight:'bold'}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
  opacity:0;
`