import moment from 'moment'

/**
 * undefined null 空文字の時はtrue、それ以外はfalse
 * @param value
 * @returns {boolean}
 */
export const isEmpty = value => {
  if (value === undefined || value === null || value === '') {
    return true
  } else {
    return false
  }
}

/**
 * 空のオブジェクトの場合true
 * @param object
 * @returns {boolean}
 */
export const isEmptyObject = object => {
  if (isEmpty(object)) return true
  return Object.keys(object).length === 0
}

/**
 * パラメータ抽出用
 * @param target object ターゲットのオブジェクト
 * @param param  array ターゲットオブジェクトから抽出するプロパティ名
 * @returns {{}}
 */
export const _pick = (target, param) => {
  let resultObj = {}

  param.map(key => {
    resultObj = Object.assign({}, resultObj, {[key]: target[key]})
  })

  return resultObj
}
/**
 * 時間変換用
 * @param date
 * @returns {string}
 * @private
 */
export const _dateFormat = date => {
  if (!!date) {
    let y = date.getFullYear()
    let mo = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let mi = date.getMinutes()

    if (mo < 10) {
      mo = '0' + mo
    }
    if (d < 10) {
      d = '0' + d
    }

    return y + '-' + mo + '-' + d + ' ' + h + ':' + mi
  } else {
    return ''
  }
}
/**
 * カレンダー日時変換用
 * @param date
 * @returns {string}
 * @private
 */
export const _calendarFormat = date => {
  //カレンダーから日付を選択した場合、T文字入るので整形する
  if (!!date && date.indexOf('T') !== -1) {
    return date.substring(0, date.indexOf('T'))
  } else {
    return date
  }
}
/**
 * yyyy-mm-dd HH:mm:ss を yyyy/mm/dd HH:mm へ変換
 */
// export const _formatYMDHM = stringDate => {
//   return stringDate ? stringDate.replace(/-/g, '/').substr(0, 16) : ''
// }
/**
 * yyyy-mm-dd HH:mm:ss を yyyy/mm/dd へ変換
 */
export const _formatYMD = stringDate => {
  let return_string = ''
  if (stringDate) {
    return_string = stringDate
      ? stringDate.replace(/-/g, '/').substr(0, 10)
      : ''
    if (stringDate.indexOf('※') > -1) {
      return_string = return_string + '※'
    }
  }
  return return_string
}
/**
 * yyyy-mm-dd HH:mm:ss を yyyy/mm へ変換
 */
export const _formatYM = stringDate => {
  return stringDate ? stringDate.replace(/-/g, '/').substr(0, 7) : ''
}
export const _formatYMD_UTC = string => {
  return moment(string).add(9, 'h').format('YYYY/MM/DD')
}
export const _formatYMDHM_UTC = string => {
  return moment(string).add(9, 'h').format('YYYY/MM/DD HH:mm')
}
export const _formatYMDHM = string => {
  return moment(string).format('YYYY/MM/DD HH:mm')
}
/**
 * 伝票番号と枝番を結合
 */
export const _margeNoAndBranch = (num, branch) => {
  if (num && branch) {
    return num + '-' + branch
  } else if (num && !branch) {
    return num
  } else {
    return ''
  }
}
/**
 * 今日の日付（午前０時）を返す
 * @returns {Date}
 */
export const getToday = () => {
  const today = new Date()
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0
  )
}

/**
 * Dateオブジェクトをdatepicker用にハイフン区切りのyyyy-mm-ddに変換する
 * @param date
 * @returns {string}
 */
export const convertToDateString = (date = null, addHour = false) => {

  if (isEmpty(date)) return ''

  if (typeof date === 'object') {
    let result = (
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getDate()).slice(-2)
    )
    if (addHour) {
      result = result
        + ' '
        + ('00' + date.getHours()).slice(-2)
        + ':'
        + ('00' + date.getMinutes()).slice(-2)
        + ':'
        + ('00' + date.getSeconds()).slice(-2)
    }
    return result
  } else {
    return date
  }
}

/**
 * APIから返されたYYYY-MM-DD HH:㎜:ssをJavaScriptのオブジェクトにし、getTimeを返す
 * @param yyyymmdd
 * @returns {Date}
 */
export const _getTime = yyyymmdd => {
  //yyyy-mm-dd の部分のみ取得
  let formated_date = yyyymmdd.substr(0, 10)
  //ハイフン区切りで年、月、日を取得
  let date_array = formated_date.split('-')
  //getTime により時間取得
  return new Date(
    date_array[0],
    date_array[1],
    date_array[2],
    0,
    0,
    0
  ).getTime()
}
/**
 * 明細の選択状況によって、伝票の選択を制御する
 *  明細がすべて選択されている場合は伝票の選択をつける
 *  明細の中で選択されていないものがある場合は伝票の選択をはずす
 * @param dtls 明細配列
 * @returns {*}
 * @private
 */
export const checkDtlsSelected = dtls => {
  let dtlSelectedFlag = true

  if (Array.isArray(dtls)) {
    dtls.map(dtl => {
      //選択されていないものがある場合、チェックを外す
      if (!dtl.isSelected) {
        dtlSelectedFlag = false
      }
    })
  }
  return dtlSelectedFlag
}
/**
 * オブジェクト配列に対して、特定のキーを持つ重複オブジェクトは除外する
 * @param list
 * @param keyName
 * @param allowEmpty 空文字を検査対象に含めるかのフラグ
 *        true: keyNameが空文の場合も重複チェックする
 *        false: keyNameが空文字なら重複チェックしない
 * @returns {Array}
 */
export const removeDuplicationObject = (list, keyName, allowEmpty) => {
  //重複チェックのための配列
  let keyList = []

  //結果配列
  let resultList = []

  if (Array.isArray(list)) {
    list.map(el => {
      //指定されたキーがすでに重複チェック配列に存在するかチェック
      if (allowEmpty) {
        if (!keyList.includes(el[keyName])) {
          //存在しない場合
          keyList.push(el[keyName])
          resultList.push(el)
        }
      } else {
        if (!isEmpty(el[keyName]) && !keyList.includes(el[keyName])) {
          //存在しない場合
          keyList.push(el[keyName])
          resultList.push(el)
        }
      }
    })
  }

  return resultList
}

/**
 * オブジェクト配列のソート関数(複数キー対応)
 * @param keyList array ソートする際の判断材料のオブジェクト配列(添え字が若い要素ほど優先する)
 *                      [
 *                        {name: xxxx, orderBy: 'asc'},
 *                        {name: yyyy, orderBy: 'dsc'},
 *                        {name: zzzz, orderBy: 'asc'},
 *                      ]
 *                      この場合、xxxx で昇順、yyyy で降順、zzzz で昇順に並び替える
 * @param targetList array ソート対象のオブジェクト配列
 * @param order 昇順、降順 'asc'か'dsc'
 */
export const sortAscend = (keyList, targetList) => {
  //並び替え優先順に従ってソート
  let sortedList = targetList.sort((a, b) => {
    //aとbの並べ替えのフラグ
    let result = 0

    //優先順位に従って並び替え
    keyList.map(key => {
      let small = -1
      let large = 1

      //降順の場合はフラグを切り替え
      if (key.orderBy === 'dsc') {
        small = 1
        large = -1
      }

      //並び替え
      if (result === 0) {
        if (
          a[key.name] < b[key.name] ||
          (isEmpty(a[key.name]) && !isEmpty(b[key.name]))
        ) {
          result = small
        }
        if (
          a[key.name] > b[key.name] ||
          (!isEmpty(a[key.name]) && isEmpty(b[key.name]))
        ) {
          result = large
        }
      }
    })
    return result
  })
  return sortedList
}
/**
 * オブジェクト配列に対して、指定したキーと値をもつオブジェクトを、配列の先頭に移動する
 * 値の重複は許さない
 * @param list 対象のオブジェクト配列
 * @param keyName 評価するオブジェクトのキー名
 * @param value 評価するオブジェクトの値
 * @returns {Array}
 */
export const moveObjectToHead = (list, keyName, value) => {
  //結果配列
  let result = []
  //取り除かれた配列
  let retrived = []

  if (Array.isArray(list)) {
    list.map((object, key) => {
      if (object[keyName] === value) {
        retrived = list.splice(key, 1)
      }
    })
  }

  //取り除いたオブジェクトを配列の先頭に追加
  result = [...retrived, ...list]

  return result
}

/**
 * 年齢計算
 * @param birthday YYYY-MM-DD の形式
 */
export const calcAge = birthday => {
  //YYYY-MM-DD の形式から、年月日を取得
  const date = birthday.split('-')
  //年、月、日のいずれかがない場合は空文字を返す
  if (isEmpty(date[0]) || isEmpty(date[1]) || isEmpty(date[2])) {
    return
  }
  const y2 = paddingZero(date[0], 4)
  const m2 = paddingZero(date[1], 2)
  const d2 = paddingZero(date[2], 2)
  //今日の日付から、年月日を取得
  const today = new Date()
  const y1 = paddingZero(today.getFullYear(), 4)
  const m1 = paddingZero(today.getMonth() + 1, 2)
  const d1 = paddingZero(today.getDate(), 2)
  //年齢計算
  return Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000)
}

/**
 * 現在時刻からYYYYMMDD_HHMMSSを返す
 */
export const getFileName = () => {
  let date = new Date()
  let y = paddingZero(date.getFullYear(), 4)
  let m = paddingZero(date.getMonth() + 1, 2)
  let d = paddingZero(date.getDate(), 2)
  let h = paddingZero(date.getHours(), 2)
  let mt = paddingZero(date.getMinutes(), 2)
  let s = paddingZero(date.getSeconds(), 2)

  return '' + y + m + d + '_' + h + mt + s
}

/**
 * ゼロパディング
 * @param num
 * @param digit
 * @returns {string}
 */
export const paddingZero = (num, digit) =>
  ('000000000000' + num).slice(-1 * digit)

/**
 * 電話番号取得
 *  携帯番号 > 自宅番号 > 勤め先番号 の優先順位
 * @returns {string}
 * @private
 */
export const getCustomerTelNum = customer => {
  if (!isEmpty(customer.mobile_phone_num1)) {
    return (
      customer.mobile_phone_num1 +
      '-' +
      customer.mobile_phone_num2 +
      '-' +
      customer.mobile_phone_num3
    )
  } else if (!isEmpty(customer.phone_num1)) {
    return (
      customer.phone_num1 +
      '-' +
      customer.phone_num2 +
      '-' +
      customer.phone_num3
    )
  } else if (!isEmpty(customer.office_phone_num1)) {
    return (
      customer.office_phone_num1 +
      '-' +
      customer.office_phone_num2 +
      '-' +
      customer.office_phone_num3
    )
  } else {
    return ''
  }
}
/**
 * 電話番号区分取得
 *  携帯番号 > 自宅番号 > 勤め先番号 の優先順位
 * @returns {string}
 * @private
 */
export const getCustomerTelSection = customer => {
  if (!isEmpty(customer.mobile_phone_num1)) {
    return '携帯'
  } else if (!isEmpty(customer.phone_num1)) {
    return '自宅'
  } else if (!isEmpty(customer.office_phone_num1)) {
    return '勤め先'
  } else {
    return ''
  }
}
/**
 * TODO フラグ反転前につかっていたので今後不要の可能性あり
 * '0'ならtrue、'1'ならfalseを返す
 * @param string
 * @returns {boolean}
 */
export const getTrueFalse = string => {
  return string === '0'
}
/**
 * 和暦を西暦に変換する
 * @param n 年号のコード値
 *     大正: '1'
 *     昭和: '2'
 *     平成: '3'
 * @param y 和暦の年
 */
export const convertAD = (n, y) => {
  let result = ''

  y = Number(y) || 0

  if (n === '3' && y > 0) {
    result = y + 1988
  } else if (n === '2' && y > 0 && y <= 64) {
    result = y + 1925
  } else if (n === '1' && y > 0 && y <= 15) {
    result = y + 1911
  } else if (n === '0' && y > 0) {
    result = y
  } else {
    result = null
  }
  return result
}

/**
 * 画面仕様書(Excel)の1セルを一マスと考え、テーブルのtdのwidth(%)を得る
 * @param col tdのセル数
 * @param total テーブルのrowのセル数(デフォルト44)
 * @returns {string}
 */
export const getWidthPercent = (col, total = 44) => {
  return col / total * 100 + '%'
}
/**
 * 明細差分用
 * @param preDtlID ひとつ前の枝番の明細ID
 * @param name 比較する明細の名前
 * @param value 比較する明細の値
 * @param preProductList ひとつ前の履歴の明細一覧
 * @returns {boolean}
 */
export const diffDetail = (preDtlID, name, value, preProductList) => {
  let isSame = false
  Array.isArray(preProductList) &&
  preProductList.map(preProduct => {
    if (preProduct.id === preDtlID) {
      if (value === preProduct[name]) {
        isSame = true
      }
    }
  })

  return isSame
}
/**
 * 添付ファイルのファイル名を取得
 * @param path
 * @returns {*}
 */
export const getAttachmentFileName = path => {
  let result = path
  if (typeof path === 'string') {
    let str_arr = path.split('/')
    result = str_arr[str_arr.length - 1]
  }
  return result
}

/**
 * 伝票の区分から、商品検索の「検索対象」の初期値を取得
 * @param bill string 伝票名
 * @param item object 伝票データ(editItem)
 */
export const getSearchProductSection = (bill, item) => {
  let search_product_section = '0'
  if (bill === 'order') {
    //注文伝票
    search_product_section = '0'
  } else if (bill === 'acceptorder') {
    //受注伝票
    if (item.section === '3') {
      search_product_section = '1'
    }
  } else if (bill === 'placingorder') {
    //発注伝票
    if (item.section === '0') {
      search_product_section = '0'
    } else if (item.section === '1') {
      search_product_section = '1'
    } else if (item.section === '2') {
      search_product_section = '3'
    }
  } else if (bill === 'purchase') {
    //仕入伝票
    if (item.section === '1') {
      search_product_section = '1'
    } else if (item.section === '2') {
      search_product_section = '3'
    }
  } else if (bill === 'shipment') {
    //出庫伝票
    if (item.section === '1') {
      search_product_section = '1'
    }
  } else if (bill === 'inflow') {
    //入庫伝票
    if (item.section === '1') {
      search_product_section = '1'
    }
  } else if (bill === 'sell') {
    //販売詳細
    if (item.section === '5') {
      search_product_section = '1'
    }
  } else if (bill === 'transportreq') {
    //移動依頼
    if (item.section === '2' || item.section === '3') {
      search_product_section = '1'
    }
  } else if (bill === 'repairreq') {
    //修理依頼
    if (item.section === '0' || item.section === '1') {
      search_product_section = '1'
    } else {
      search_product_section = '1'
    }
  } else {
  }
  return search_product_section
}
/**
 * 伝票の区分から、商品選択の product_section(配列)パラメータを取得
 * @param bill string 伝票名
 * @param item object 伝票データ(editItem)
 */
export const getSelectProductSection = (bill, item) => {
  let product_section = []

  if (isEmpty(item)) {
    return product_section
  }

  if (bill === 'order') {
    //注文
    product_section = ['0', '1', '3']
  } else if (bill === 'acceptorder') {
    //受注
    if (item.section === '0') {
      //商品受注
      product_section = ['0', '3']
    } else if (item.section === '1') {
      //留置き
      product_section = ['0', '3']
    } else if (item.section === '2') {
      //予約
      product_section = ['0', '3']
    } else if (item.section === '3') {
      //部品受注
      product_section = ['1', '3']
    } else if (item.section === '4') {
      //その他
      product_section = ['0', '3']
    }
  } else if (bill === 'placingorder') {
    //発注
    if (item.section === '0') {
      //商品
      product_section = ['0']
    } else if (item.section === '1') {
      //部品
      product_section = ['1']
    } else if (item.section === '2') {
      //その他
      product_section = ['3']
    }
  } else if (bill === 'purchase') {
    //仕入
    if (item.section === '0') {
      //商品
      product_section = ['0']
    } else if (item.section === '1') {
      //部品
      product_section = ['1']
    } else if (item.section === '2') {
      //修理
      product_section = ['0', '1', '3']
    } else if (item.section === '3') {
      //その他
      product_section = ['3']
    } else if (item.section === '4') {
      //返品
      product_section = ['0', '3']
    } else if (item.section === '5') {
      //返品戻り
      product_section = ['0', '3']
    }
  } else if (bill === 'shipment') {
    //出庫
    if (item.section === '0') {
      //商品
      product_section = ['0', '3']
    } else if (item.section === '1') {
      //部品
      product_section = ['1', '3']
    } else if (item.section === '2') {
      //修理
      product_section = ['0', '1', '3']
    } else if (item.section === '3') {
      //その他
      product_section = ['0', '1', '3']
    } else if (item.section === '4') {
      //棚卸差異
      product_section = ['0', '1', '2', '3']
    } else if (item.section === '5') {
      //サンプル
      product_section = ['0', '3']
    }
  } else if (bill === 'inflow') {
    //入庫
    if (item.section === '0') {
      //商品
      product_section = ['0', '3']
    } else if (item.section === '1') {
      //部品
      product_section = ['1', '3']
    } else if (item.section === '2') {
      //修理
      product_section = ['0', '1', '3']
    } else if (item.section === '3') {
      //その他
      product_section = ['0', '1', '3']
    } else if (item.section === '4') {
      //棚卸差異
      product_section = ['0', '1', '2', '3']
    } else if (item.section === '5') {
      //サンプル
      product_section = ['0', '3']
    }
  } else if (bill === 'sell') {
    //販売
    if (item.section === '0') {
      //測定
      product_section = []
    } else if (item.section === '1') {
      //販売(レンズあり)
      product_section = ['0', '1', '3']
    } else if (item.section === '2') {
      //販売
      product_section = ['0', '3']
    } else if (item.section === '3') {
      //留置き
      product_section = ['0', '1', '3']
    } else if (item.section === '4') {
      //予約
      product_section = ['0', '1', '3']
    } else if (item.section === '5') {
      //修理
      product_section = ['0', '1', '2', '3']
    } else if (item.section === '6') {
      //返品
      product_section = ['0', '1', '3']
    } else if (item.section === '7') {
      //再作成
      product_section = ['0', '1', '3']
    }
  } else if (bill === 'transportreq') {
    //移動依頼
    if (item.section === '0') {
      //商品
      product_section = ['0', '3']
    } else if (item.section === '1') {
      //商品予約
      product_section = ['0', '3']
    } else if (item.section === '2') {
      //部品
      product_section = ['1', '3']
    } else if (item.section === '3') {
      //部品予約
      product_section = ['1', '3']
    }
  } else if (bill === 'repairreq') {
    //修理依頼
    if (item.handle_section === '0') {
      //預かり修理
      product_section = ['0', '1', '3']
    } else if (item.handle_section === '1') {
      //部品出荷
      product_section = ['0', '1', '3']
    }
  } else {
  }
  return product_section
}
/**
 * Base64とMIMEコンテンツタイプ(ZIP)からBlobオブジェクトを作成する。
 *
 * @param base64
 */
export const downloadZip = data => {
  var mime_ctype = 'application/zip'
  var blob = toBlob(data, mime_ctype)

  if (window.navigator.msSaveBlob) {
    // IEやEdgeの場合、Blob URL Schemeへと変換しなくともダウンロードできる
    window.navigator.msSaveOrOpenBlob(blob, 'xxxx.zip')
  } else {
    return blob
  }
}
/**
 * Base64とMIMEコンテンツタイプ(Plain tsv csv)からBlobオブジェクトを作成する。
 *
 * @param base64
 */
export const downloadPlain = data => {
  var mime_ctype = 'application/plain'
  var blob = toBlob(data, mime_ctype)

  if (window.navigator.msSaveBlob) {
    // IEやEdgeの場合、Blob URL Schemeへと変換しなくともダウンロードできる
    window.navigator.msSaveOrOpenBlob(blob, 'xxxx.csv')
  } else {
    return blob
  }
}
/**
 * Base64とMIMEコンテンツタイプ(Xls)からBlobオブジェクトを作成する。
 *
 * @param base64
 */
export const downloadXls = data => {
  var mime_ctype = 'application/vnd.ms-excel'
  var blob = toBlob(data, mime_ctype)

  if (window.navigator.msSaveBlob) {
    // IEやEdgeの場合、Blob URL Schemeへと変換しなくともダウンロードできる
    window.navigator.msSaveOrOpenBlob(blob, 'xxx.xlsx')
  } else {
    return blob
  }
}
/**
 * Base64とMIMEコンテンツタイプ(PDF)からBlobオブジェクトを作成する。
 *
 * @param base64
 */
export const downloadPDF = data => {
  var mime_ctype = 'application/pdf'
  var blob = toBlob(data, mime_ctype)

  if (window.navigator.msSaveBlob) {
    // IEやEdgeの場合、Blob URL Schemeへと変換しなくともダウンロードできる
    window.navigator.msSaveOrOpenBlob(blob, 'xxx.pdf')
  } else {
    return blob
  }
}
/**
 * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
 * 日本語対応。
 *
 * @param base64
 * @param mime_ctype MIMEコンテンツタイプ
 * @returns Blob
 */
export const toBlob = (base64, mime_ctype) => {
  // 日本語の文字化けに対処するためBOMを作成する。
  // var bom = new Uint8Array([0xef, 0xbb, 0xbf])
  var bom = new Uint8Array([])

  var bin = atob(base64.replace(/^.*,/, ''))
  var buffer = new Uint8Array(bin.length)
  for (var i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  // Blobを作成
  try {
    var blob = new Blob([bom, buffer.buffer], {
      type: mime_ctype,
    })
  } catch (e) {
    return false
  }
  return blob
}
/**
 * スキャンからの入力を整形(文頭2、文末1削除)
 * @param input
 * @returns {*}
 */
export const formatFromScan = input => {
  if (input.length === 13) {
    return input.slice(2).slice(0, -1)
  }
  return input
}

/**
 * 配列を特定のプロパティで分類し
 * 新しいオブジェクトとして返す
 *
 * @param arr
 * @param property_name
 * @returns {*}
 */
export const groupByArray = (arr, property_name) => {
  //プロパティの値が同じ要素で分類し、オブジェクトを生成
  return arr.reduce((temp, target) => {
    if (!temp[target[property_name]]) {
      temp[target[property_name]] = []
    }
    temp[target[property_name]].push(target)
    return temp
  }, {})
}
/**
 * オブジェクト内のnullを空文字に変換する(1階層のみ対応)
 * @param object
 * @returns {*}
 */
export const convertNullToBlank = object => {
  let target = {...object}

  for (let key in target) {
    if (target[key] === null) {
      target[key] = ''
    }
  }

  return target
}
/**
 * 通貨CDから通貨名を返す
 * @param cd
 * @returns {*}
 */
export const getCurrencyNameByCD = (cd) => {
  let result

  cd = cd + ''

  if (cd === '0') {
    result = 'JPY'
  } else if (cd === '1') {
    result = 'EUR'
  } else if (cd === '2') {
    result = 'SGD'
  }

  return result
}
/**
 * オブジェクトの配列に対して、key_name が target の要素の次の要素を返す
 * 次の要素がない場合には初めの要素を返す
 *
 * @param array
 * @param target
 * @param key_name
 * @returns {number}
 */
export const getNextIndex = (array, target, key_name) => {
  if (Array.isArray(array)) {
    let index = 0
    array.forEach((item, key) => {
      if (item[key_name] === target) {
        index = key
      }
    })
    return (index + 1) < array.length ? array[(index + 1)] : array[0]
  }
}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
export const getParam = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
/**
 * ISO時刻をフォーマットする
 * @param date
 * @param separateConvert
 * @returns {string}
 */
export const formatISODate = (date = '', separateConvert = true) => {
  if (isEmpty(date)) return ''

  let result = date.replace(/T/g, ' ')
  result = result.substr(0, 19)

  if (separateConvert) result = result.replace(/-/g, '/')

  return result
}
/**
 * CSVのヘッダーの取得
 *
 * data のプロパティ名を、keyとvalueにもつオブジェクトを返す
 *
 * @param data
 * @returns {{}}
 */
export const createCSVHeaders = data => {
  let result = {}

  for(let key in data){
    result[key] = key
  }

  return result
}
/**
 * 文字列を省略
 * strが cut_numより大きければ...を付けて省略した文字列を返す
 * @param str
 * @param cut_num
 * @returns {string}
 */
export const cutString = (str, cut_num) => {
  return str.length > cut_num ? str.slice(0, cut_num-1) + '...' : str
}
