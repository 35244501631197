import {IS_PRO_ENV} from './api-config'

const DEV_POST_CLIENT_TOKEN = 'd9000813c84d4cffb0bd7ad9f94aff35'
const PRO_POST_CLIENT_TOKEN = 'a2302f9608354922b49a24ac8a0739d3'

export const POST_CLIENT_TOKEN = (() => {
  if (IS_PRO_ENV) {
    return PRO_POST_CLIENT_TOKEN
  }

  return DEV_POST_CLIENT_TOKEN
})()
  
