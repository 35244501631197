import { createActions, handleActions } from 'redux-actions'
import moment from 'moment'
import { NODERED_URL, SUMAKAGI_URL, UNLINKED_CARDS_API } from '../constants/api-config'
import {
  POST_CHECK_OUT,
  GET_MESSAGES_URL,
  GET_BALANCE_URL,
  GET_RESERVATION_DETAIL_URL,
} from '../constants/api-url'
import { FACILITY_ID } from '../constants/settings'
import {actions as errorAction} from './error'
import {actions as loadingAction} from './loading'
import {api} from './middleware'
import { select, put, call, all, takeEvery, race, cancel } from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {delay} from '@redux-saga/core/effects'
import {printCSinfomation} from './receipt'
import {actions as receiptActions} from './receipt'
import rollbar from '../modules/rollbar'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: () => {},
    checkOut: payload => payload,
    setCheckoutResult: payload => payload,
    setIsNextCard: payload => payload,
    setFirstCard: payload => payload,

    manuallyKeyTouch: payload => payload,
    manuallyKeyDelete: payload => payload,
  },
  { prefix: 'hoshino/checkOut'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  checkoutResult: {},
  isNextCard: false,
  firstCard: null,
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.checkOut]: (state, action) => ({
    ...initialState,
    ...state,
  }),
  [actions.setCheckoutResult]: (state, action) => ({
    ...state,
    checkoutResult: action.payload,
  }),
  [actions.setIsNextCard]: (state, action) => ({
    ...state,
    isNextCard: action.payload,
  }),
  [actions.setFirstCard]: (state, action) => ({
    ...state,
    firstCard: action.payload,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* checkOutSaga() {
  yield takeEvery(actions.checkOut, checkOut)
  yield takeEvery(actions.manuallyKeyTouch, manuallyKeyTouch)
  yield takeEvery(actions.manuallyKeyDelete, manuallyKeyDelete)
}

function* checkOut(action) {
  let { card_id, finish } = action.payload

  try {
    const {
      checkOut: {
        isNextCard,
        firstCard,
      },
      setting: {
        sumakagiTenants,
      }
    } = yield select()

    //ローディング
    yield put(loadingAction.openLoading())
    yield put(loadingAction.onContinue())

    // スマ鍵から鍵名を取得
    let card = null
    if (card_id !== '') {
      card = yield call(findCardInfo, card_id)

      if (!card) {
      // スマ鍵解除済み判定
        yield put(loadingAction.offContinue())
        
        const unlockedCard = yield call(getUnlockedCards, card_id)
        if (unlockedCard) {
          yield put(errorAction.displayError('チェックアウト済みです'))
        } else {
          yield put(errorAction.displayError('ご登録のないカードキーです\n恐れ入りますが、お手持ちのカードキーをご確認ください'))
        }

        yield put(actions.init())
        yield put(errorAction.displayBtnFlg(false))
        yield delay(3000)
        yield put(errorAction.close())
        yield put(errorAction.displayBtnFlg(false))
        return
      }
    } else if (firstCard) {
      card = firstCard
    }

    // 予約番号、部屋番号
    if (!card.card_name.match(/^[0-9]+_[0-9]+$/)) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('チェックアウト済みです'))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }
    const reserveNo = card.card_name.replace(/^([0-9]+)_([0-9]+)$/, '$1')
    const roomNo = card.card_name.replace(/^([0-9]+)_([0-9]+)$/, '$2')


    // 共有部テナント
    const sharedTenant = sumakagiTenants.find(item => item.common_flag == 1)
    if (!sharedTenant) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('テナントの設定が行われていない'))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // カードに紐づくテナント取得
    const tenantResult = yield call(getTenantsByCardId, card.card_id)
    if (!tenantResult) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('チェックアウト済みです'))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }
    // 専有部のテナント
    const roomTenant = tenantResult.tenant.find(item => item.tenant_id !== sharedTenant.tenant_id)
    if (!roomTenant) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('チェックアウト済みです'))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // 専有部のカードキー取得
    const cardResult = yield call(getCardsByTenantId, roomTenant.tenant_id)
    const roomCards = cardResult.card.filter(item => item.card_name == card.card_name)

    // カードが2枚の時、2枚目の処理を行う
    if (isNextCard === false) {

      if (roomCards.length >= 2) {
        yield put(loadingAction.offContinue())
        yield put(actions.setIsNextCard(true))
        yield put(actions.setFirstCard(card))
        return
      }
    } else {
      if (card.card_name !== firstCard.card_name){
        yield put(loadingAction.offContinue())
        yield put(errorAction.displayError('異なるお部屋のカードキーです'))
        yield put(errorAction.displayBtnFlg(false))
        yield delay(3000)
        yield put(errorAction.close())
        yield put(errorAction.displayBtnFlg(false))
        return
      } else if (card_id === firstCard.card_id) {
        yield put(loadingAction.offContinue())
        return
      }
    }
    yield put(actions.setIsNextCard(false))
    yield put(actions.setFirstCard(null))

    // 精算取得
    const balanceResult = yield call(getBalance, roomNo)
    if (!balanceResult) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('通信エラー'))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }
    if (balanceResult.ResultHeader.ResultNo !== '00000' || balanceResult.GetBalance_ResultList.Balance != 0) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
      if (balanceResult.ResultHeader.ResultNo === '10001') {
        yield put(receiptActions.printAlert([
          {label: 'ご予約番号', value: reserveNo},
          {label: '部屋番号', value: roomNo},
          {label: 'メッセージ', value: 'チェックアウト アウト日違い'},
        ]))
      } else if (balanceResult.GetBalance_ResultList.Balance != 0) {
        yield put(receiptActions.printAlert([
          {label: 'ご予約番号', value: reserveNo},
          {label: '部屋番号', value: roomNo},
          {label: 'メッセージ', value: 'チェックアウト Balance≠0'},
        ]))
      } else {
        yield put(receiptActions.printAlert([
          {label: 'ご予約番号', value: reserveNo},
          {label: '部屋番号', value: roomNo},
          {label: 'メッセージ', value: 'チェックアウト 精算エラー：' +  balanceResult.ResultHeader.ResultNo},
        ]))
      }
      yield put(errorAction.displayBtnFlg(false))
      yield delay(10000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // チェックアウト
    let resultCheckOut = yield call(postCheckOut, reserveNo, roomNo)

    // チェックアウト失敗
    if (!resultCheckOut) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('通信エラー'))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // チェックアウト済み
    if (resultCheckOut.ResultHeader.ResultNo === '10000') {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(actions.setCheckoutResult(resultCheckOut.ResultHeader.ResultNo))
      yield put(push('/checkout/return-key'))
      return
    }

    // チェックアウト日違い
    if (resultCheckOut.ResultHeader.ResultNo === '10001') {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
      yield put(receiptActions.printAlert([
        {label: 'ご予約番号', value: reserveNo},
        {label: '部屋番号', value: roomNo},
        {label: 'メッセージ', value: 'チェックアウト アウト日違い'},
      ]))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(10000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // 未伝達メッセージあり
    if (resultCheckOut.ResultHeader.ResultNo === '10004') {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
      yield put(receiptActions.printAlert([
        {label: 'ご予約番号', value: reserveNo},
        {label: '部屋番号', value: roomNo},
        {label: 'メッセージ', value: 'チェックアウト MSGエラー'},
      ]))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(10000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // チェックアウト失敗
    if (resultCheckOut.ResultHeader.ResultNo !== '00000') {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield put(errorAction.displayError('申し訳ございません、チェックアウトができませんでした\n発行されたレシートをスタッフへお渡しください'))
      yield put(receiptActions.printAlert([
        {label: 'ご予約番号', value: reserveNo},
        {label: '部屋番号', value: roomNo},
        {label: 'メッセージ', value: 'チェックアウト 処理エラー：' + resultCheckOut.ResultHeader.ResultNo},
      ]))
      yield put(errorAction.displayBtnFlg(false))
      yield delay(10000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
      return
    }

    // CS案内印字
    if (!(yield call(printCSinfomation, reserveNo, roomNo))) {
      yield put(actions.init())
      yield put(loadingAction.offContinue())
      yield delay(3000)
      yield put(errorAction.close())
      yield put(errorAction.displayBtnFlg(false))
    }

    yield put(loadingAction.offContinue())
    yield put(push('/checkout/return-key'))

    // スマ鍵解除登録
    yield all(roomCards.map(item => call(storeUnlockedCards, item.card_id, roomNo)))
  
    // カードキー紐付け解除リクエスト
    yield all(roomCards.map(item => call(unlinkCardProcess, item.card_id, roomTenant.tenant_id)))
  } catch (e) {
    console.error(e)
    rollbar.critical('チェックアウト エラー制御不備', e)
  } finally {
    if (finish && typeof finish === 'function') {
      finish()
    }
  }
}

function* unlinkCardProcess(cardId, tenantId) {
  try {
    const {
      setting: {
        sumakagiTenants,
      }
    } = yield select()
    const sharedTenant = sumakagiTenants.find(item => item.common_flag == 1)

    // カードキー紐付け解除リクエスト
    let unlinkRequests = yield all([
      call(requestUnlinkJobs, cardId, tenantId),
      call(requestUnlinkJobs, cardId, sharedTenant.tenant_id),
    ])
    unlinkRequests = unlinkRequests.filter(request => request)

    const startDate = new Date()

    // すべての部屋が紐付け解除されるのを待つ
    while (unlinkRequests.length) {
      yield delay(1000 * 10)

      let unlinkResults = yield all(unlinkRequests.map(request => call(getUnlinkResult, request.request_id)))
      const failedResult = unlinkResults.find(result => result && result.status == 'failed')
      if (failedResult) {
        return false
      }

      let successRequestIds = unlinkResults.filter(result => result && result.status == 'successful')

      unlinkRequests = unlinkRequests.filter(request => !successRequestIds.find(result => result.request_id == request.request_id))

      let processDate = new Date()
      if (processDate - startDate > (1000 * 60 * 30)) {
        break
      }
    }

    // カードキーを削除
    const result = yield call(deleteCard, cardId)

    return result !== null
  } catch (e) {
    console.error(e)
    rollbar.critical('チェックアウト unlinkCardProcess エラー制御不備', e)
  }
}

// reserveAction.searchReserveDetailsと統一させたい
function* getRegistrationInfo(roomNo) {
  const URL = `${NODERED_URL}${GET_RESERVATION_DETAIL_URL}?RoomAccountID=&RoomNo=${roomNo}`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  const { response, timeout } = yield race({
    response: call(api, {payload}),
    timeout: delay(3000),
  })
  console.log(response)
  console.log(timeout)
  if (timeout) {
    console.log('timeout')
    return ''
  }

  return response
}

function* getMessages(roomNo) {
  const info = yield call(getRegistrationInfo, roomNo)
  if (!info) {
    return null
  }

  const URL = `${NODERED_URL}${GET_MESSAGES_URL}?RoomAccountID=${info.GetRegistrationInfo_ResultList.RoomAccountID}&RoomNo=${roomNo}&RsvStyPtn=STY`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }

  return yield call(api, {payload})
}

function* postCheckOut(reserveNo, roomNo) {
  const URL = `${NODERED_URL}${POST_CHECK_OUT}`
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      RoomNo: roomNo
    },
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

/**
 * TODO: payment.jsのgetBalanceと共有させたい
 */
function* getBalance(roomNo) {
  const URL = `${NODERED_URL}${GET_BALANCE_URL}?RoomNo=${roomNo}&RsvStyPtn=STY`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* findCardInfo(cardId) {
  let cursor = 1
  let checkURL = ''
  while (true) {
    let URL = `${SUMAKAGI_URL}/cards?cursor=${cursor}&limit=100`
    // 無限ループ対策
    if (URL === checkURL) {
      break
    }
    checkURL = URL

    let payload = {
      url: URL,
      method: 'GET',
      openLoadingFlag: 4
    }
    let cards = yield call(api, {payload})

    let cardInfo = cards.data.find(item => item.card_id === cardId)
    if (cardInfo) {
      return cardInfo
    }
  
    cursor = cards.metadata.next_cursor
    if (cursor == 0) {
      break
    }
  }

  return null
}

function* getTenantsByCardId(cardId) {
  const URL = `${SUMAKAGI_URL}/cards/${cardId}/tenants`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* getCardsByTenantId(tenantId) {
  const URL = `${SUMAKAGI_URL}/tenants/${tenantId}/cards?limit=100`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* requestUnlinkJobs(cardId, tenantId) {
  if (!tenantId) {
    return null
  }

  // カードキー紐付け解除
  const URL = `${SUMAKAGI_URL}/jobs/cards/${cardId}/tenants/${tenantId}`
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      command: {
        type: 'card',
        value: 'unlink',
      }
    },
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* getUnlinkResult(requestId) {
  const URL = `${SUMAKAGI_URL}/jobs?request_id=${requestId}`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* deleteCard(cardId) {
  const URL = `${SUMAKAGI_URL}/cards/${cardId}`
  const payload = {
    url: URL,
    method: 'DELETE',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* getUnlockedCards(keyno_id) {
  const URL = `${UNLINKED_CARDS_API}?keyno_id=${keyno_id}`
  const payload = {
    url: URL,
    method: 'GET',
    doNotDisplayError: true,
    openLoadingFlag: 4
  }

  let cards = yield call(api, {payload})
  if (!cards) {
    return cards
  }

  let todayString = moment().format('YYYY/MM/DD')
  return cards.filter(card => (card.facility_id == FACILITY_ID && card.created_at.indexOf(todayString) === 0)).slice(-1)[0]
}

function* storeUnlockedCards(keyno_id, room_no) {
  const facility_id = FACILITY_ID

  const URL = `${UNLINKED_CARDS_API}`
  const payload = {
    url: URL,
    method: 'POST',
    request: {
      keyno_id,
      facility_id,
      room_no,
    },
    doNotDisplayError: true,
    openLoadingFlag: 4
  }
  return yield call(api, {payload})
}

function* manuallyKeyTouch(action) {
  const { card_id, finish } = action.payload
  let reserveNo, roomNo, tenants

  try {
    //ローディング
    yield put(loadingAction.openLoading())
    yield put(loadingAction.onContinue())

    // スマ鍵から鍵名を取得
    let card = null
    if (card_id !== '') {
      card = yield call(findCardInfo, card_id)
      if (!card) {
        yield put(errorAction.displayError('登録のないカードキーです'))
        return
      }
    }

    // 予約番号、部屋番号
    if (!card.card_name.match(/^[0-9]+_[0-9]+$/)) {
      yield put(errorAction.displayError(`処理できないカード名です\n${card.card_name}`))
      return
    }
    reserveNo = card.card_name.replace(/^([0-9]+)_([0-9]+)$/, '$1')
    roomNo = card.card_name.replace(/^([0-9]+)_([0-9]+)$/, '$2')

    // カードに紐づくテナント取得
    const tenantResult = yield call(getTenantsByCardId, card.card_id)
    tenants = tenantResult ? tenantResult.tenant : []
    
  } catch (e) {
    console.error(e)
  } finally {
    yield put(loadingAction.offContinue())
    if (finish && typeof finish === 'function') {
      finish(reserveNo, roomNo, tenants)
    }
  }
}

function* manuallyKeyDelete(action) {
  const { finish } = action.payload
  const {
    setting: {
      sumakagiTenants,
    },
    form: {
      DeleteKeyTouch: {
        values: {
          uid: card_id,
          tenants
        }
      }
    }
  } = yield select()

  try {
    //ローディング
    yield put(loadingAction.openLoading())
    yield put(loadingAction.onContinue())

    // 共有部テナント
    const sharedTenant = sumakagiTenants.find(item => item.common_flag == 1)
    if (!sharedTenant) {
      yield put(errorAction.displayError('テナントの設定が行われていない'))
      return
    }

    // 専有部のテナント
    const roomTenant = tenants.find(item => item.tenant_id !== sharedTenant.tenant_id)
    
    // カードキー紐付け解除リクエスト
    const result = yield call(unlinkCardProcess, card_id, roomTenant ? roomTenant.tenant_id : null)
    if (result) {
      yield put(errorAction.displayError('鍵を削除しました'))
    } else {
      yield put(errorAction.displayError('鍵の削除に失敗しました'))
    }
  } catch (e) {
    console.error(e)
  } finally {
    yield put(loadingAction.offContinue())
    if (finish && typeof finish === 'function') {
      finish()
    }
  }
}
