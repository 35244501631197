import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Card
} from 'reactstrap'

import {ACC_COLOR, SUB_COLOR, containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next';
import { destroy as formDestroy } from 'redux-form'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {actions as checkOutAction} from '../../modules/checkOut'
import {actions as errorAction} from '../../modules/error'
import rollbar from '../../modules/rollbar'


class Checkin extends Component {
  state = { 
    count: 0, 
    interval: 0,
  };

  componentDidMount() {
    console.log('mount')
    this.props.start()
    this.video1.play()
  }

  componentWillUnmount() {
    console.log('unmount')
    this.props.end()
  }

  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.history.push('/')
    }
  }

  render() {
    const { t, i18n, isNextCard } = this.props

    // アプリで開かれているか判定
    const isApri = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.icCard

    
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <Row style={{position:'absolute', top:'3vh', left:'5vh'}}>
            <Logo className={[i18n.language]} onClick={this.clickDummyBtn}><img src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`} alt="" style={{height:'4vw'}}/></Logo>
          </Row>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('ICリーダーにカードキーをかざしてください')}/>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <SelectBtnArea style={{height:'2vh'}}>
                  {!isApri && (
                    <Card
                      style={{
                        position: 'absolute',
                        right: '0',
                        bottom: '0',
                        padding: '20px',
                        zIndex: '9999',
                      }}
                    >
                      <p><Button onClick={() => window.callbackGetId('9d5cfbe0')}>9d5cfbe0</Button></p>
                      <p><Button onClick={() => window.callbackGetId('5eba794a')}>5eba794a</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345671')}>12345671</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345672')}>12345672</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345673')}>12345673</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345674')}>12345674</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345675')}>12345675</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345676')}>12345676</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345677')}>12345677</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345678')}>12345678</Button></p>
                      <p><Button onClick={() => window.callbackGetId('12345679')}>12345679</Button></p>
                    </Card>
                  )}
                </SelectBtnArea>
                <AnimationArea>
                  <video loop muted playsInline width='500' id="video1" ref={ref => this.video1 = ref}>
                    <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkout_1.mp4" type="video/mp4" />
                  </video>
                </AnimationArea>
                <LanguageArea className={[i18n.language]}>
                  <LanguageBtn style={i18n.language === 'ja' ? selected : {}} onClick={()=>{ i18n.changeLanguage('ja') }}>日本語</LanguageBtn>
                  <LanguageBtn style={i18n.language === 'en' ? selected : {}} onClick={()=>{ i18n.changeLanguage('en') }}>ENGLISH</LanguageBtn>
                </LanguageArea>
              </div>
            </ConfirmArea>
          </Container>
        </CustomContainer>
        <Modal
          backdrop="static"
          dialogClassName="error-modal"
          isOpen={isNextCard}
          style={{minHeight: '50vh', minWidth: '80vw', paddingTop: '15vh'}}
          onOpened={() => { this.video2.play() }}
        >
          
          <ModalBody style={{minHeight: '50vh', minWidth: '80vw'}}>
            <div
              className={'text-center'}
              style={{
                fontSize: '2rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                paddingTop: '2vh'
              }}
            >
              <AnimationArea style={{display: 'block'}}>
                <div style={{marginTop: '3vh'}}>
                  {t('もう一枚かざしてください')}
                </div>
                <video muted loop playsInline width='350' style={{marginTop:'3vh'}} ref={ref => this.video2 = ref}>
                  <source src="https://dfc78s572732n.cloudfront.net/animation/20200312/checkout_1.mp4" type="video/mp4" />
                </video>
              </AnimationArea>
            </div>
          </ModalBody>
          <ModalFooter style={{margin: '2rem auto', textAlign: 'center', borderTop: '0 solid'}}>
            <Button style={{boxShadow:'0px 4px ' + '#707070', width:'12vw', height:'4.5vw', fontSize:'1.5rem'}} onClick={() => window.callbackGetId('')}>{t('スキップ')}</Button>
          </ModalFooter>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isNextCard: state.checkOut.isNextCard
  }
}

const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.icCard) {
        window.webkit.messageHandlers.icCard.postMessage('removeCard')
      } else {
        console.log('アプリ連携なし icCard')
      }
      
      // カード読み取りのコールバック
      window.callbackGetId = (uid) => {
        rollbar.debug('チェックアウト カード読み取り', {card_id: uid})
        dispatch(checkOutAction.checkOut({card_id: uid, finish: () => {
          if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.icCard) {
            window.webkit.messageHandlers.icCard.postMessage('removeCard')
          } else {
            console.log('アプリ連携なし icCard')
          }
        }}))
      }
      window.callbackRemoveCard = () => {
        window.webkit.messageHandlers.icCard.postMessage('getId')
      }
      window.callbackTimeout = () => {
        window.webkit.messageHandlers.icCard.postMessage('getId')
      }
      window.callbackError = (msg) => {
        console.log(msg)
        rollbar.debug('チェックアウト カードエラー')
        dispatch(errorAction.displayBtnFlg(true))
        dispatch(errorAction.displayError(msg))
        window.webkit.messageHandlers.icCard.postMessage('removeCard')
      }
    },
    end: () => {
      // カード読み取りのコールバックを消しておく
      window.callbackGetId = (uid) => {
        console.log(uid)
        rollbar.debug('チェックアウト 閉じ済みcallbackGetId')
      }
      window.callbackRemoveCard = () => {
        rollbar.debug('チェックアウト 閉じ済みcallbackRemoveCard')
      }
      window.callbackTimeout = () => {
        rollbar.debug('チェックアウト 閉じ済みcallbackTimeout')
      }
      window.callbackError = (msg) => {
        console.log(msg)
        rollbar.debug('チェックアウト 閉じ済みcallbackError')
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(Checkin)
)

/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
`
const Logo = styled.div`
  margin-right: auto;
`
const MainArea = styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  list-style:none;
  max-width:75vw;
`
const SelectBtnArea = styled(MainArea)`
  padding: 0px;
  margin: 0 auto;
`
const LanguageArea = styled(MainArea)`
  padding: 0;
  margin: 1.3vh auto 0 auto;
  justify-content: space-evenly;
`
const LanguageBtn = styled.li`
  background-color:#DDDDDD;
  color:#fff;
  text-align:center;
  height:5vh;
  line-height:5vh;
  width:10vw;
`
const selected ={
  backgroundColor:'#31A237',
  color:'#fff',
  fontWeight:'bold'}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
  opacity:0;
`
const AnimationArea = styled.div`
  text-align:center;
`