import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {BASE_COLOR} from '../../constants/common'

/**
 * フッター
 */
class Footer extends Component {
  render() {
    const {children, ...rest} = this.props
    return (
      <FooterArea {...rest}>
        {children}
      </FooterArea>
    );
  }
}

Footer.propTypes = {
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

const FooterArea = styled.div`
  height: ${props => props.height ? props.height : '10vh'};
  background-color: ${BASE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`