import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, Row, Col, Card, CardBody} from 'reactstrap'
import styled from 'styled-components'
import {actions as errorAction} from '../../../modules/error'
import {isEmpty} from '../../../util/common'
import {FaArrowAltCircleRight} from 'react-icons/fa'
import {push} from 'connected-react-router'
import { ok } from 'assert'
import { withTranslation } from 'react-i18next'
import { ACC_COLOR, SUB_COLOR, containerStyle } from '../../../constants/common'

/**
 * クレジット支払い種別選択
 */
class PayMethodSelect extends Component {
  
  render() {
    const { t, i18n } = this.props

    return (
      <section id="payment_select">
        <Modal
          isOpen={true}
          toggle={() => {
            this.props.onSelect('')
          }}
          size="lg"
          className="modal-dialog-centered"
        >
          <ModalBody>
            <div className={[i18n.language]} style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', margin: '20px'}}>
              {t('ご利用のクレジットカードを選択してください')}
            </div>

            <Row>
              <Col xs={6}>
                <Card onClick={e => { this.props.onSelect('credit') }} style={{border: '1px solid #484747'}}>
                  <CardBody style={{height: '25vh'}}>
                    <CardLogoArea>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/visa.png" alt="" /></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/master_logo.png" alt=""/></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/jcb_logo.png" alt=""/></CardLogo>
                    </CardLogoArea>
                    <CardLogoArea style={{marginTop:'1vh'}}>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/amex_logo.png" alt="" /></CardLogo>
                      <CardLogo><LogoImg src="https://dfc78s572732n.cloudfront.net/card_logo/diners_logo.png" alt=""/></CardLogo>
                    </CardLogoArea>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6}>
                <Card onClick={e => { this.props.onSelect('ginren') }} style={{border: '1px solid #484747'}}>
                  <CardBody style={{height: '25vh'}}>
                    <CardLogoArea style={{height: '100%'}}>
                      <img src="https://dfc78s572732n.cloudfront.net/card_logo/ginren-logo.gif" alt="" />
                    </CardLogoArea>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={4} style={{margin: '4vh auto 2vh'}}>
                <TopBtn onClick={() => {
                  this.props.onSelect('')
                }}>
                  {t('閉じる')}
                </TopBtn>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </section>
    )
  }
}

PayMethodSelect.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayMethodSelect))

const CardLogoArea = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  padding:10px;
`
const CardLogo = styled.div`
  display:flex;
  padding:5px;
`
const LogoImg = styled.img`
  height:calc(100vw/20);
  flex-basis: auto;
`
const TopBtn = styled.li`
  background-color: ${SUB_COLOR};
  border-radius: 0.4vw;
  box-shadow: 0px 4px ${ACC_COLOR};
  color: #fff;
  font-size: 2em;
  height: 8vh;
  line-height: 8vh;
  text-align: center;
  list-style: none;
`