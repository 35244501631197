
/**
 * 認証チェック
 * @param store
 * @returns {function(*): function(*=)}
 */
// export const auth = store => next => action => {
//
//   next(action)
//
//   //遷移先が '/' もしくは '/login' ではない場合、認証チェック
//   if (window.location.pathname !== '/'
//       && window.location.pathname !== '/login'
//   ) {
//
//     const {auth} = store.getState()
//     const {auth: a} = auth || {}
//     const {access_token} = a || {}
//
//     if (!access_token) {
//
//       //認証済みではない場合、ログイン画面へ
//       store.dispatch(push('/'))
//     }
//   }
// }

/**
 * 画面遷移
 * @param store
 * @returns {function(*): function(*=)}
 */
export const locationChange = store => next => action => {

  if (action.type !== '@@router/LOCATION_CHANGE') {
    next(action)
    return
  }

  window.scrollTo(0, 0)

  next(action)
}