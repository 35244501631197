import React, {Component} from 'react'
import {store} from './index'
import 'react-simple-keyboard/build/css/index.css';
import './App.css'
import {Route, Switch} from 'react-router'
import {IntlProvider} from 'react-intl'
import Loading from './components/molecules/Loading'
import Error from './components/molecules/Error'
import Notice from './components/molecules/Notice'
import Confirm from './components/molecules/Confirm'
import NotFound from './views/notFound/NotFound'
import Checkin from './views/checkin/Checkin'
import CheckinSearch from './views/checkin/ReserveSearch'
import CheckinQR from './views/checkin/QR'
import ReadPassport from './views/checkin/ReadPassport'
import ConfirmPassport from './views/checkin/ConfirmPassport'
import SkipPassport from './views/checkin/SkipPassport.js'
import Reconfirm from './views/checkin/Confirm'
import Payment from './views/checkin/Payment'
import Instructions from './views/checkin/Instructions'
import Completed from './views/checkin/Completed'
import CustomerEdit from './views/checkin/CustomerEdit'
import ReserveSearch from './views/reserve/ReserveSearch'
import ConfirmPassportBack from './views/reserve/ConfirmPassport'
import ReserveRoomList from './views/reserve/ReserveRoomList'
import KeyRegister from './views/reserve/KeyRegister'
import ReserveComplete from './views/reserve/ReserveComplete'
import CustomerEditOption from './views/checkin/CustomerEditOption'
import PaymentSelect from './views/payment/PaymentSelect'
import SearchResult from './views/checkin/SearchResult'
import Checkout from './views/Checkout/Checkout'
import KeyTouch from './views/Checkout/KeyTouch'
import ReturnKey from './views/Checkout/ReturnKey'
import CheckoutCompleted from './views/Checkout/ChechoutCompleted'
import SandboxPrinter from './views/sandbox/Printer'
import SandboxQr from './views/sandbox/Qr'
import ConfigConfigPage from './views/config/ConfigPage'
import 'sanitize.css';
import Top from './views/top/Top'
import SelectPayment from './views/checkin/SelectPayment'
import RoomKeyIssue from './views/checkin/RoomKeyIssue'
import EnterInformation from './views/keysManually/EnterInformation'
import DeleteKeyTouch from './views/keysManually/DeleteKeyTouch'
import SettingSelect from './views/config/SettingSelect';
import AdminCheckPassword from './views/config/AdminCheckPassword';
import Vega3000Config from './views/config/Vega3000Config';


class App extends Component {
  render() {
    const {setting: {locale}} = store.getState()
    return (
      <IntlProvider locale={locale}>
        <div className='App' style={{backgroundColor:'#fff'}}>
          <Switch>
            <Route exact path='/' component={Top} />
            <Route exact path='/checkin' component={Checkin} />
            <Route exact path='/checkin/search' component={CheckinSearch} />
            <Route exact path='/checkin/qr' component={CheckinQR} />
            <Route exact path='/checkin/read_passport' component={ReadPassport} />
            <Route exact path='/checkin/confirm_passport' component={ConfirmPassport} />
            <Route exact path='/checkin/skip_passport' component={SkipPassport} />
            <Route exact path='/checkin/search_result' component={SearchResult} />
            <Route exact path='/checkin/reconfirm' component={Reconfirm} />
            <Route exact path='/checkin/edit' component={CustomerEdit} />
            <Route exact path='/checkin/edit/option' component={CustomerEditOption} />
            <Route exact path='/checkin/payment' component={Payment} />
            <Route exact path='/checkin/Instructions' component={Instructions} />
            <Route exact path='/checkin/completed' component={Completed} />
            <Route exact path='/reserve/search' component={ReserveSearch} />
            <Route exact path='/reserve/confirm_passport' component={ConfirmPassportBack} />
            <Route exact path='/reserve/room_list' component={ReserveRoomList} />
            <Route exact path='/reserve/key_register' component={KeyRegister} />
            <Route exact path='/reserve/complete' component={ReserveComplete} />
            <Route exact path='/payment/select' component={PaymentSelect} />
            <Route exact path='/checkout' component={Checkout} />
            <Route exact path='/checkout/key-touch' component={KeyTouch} />
            <Route exact path='/checkout/return-key' component={ReturnKey} />
            <Route exact path='/checkout/completed' component={CheckoutCompleted} />
            <Route exact path='/sandbox/printer' component={SandboxPrinter} />
            <Route exact path='/sandbox/qr' component={SandboxQr} />
            <Route exact path='/config/configpage' component={ConfigConfigPage} />
            <Route exact path='/top' component={Top} />
            <Route exact path='/checkin/select_payment' component={SelectPayment} />
            <Route exact path='/checkin/room_key_issue' component={RoomKeyIssue} />
            <Route exact path='/key_manually/enter_information' component={EnterInformation} />
            <Route exact path='/key_manually/delete_key_touch' component={DeleteKeyTouch} />
            <Route exact path='/config/setting_select' component={SettingSelect} />
            <Route exact path='/config/admin_check_password' component={AdminCheckPassword} />
            <Route exact path='/config/vega3000_config' component={Vega3000Config} />
            <Route exact component={NotFound}/>
          </Switch>
          <Loading />
          <Error />
          <Notice />
          <Confirm />
        </div>
      </IntlProvider>
    )
  }
}

export default App