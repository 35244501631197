import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button} from 'reactstrap'
import styled from 'styled-components'
import {IoMdClose, IoIosCheckmark} from 'react-icons/io'
import {BASE_COLOR, DARYGREY_COLOR, GREY_COLOR} from '../../constants/common'
import {actions as numericpadAction} from '../../modules/numericpad'
import {isEmpty} from '../../util/common'

const Display = styled.div`
  background: ${BASE_COLOR};
  color: #FFF;
  font-size: 2rem;
  text-align: center;
  height: 4rem;
  line-height: 4rem;
`
const Wrapper = styled.section`
  // position: fixed;
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
  width: 255px;
`
const StyledBtn = styled(Button)`
  height:85px;
  width:85px;
  background:${BASE_COLOR} !important;
  color:#FFF !important;
  font-size: 26px !important;
  border-radius: 0px !important;
  &:active{
    background: ${GREY_COLOR} !important;
  }
  &:disabled{
    opacity: 1 !important;
    background:${DARYGREY_COLOR} !important;
    color:#aaa !important;
  }
`
const ClearBtn = styled(StyledBtn)`
  background:${BASE_COLOR} !important;
`
const SubmitBtn = styled(StyledBtn)`
  background:${BASE_COLOR} !important;
  font-size: 26px !important;
`
/**
 * 数値入力用コンポーネント
 */
class NumericPad extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  render() {
    const {
      numericpad,
      submitFunc = () => {},
      maxLength = 6,
      initialValue,
    } = this.props
    const {
      is_open,
      position:{m_left, m_right}
    } = numericpad
    let wrapper_style = {
      position: 'relative',
      margin: 'auto',
      marginTop: '30vh',
    }
    if (!isEmpty(m_left)) {
      wrapper_style = {
        ...wrapper_style,
        marginLeft: m_left,
      }
    }
    if (!isEmpty(m_right)) {
      wrapper_style = {
        ...wrapper_style,
        marginRight: m_right,
      }
    }
    const NumBtn = num => {
      return (
        <StyledBtn disabled={isButtonDisabled} className={'text center'} onClick={() => { this._setValue(num) }}>
          {num}
        </StyledBtn>
      )
    }
    const isButtonDisabled = initialValue
      ? initialValue >= maxLength
      : this.state.value.length >= maxLength

    return (
      <section style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: is_open ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          background: '#000',
          opacity: '0.4'
        }}
        onClick={() => {
          // closeFunc()
          this.props.clickClose()
        }}
        >
        </div>
        <Wrapper style={{...wrapper_style}}>
          <Row>
            <Col xs={12} style={{margin: 'auto'}}>
              <Display>
                {initialValue ? initialValue : this.state.value}
              </Display>
            </Col>
            <Col xs={12} style={{margin: 'auto'}}>
              {NumBtn(1)}
              {NumBtn(2)}
              {NumBtn(3)}
            </Col>
            <Col xs={12} style={{margin: 'auto'}}>
              {NumBtn(4)}
              {NumBtn(5)}
              {NumBtn(6)}
            </Col>
            <Col xs={12} style={{margin: 'auto'}}>
              {NumBtn(7)}
              {NumBtn(8)}
              {NumBtn(9)}
            </Col>
            <Col xs={12} style={{margin: 'auto'}}>
              <ClearBtn className={'text center'} onClick={() => {
                this._close()
              }}>
                <IoMdClose/>
              </ClearBtn>
              {NumBtn(0)}
              <SubmitBtn className={'text center'} onClick={() => {
                if(!isEmpty(this.state.value)){ submitFunc(this.state.value) }
                this._close()
              }}>
                <IoIosCheckmark/>
              </SubmitBtn>
            </Col>
          </Row>
        </Wrapper>
      </section>
    )
  }

  /**
   * 閉じる際に呼び出す
   * @private
   */
  _close() {
    this.props.closeFunc(this.props.initialValue ? this.props.initialValue : this.state.value)
    this.setState({value: ''})
    this.props.clickClose()
  }

  /**
   * 数値ボタンが押されたときに呼び出す
   * @param value
   * @private
   */
  _setValue(value) {
    this.setState({value: this.state.value + '' + value})
  }
}

NumericPad.propTypes = {
  numericpad: PropTypes.object,
  closeFunc: PropTypes.func,
  submitFunc: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    numericpad: state.numericpad
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clickClose: () => {
      dispatch(numericpadAction.close())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumericPad)
