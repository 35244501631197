/*************
 *HOST
 *************/
export const DEV_HOST = 'cico-hoshinoresorts.mt-square.com'
export const PRO_HOST = 'cico.hoshinoresorts.systems'

const NODERED_DEV_HOST = 'hoshino-testci.mt-square.com/api/nodered'
// const NODERED_DEV_HOST = 'localhost:1880'
const NODERED_PRO_HOST = 'tapapi.cico.hoshinoresorts.systems/api/nodered'

const DEV_IMG = 'd2h6ki1t635jo7.cloudfront.net'
const PRO_IMG = 'due1lw2g3yclr.cloudfront.net'

//パスポートアップロード用URL
const DEV_UPLOAD_IMG = 'hoshino-testci.mt-square.com/api/upload/'
const PRO_UPLOAD_IMG = 'tapapi.cico.hoshinoresorts.systems/api/upload/'

/** スマ鍵 */
const DEV_SUMAKAGI_HOST = 'hoshino-testci.mt-square.com/api/sumakagi/v2'
const PRO_SUMAKAGI_HOST = 'tapapi.cico.hoshinoresorts.systems/api/sumakagi/v2'

const DEV_X_SBAPI_KEY = 'oGsvTEUmISnNGM3ba7HlTAXg0hwjUcdr2JiqOmQgPfLzY1wJqczKFuOdCkylWRoE3NhaSPpeKHy87V4u4QvIxiA6b5ZWM9L'
const PRO_X_SBAPI_KEY = 'oGsvTEUmISnNGM3ba7HlTAXg0hwjUcdr2JiqOmQgPfLzY1wJqczKFuOdCkylWRoE3NhaSPpeKHy87V4u4QvIxiA6b5ZWM9L'
const DEV_X_SUMAKAGI_KEY = 'cF4Fgj8fQCN9V0yYMm52qUiYFlYcxR2c70jSDOxIurvR80z'
const PRO_X_SUMAKAGI_KEY = 'cF4Fgj8fQCN9V0yYMm52qUiYFlYcxR2c70jSDOxIurvR80z'

// 一時データ保存
const DEV_UNLINKED_CARDS_API = 'pr3g840ba6.execute-api.ap-northeast-1.amazonaws.com/dev/'
const PRO_UNLINKED_CARDS_API = 'cardkeyapi.cico.hoshinoresorts.systems/prd/'

// 設定
const DEV_SUMAKAGI_TENANTS_API = 'oeg9099yk5.execute-api.ap-northeast-1.amazonaws.com/dev/'
const PRO_SUMAKAGI_TENANTS_API = 'sumakagiapi.cico.hoshinoresorts.systems/prd/'

const DEV_SETTINGS_API = 'tx6bqf11o1.execute-api.ap-northeast-1.amazonaws.com/dev/'
const PRO_SETTINGS_API = 'envapi.cico.hoshinoresorts.systems/prd/'

/**本番環境フラグ**/
export const IS_PRO_ENV = (() => {
  return window.location.hostname === PRO_HOST
})()

/*************
 *PREFIX
 *************/
// const INHOUSE = '/in-house'

/**開発URL**/
export const API_URL = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return 'https://' + DEV_HOST
    case PRO_HOST:
      return 'https://' + PRO_HOST
    default:
      return 'https://' + DEV_HOST
  }
})()
/**NODERED**/
export const NODERED_URL = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return 'https://' + NODERED_DEV_HOST
    case PRO_HOST:
      return 'https://' + NODERED_PRO_HOST
    default:
      return 'https://' + NODERED_DEV_HOST
  }
})()
/**IMG**/
export const IMG_URL = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return 'https://' + DEV_IMG
    case PRO_HOST:
      return 'https://' + PRO_IMG
    default:
      return 'https://' + DEV_IMG
  }
})()
/* UPLOAD IMG */
export const UPLOAD_IMG_URL = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return 'https://' + DEV_UPLOAD_IMG
    case PRO_HOST:
      return 'https://' + PRO_UPLOAD_IMG
    default:
      return 'https://' + DEV_UPLOAD_IMG
  }
})()
/**スマ鍵**/
export const SUMAKAGI_URL = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return 'https://' + DEV_SUMAKAGI_HOST
    case PRO_HOST:
      return 'https://' + PRO_SUMAKAGI_HOST
    default:
      return 'https://' + DEV_SUMAKAGI_HOST
  }
})()
export const SUMAKAGI_REQUEST_HEADER = ((url) => {
  let regexp_dev = new RegExp('^https?://' + DEV_SUMAKAGI_HOST)
  let regexp_pro = new RegExp('^https?://' + PRO_SUMAKAGI_HOST)

  if (url.match(regexp_pro)) {
    return {
      'X-SBAPI-KEY': PRO_X_SBAPI_KEY,
      'X-SUMAKAGI-KEY': PRO_X_SUMAKAGI_KEY,
    }
  } else if (url.match(regexp_dev)) {
    return {
      'X-SBAPI-KEY': DEV_X_SBAPI_KEY,
      'X-SUMAKAGI-KEY': DEV_X_SUMAKAGI_KEY,
    }
  }

  return {}
})
/**DynamoDB**/
export const UNLINKED_CARDS_API = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case PRO_HOST:
      return 'https://' + PRO_UNLINKED_CARDS_API
    default:
      return 'https://' + DEV_UNLINKED_CARDS_API
  }
})()
export const SUMAKAGI_TENANTS_API = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case PRO_HOST:
      return 'https://' + PRO_SUMAKAGI_TENANTS_API
    default:
      return 'https://' + DEV_SUMAKAGI_TENANTS_API
  }
})()
export const SETTINGS_API = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case PRO_HOST:
      return 'https://' + PRO_SETTINGS_API
    default:
      return 'https://' + DEV_SETTINGS_API
  }
})()
/**LINE認証情報**/
export const LINE_AUTH_INFO = (() => {
  let host_name = window.location.hostname

  switch (host_name){
    case DEV_HOST:
      return {
        CHANNEL_ID: '1574934196',
        SECRET: '2c1953c792503a190f5fb16b481c45fd',
      }
    case PRO_HOST:
      return {
        CHANNEL_ID: '',
        SECRET: '',
      }
    default:
      return {
        CHANNEL_ID: '1574934196',
        SECRET: '2c1953c792503a190f5fb16b481c45fd',
      }
  }
})()