import {keyframes} from 'styled-components'
import styled from 'styled-components'
import {Button, Row, Col} from 'reactstrap'
import {ACC_COLOR, J_ABURI_COLOR, SUB_COLOR, WHITE_COLOR} from '../constants/common';

export const purun = keyframes`
  0%   { transform: scale(1.0, 1.0) translate(0%, 0%); }
  3%  { transform: scale(0.9, 0.9) translate(0%, 5%); }
  5%  { transform: scale(1.3, 0.8) translate(0%, 10%); }
  7%  { transform: scale(0.8, 1.3) translate(0%, -10%); }
  10%  { transform: scale(1.1, 0.9) translate(0%, 5%); }
  13% { transform: scale(1.0, 1.0) translate(0%, 0%); }
  50% { transform: scale(1.0, 1.0) translate(0%, 0%); }
  100% { transform: scale(1.0, 1.0) translate(0%, 0%); }

`
export const Purun = styled.div`
  display: inline-block;
  animation: ${purun} 2s linear infinite;
`

export const DangerBtn = styled(Button)`
  background: ${J_ABURI_COLOR} !important;
  &:hover{
    background: 
  }
`
export const OkBtn = styled(Button)`
  background: ${ACC_COLOR} !important;
  border: none !important;
`
export const StyledThead = styled.thead`
  background-color: ${SUB_COLOR};
  color: ${WHITE_COLOR};
`
export const StyledTh = styled.th`
  text-align: center;
`
export const RowCenter = styled(Row)`
  justify-content: center;
`
export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`
export const RowVerticalCenter = styled(Row)`
  align-items: center;
`
export const ColAlignRight = styled(Col)`
  text-align: right;
`