import React, {Component} from 'react'

class IconButton extends Component {
  render() {
    const { btnLabel, LabelPadding, fontSize, btnHeight, btnFloat, imageWidth,  marginTop, btnImage, disabled, OnClick=()=>{}} = this.props
    const IconButton = {
      color:'#000000',
      fontSize:fontSize?fontSize:'0.9rem',
      float:btnFloat?btnFloat:'right',
      lineHeight:btnHeight?btnHeight:'2vw',
      marginTop:marginTop?marginTop:'',
      textAlign:'center',
      flexDirection:'column',
      padding:'3vh',
      width: '15vw',
      
    }

    const BtnLabelStyle = {
      padding:LabelPadding?LabelPadding:'0.8vh',
      verticalAlign:'middle',
     
    }

    const image ={
      width:imageWidth?imageWidth:'3vw',
    }

    return (
      <div style={IconButton} onClick={()=>OnClick()} disabled={disabled}><img style={image} src={btnImage}></img>
        <span style={BtnLabelStyle}>{btnLabel}</span>
      </div>
    );
  }
}
IconButton.propTypes = {
}

export default IconButton