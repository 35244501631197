export const MESSAGES = {
  INFO: {

  },
  WARN: {

  },
  ERROR: {
    LANE: {
      NOT_MATCH_SEAT_NUMBER: '同時に複数の卓に発射することはできません',
      NOT_COMPLETE_DELIV: '手動でレーンを動作させてください',
    },
    POST_VISITOR_FAILED: '来客情報の登録が失敗しました。\n',
    PUT_VISITOR_FAILED: '来客情報の更新が失敗しました。\n',
    POST_CUSTOMER_ORDER_FAILED: '注文情報の登録が失敗しました。\n',
    GET_UNCOLLECTED_FAILED: '未収情報の登録が失敗しました。\n',
    POST_ACCOUNT_FAILED: '会計の登録が失敗しました。\n',
    POST_ADJUSTMENT_FAILED: '清算処理が失敗しました。\n',
    AUTH_FAILED: '401: 認証エラーです。\n',
    NETWORK_TIMEOUT: '504: 接続タイムアウトです。\nネットワーク・もしくはサーバー側に問題があります。\n',
    NETWORK_FAILED: '接続が確立できませんでした。\nネットワークに問題があります。\n',
    PRINTER_FAILED: 'プリンターとの接続に問題があります。\nスタッフにお声がけください。\n'
  }
}