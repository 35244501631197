import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, Row, Col, Card, CardBody, ModalHeader, Spinner} from 'reactstrap'
import styled from 'styled-components'
import nl2br from 'react-nl2br'
import {actions as errorAction} from '../../../modules/error'
import {isEmpty} from '../../../util/common'
import {FaArrowAltCircleRight, FaBlackTie} from 'react-icons/fa'
import {push} from 'connected-react-router'
import { ok } from 'assert'
import { withTranslation } from 'react-i18next'
import Btn from '../../../components/atomics/Button'

/**
 * 決済失敗モーダル
 */
class PayFailure extends Component {
  
  render() {
    const {
      title,
      message,
      t
    } = this.props

    return (
      <section id="pay_credit_sequence">
        <PayModal
          backdrop="static"
          isOpen={true}
          size="lg"
          className="modal-dialog-centered"
        >
          <PayModalHeader>
            {t(title)}
          </PayModalHeader>
          <ModalBody>
            <div style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', margin: '30px'}}>
              {/* {nl2br(message.replace('\r', ''))} */}
              <p style={{margin: '0'}}>{t('決済できませんでした')}</p>
              <p style={{margin: '0'}}>{t('もう一度お試しください')}</p>
            </div>
          </ModalBody>
        </PayModal>
      </section>
    )
  }
}

PayFailure.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayFailure))

const PayModal = styled(Modal)`
  min-height: 40vh;
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
`

const PayModalHeader = styled(ModalHeader)`
  background-color: #dc3545;
  .modal-title {
    margin: auto;
    font-size: 2rem;
    color: white;
  }
`

const PayCancel = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.5rem;
  font-size: 1rem;
  color: white;
`

const PayModalBody = styled(ModalBody)`
  min-height: 40vh;
`
const CardLogoArea = styled.div`
  display:flex;
  padding:10px;
  justify-content: center;
`
const CardLogo = styled.div`
  display:flex;
  padding:5px;
`
const LogoImg = styled.img`
  height:calc(100vw/35);
  flex-basis: auto;
`

const PaySeqModal = styled(Modal)`
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .modal-footer {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      justify-content: center;
    }
  }
`
const PaySeqModalBody = styled(ModalBody)`
`
const PaySeqModalFooter = styled(ModalFooter)`
  
`