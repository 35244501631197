import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
} from 'reactstrap'
import {push} from 'connected-react-router'
import Header from '../../components/molecules/Header'
import CustomContainer from '../../components/atomics/CustomContainer'
import Paper from '../../components/atomics/Paper'
import Footer from '../../components/molecules/Footer'
import {RowCenter, RowVerticalCenter} from '../../util/styled'
import {containerStyle, FONT_COLOR} from '../../constants/common'
import { IoIosBed } from 'react-icons/io';
import styled from 'styled-components'

/**
 * 予約検索
 */
class ReserveComplete extends Component {
  render() {
    return (
      <section>
        <Header/>
        <CustomContainer>
          <Container style={containerStyle}>
            <RowVerticalCenter>
              <BedIcon/>
              <Title>
                チェックインが完了しました
              </Title>
            </RowVerticalCenter>
            <Paper height='60vh'>
              <RowCenter>
                <MainMessage>
                  お部屋へご案内します。
                </MainMessage>
              </RowCenter>
              <RowCenter>
                <SubMessage>
                  端末をスタッフへお返しください。
                </SubMessage>
              </RowCenter>
            </Paper>
          </Container>
        </CustomContainer>
        <Footer onClick={()=>this.props.returnTop()}>
        </Footer>
      </section>
    )
  }
}

ReserveComplete.propTypes = {}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult
  }
}

const mapDispatchToProps = dispatch => {
  return {
    returnTop: () => dispatch(push('/reserve/search')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveComplete)

const BedIcon = styled(IoIosBed)`
  font-size: 30px;
`
const Title = styled.span`
  font-size: 30px;
  color: ${FONT_COLOR};
`
const MainMessage = styled.p`
  font-size: 50px;
  color: ${FONT_COLOR};
`
const SubMessage = styled.p`
  font-size: 25px;
  color: ${FONT_COLOR};
`