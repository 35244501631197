import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import {containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next';
import CustomContainer from '../../components/atomics/CustomContainer'
import Btn from '../../components/atomics/Button'
import {actions as checkOutAction} from '../../modules/checkOut'



class ChechoutCompleted extends Component {
  
  // チェックアウト完了したら10秒後にTOP画面に戻す
  componentDidMount() {
    this.props.start()
    this.setState(
      {
        to_top_timer: setTimeout(() => {
          this.props.backTop()
        }, 10000)
      }
    )
  }
  
  componentWillUnmount() {
    clearTimeout(this.state.to_top_timer)
  }

  render() {
    const {t, i18n, ResultNo} = this.props
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}></Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                {ResultNo === '10000' ?
                <MsgArea style={{marginTop:'13.5vh'}} className={[i18n.language]}>
                  <p>{t('チェックアウト処理が完了しました')}</p>
                  <p style={{marginTop:'10vh'}}>{t('ありがとうございました')}</p>
                </MsgArea>
                :
                <MsgArea style={{marginTop:'13.5vh', whiteSpace:'pre-line'}} className={[i18n.language]}>
                  <p>{t('チェックアウト処理が完了しました')}</p>
                  <p>{t('ご案内用紙をお取りください')}</p>
                  <p style={{marginTop:'10vh'}}>{t('ありがとうございました')}</p>
                </MsgArea>}
              </div>
            </ConfirmArea>
            <Btn btnLabel={t('OK')} marginTop='10vh' OnClick={() => this.props.clickBtn('/checkout/key-touch')}/>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}


ChechoutCompleted.propTypes = {
}

const mapStateToProps = state => {
  return {
    ResultNo: state.checkOut.checkoutResult
  }
}

const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      dispatch(checkOutAction.init())
    },
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: path => {
      dispatch(push(path))
    },
    backTop: () => {
      dispatch(push('/checkout/key-touch'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChechoutCompleted))

/* CSS定義 */
const clear_both = {
  clear:'both'
}

const ConfirmArea = styled.div`
  margin:0 auto;
`

const MsgArea = styled.div`
  font-size:2.7rem;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
  width:60vw;
  &.en {
    width:80vw;
  }
`
