import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
  Button,
} from 'reactstrap'
import { IoMdArrowRoundBack } from 'react-icons/io'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {GREY_COLOR, containerStyle} from '../../constants/common'
import CustomInput from '../../components/atomics/CustomInput'
import styled from 'styled-components'
import { reduxForm, destroy as formDestroy } from 'redux-form'
import Btn from '../../components/atomics/Button'
import TitleHeader from '../../components/atomics/TitleHeader'
import IconBtn from '../../components/atomics/IconButton'
import {actions as keyIssueAction} from '../../modules/keyIssue'
import '../../css/CustomerEdit.css'
import { isEmpty } from '../../util/common'

/**
 * 手動ルームキー発行(情報入力)
 */
class EnterInformation extends Component {
  componentDidMount() {
    this.props.start()
  }
  state = { 
    count: 0, 
    interval: 0
  };

  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.clickBtn('/config/setting_select')
    }
  }

  render() {
    const { isFilled, t } = this.props

    console.log(isFilled)

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
            <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
              <img
                src="https://dfc78s572732n.cloudfront.net/logo/black/ja/logo-bk3.png"
                alt=""
                style={{height:'4vw'}}
              />
            </Logo>
          </Row>
          <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
            <Button onClick={() => this.props.clickBtn('/config/setting_select')}>
              <IoMdArrowRoundBack />
            </Button>
          </Row>
        </div>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle='スマカギ用鍵発行画面'/>
              <div style={{fontSize:'1.3rem', margin:'0 auto'}}>※タップと連携しておりません。チェックイン等の必要処理はタップにて手動で行ってください。</div>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <RowCenter style={{height:'7vw', margin:'0'}}>
                  <TableHeader xs={4} style={{height:'7vw'}}>予約番号</TableHeader>
                  <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                    <TableList>
                      <CustomInput bordered="true" name="ReserveNo" component="input" type="text" placeholder="" />
                    </TableList>
                  </TableData>
                </RowCenter>
                <RowCenter style={{height:'7vw', margin:'0'}}>
                  <TableHeader xs={4} style={{height:'7vw'}}>部屋番号</TableHeader>
                  <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                    <TableList>
                      <CustomInput bordered="true" name="RoomNo" component="input" type="text" placeholder="" />
                    </TableList>
                  </TableData>
                </RowCenter>
                <RowCenter style={{height:'7vw', margin:'0', whiteSpace:'pre-line'}}>
                  <TableHeader xs={4} style={{height:'7vw'}}>発行する枚数</TableHeader>
                  <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                    <TableList>
                      <CustomInput bordered="true" name="RoomKeyNo" component="input" type="text" placeholder="" />
                    </TableList>
                  </TableData>
                </RowCenter>
              </div>
            </ConfirmArea>
            <Btn btnLabel='次へ' disabled={!isFilled} marginTop='10vh' OnClick={()=>this.props.clickNext()}/>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

EnterInformation.propTypes = {}

const mapStateToProps = state => {
  const values = (state.form.EnterInformation && state.form.EnterInformation.values) ? state.form.EnterInformation.values : {}
  return {
    isFilled: !isEmpty(values.ReserveNo) && !isEmpty(values.RoomNo) && !isEmpty(values.RoomKeyNo)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      dispatch(formDestroy(
        'EnterInformation'
      ))
    },
    clickBtn: (path) => dispatch(push(path)),
    clickNext: () => {
      dispatch(keyIssueAction.setIsManual(true))
      dispatch(push('/checkin/room_key_issue'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'EnterInformation',
    destroyOnUnmount: false,
  })(withTranslation()(EnterInformation)))

const ConfirmArea = styled.div`
  margin:0 auto;
`
const Logo = styled.div`
  margin-right: auto;
`
const Residence = styled.div`
  background-color:#F2F0F0;
  border:1px solid ${GREY_COLOR};
  box-shadow:0px 2px  ${GREY_COLOR};
  float:left;
  height:4.5vw;
  line-height:4.5vw;
  text-align:center;
  width:15vw;
`
const TableList = styled.div`
  width: 60vw;
`
const TableHeader = styled(Col)`
  background-color:${GREY_COLOR};
  border-bottom:1px solid #fff;
  color:#333;
  line-height:1.5rem;
  font-size:1.5rem;
  padding:1.5vh;
`
const TableData = styled(Col)`
  border-top:1px solid ${GREY_COLOR};
  border-right:1px solid ${GREY_COLOR};
  // border-right:1px solid ${GREY_COLOR};
  // border-bottom:1px solid ${GREY_COLOR};
  font-size:1.5rem;
  display: flex !important;
  align-items: center !important;
  padding:1.5vh;
`
const PageNumber = styled.div`
  margin-top:1.5vh;
  margin-right:1.5vw;
`
const NextPage = styled.div`
  margin-top:1.5vh;
`
const clear_both = {
  clear:'both'
}