import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import {ACC_COLOR, SUB_COLOR, containerStyle} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { destroy as formDestroy } from 'redux-form'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import { RowCenter } from '../../util/styled'
import {actions as reserveAction} from '../../modules/reserve'

class Checkout extends Component {
  componentDidMount() {
    this.props.initReserve()
  }
  state = { 
    count: 0, 
    interval: 0
  };

  //ロゴを5回クリックでTOPへ遷移
  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.history.push('/')
    }
  }

  render() {
    const { t, i18n } = this.props
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <CustomContainer style={{height:'100vh'}}>
          <Container style={{...containerStyle, height:'100vh'}}>
            <Row>
              <Logo className={[i18n.language]} onClick={this.clickDummyBtn}><img src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`} alt="" style={{height:'4vw'}}/></Logo>
            </Row>
            <RowCenter>
              <TitleHeader marginTop='8vw' headerTitle={t('チェックアウトをしますか？')}/>
            </RowCenter>
            <SelectBtnArea>
              <CheckoutBtn onClick={()=>this.props.clickBtn('yes')}>{t('はい')}</CheckoutBtn>
            </SelectBtnArea>
            <LanguageArea className={[i18n.language]}>
              <LanguageBtn style={i18n.language === 'ja' ? selected : {}} onClick={()=>{ i18n.changeLanguage('ja') }}>日本語</LanguageBtn>
              <LanguageBtn style={i18n.language === 'en' ? selected : {}} onClick={()=>{ i18n.changeLanguage('en') }}>ENGLISH</LanguageBtn>
            </LanguageArea>
          </Container>
        </CustomContainer>
      </section>
    );
  }
}
Checkout.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/main-menu'))
    },
    clickBtn: searchType => {
      dispatch(reserveAction.setSearchType(searchType))
      dispatch(push('/checkout/key-touch'))
    },
    initReserve: () => {
      dispatch(formDestroy(
        'customerEdit',
        'customerEditOption',
      ))
      dispatch(reserveAction.initReserve())
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(Checkout)
)

/* CSS定義 */
const Logo = styled.div`
  margin-right: auto;
`
const MainArea = styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  list-style:none;
  max-width:75vw;
`
const SelectBtnArea = styled(MainArea)`
  padding: 0px;
  margin: 0 auto;
`
const LanguageArea = styled(MainArea)`
  padding: 0;
  margin: 25vh auto 0 auto;
  justify-content: space-evenly;
`
const CheckoutBtn = styled.li`
  background-color:${SUB_COLOR};
  border-radius:0.4vw;
  box-shadow:0px 4px ${ACC_COLOR};
  color:#fff;
  font-size:2.5em;
  height:14vh;
  line-height:14vh;
  margin: 0 auto;
  margin-top:5vh;
  text-align:center;
  max-width:25vw;
  width:20vw;
`
const LanguageBtn = styled.li`
  background-color:#DDDDDD;
  color:#fff;
  text-align:center;
  height:5vh;
  line-height:5vh;
  width:10vw;
`
const selected ={
  backgroundColor:'#31A237',
  color:'#fff',
  fontWeight:'bold'}