import {GET_STORE_MASTER_URL} from '../constants/api-url'
import { createActions, handleActions } from 'redux-actions'
import { put, takeEvery } from 'redux-saga/effects'
import { actions as MiddlewareAction } from './middleware'

/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    getStoreMaster: () => ({}),
    setStoreMaster: payload => payload,
  },
  { prefix: 'hoshino/master'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  stores: [],
}
const reducer = handleActions({
  [actions.setStoreMaster]: (state, action) => {
    const json = action.payload
    // TODO: 選択店舗を格納
    localStorage.setItem('selectedStoreObject', JSON.stringify(json.stores[0]))
    const {stores} = json
    return {
      ...state,
      stores: stores
    }
  },
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* masterSaga() {
  yield takeEvery(actions.getStoreMaster, getStoreMaster)
}

function* getStoreMaster(action) {
  const payload = {
    // PANDA_API_URLは設定から除外している
    //url: PANDA_API_URL + GET_STORE_MASTER_URL,
    method: 'GET',
    request: '',
    reqAuth: false,
    isCors: true,
    successCB: actions.setStoreMaster
  }
  yield put(MiddlewareAction.api(payload))
}