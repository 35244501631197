import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
  Button,
} from 'reactstrap'
import {push, goBack} from 'connected-react-router'
import Header from '../../components/molecules/Header'
import CustomContainer from '../../components/atomics/CustomContainer'
import Paper from '../../components/atomics/Paper'
import Footer from '../../components/molecules/Footer'
import {actions as reserveAction} from '../../modules/reserve'
import {actions as numericpadAction} from '../../modules/numericpad'
import {RowCenter} from '../../util/styled'
import {ACC_COLOR, DARYGREY_COLOR, WHITE_COLOR} from '../../constants/common'
import CustomCard from '../../components/atomics/CustomCard'
import styled from 'styled-components'
import NumericPad from '../../components/molecules/NumericPad'
import moment from 'moment'

/**
 * 予約検索
 */
class KeyRegister extends Component {
  render() {
    const {selectedReserve, keyCode} = this.props
    const {
      GuestName,
      CheckOutDate,
      detail,
    } = selectedReserve

    return (
      <section>
        <Header headerTitle={'宿泊者照会'}/>
        <CustomContainer>
          <Container>
            <Paper height='60vh'>
              <RowCenter>
                <CustomCard noBorder headerTitle={'氏名(フリガナ)'} cardWidth={'43%'}>
                  {detail.GuestName_Jpn}
                  {`(${GuestName})`}
                </CustomCard>
                <CustomCard noBorder headerTitle={'部屋番号'} cardWidth={'20%'}>

                </CustomCard>
                <CustomCard noBorder headerTitle={'チェックアウト日'} cardWidth={'30%'}>
                  {moment(CheckOutDate).format('YYYY/MM/DD')}
                </CustomCard>
              </RowCenter>
              <RowCenter>
                <CustomCard noBorder headerTitle={'暗証キー'} cardWidth={'100%'}>
                  <NumberRow>
                    {renderBox(keyCode, this.props.clickBox)}
                  </NumberRow>
                </CustomCard>
              </RowCenter>
              <RowCenter>
                <Button style={registerButtonStyle} onClick={()=>this.props.clickRegisterButton()}>
                  発行
                </Button>
                <Button style={deleteButtonStyle} onClick={()=>this.props.clickDeleteButton()}>
                  無効化
                </Button>
              </RowCenter>
            </Paper>
          </Container>
        </CustomContainer>
        <Footer onClick={()=>this.props.clickReturn()}>
          <h2>戻る</h2>
        </Footer>
        <NumericPad initialValue={keyCode} closeFunc={code=>this.props.setKeyCode(code)}/>
      </section>
    )
  }
}

KeyRegister.propTypes = {}

const mapStateToProps = state => {
  return {
    selectedReserve: state.reserve.selectedReserve,
    keyCode: state.reserve.keyCode,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickReturn: () => dispatch(goBack()),
    clickRegisterButton: () => dispatch(push('/reserve/complete')),
    clickDeleteButton: () => dispatch(push('/reserve/complete')),
    clickBox: () => dispatch(numericpadAction.toggle()),
    setKeyCode: code => dispatch(reserveAction.setKeyCode(code)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyRegister)

const renderBox = (keyCode, clickBox) => {
  let renderArray = []
  for(let i=0; i<6; i++){
    renderArray.push(<NumBox onClick={()=>clickBox()}>{keyCode[i]}</NumBox>)
  }
  return renderArray
}
const NumBox = styled.div`
  font-size: 55px;
  color: ${WHITE_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ACC_COLOR};
  border: ${ACC_COLOR};
  border-radius: 4px;
  height: 100px;
  width: 100px;
  margin-right: 3vw;
`
const NumberRow = styled(RowCenter)`
  margin: 4vh auto;
`
const registerButtonStyle = {
  background: ACC_COLOR,
  border: ACC_COLOR,
  height: '80px',
  width: '90px',
  marginRight: '20vw',
}
const deleteButtonStyle = {
  background: DARYGREY_COLOR,
  border: DARYGREY_COLOR,
  height: '80px',
  width: '90px',
}