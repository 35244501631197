import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {containerStyle} from '../../constants/common'
import { reduxForm } from 'redux-form'
import Wizard from '../../components/atomics/StepWizard'
import TitleHeader from '../../components/atomics/TitleHeader'
import IconBtn from '../../components/atomics/IconButton'
import {actions as reserveAction} from '../../modules/reserve'

/**
 * QRコード
 */
class QR extends Component {
  componentDidMount() {
    this.props.init()
    this.props.startQr()
  }
  componentWillUnmount() {
    this.props.endQr()
  }
    
  render() {
    const {t} = this.props

    const isApri = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qr

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn('/checkin')}/>
          <Wizard index={1}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader marginTop='2vh' marginBottom='8vh' headerTitle={t('QRコードをかざしてください')}/>
            </Row>
            <AnimationArea>
              <img src="https://dfc78s572732n.cloudfront.net/animation/20200225/qr_display.gif" />
            </AnimationArea>
            <div height='68vh'>
              {isApri ? '' : <button onClick={()=>this.props.successQR()}>QR成功</button>}
            </div>
            <BottomMessage>{t('読み込めない場合はお手持ちの画面を明るくしてお試しください')}</BottomMessage>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

QR.propTypes = {}

const mapStateToProps = state => {
  return {
    initialValues: {
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reserveAction.refreshReserve())
    },
    clickBtn: path => dispatch(push(path)),
    successQR: () => {
      window.callbackQrSuccess('ygt1912200032345678901')
    },
    startQr: () => {
      // カメラ起動
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qr) {
        window.webkit.messageHandlers.qr.postMessage('start')
      }
 
      window.callbackQrSuccess = qr => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qr) {
          window.webkit.messageHandlers.qr.postMessage('stop')
        }

        const reserve_num = qr.substring(12)
        if (reserve_num === '') {
          // QRが違う
          dispatch(reserveAction.errorMessage('ご予約を特定できませんでした\n恐れ入りますが、他の方法で検索いただけますでしょうか。'))
          return
        }
        
        dispatch(reserveAction.qrSetflg(true))
        dispatch(reserveAction.findReserve({searchType: 'reserve_num', value: reserve_num}))
      }

      window.callbackError = message => {
        console.log(message)
      }

    },
    endQr: () => {
      // カメラ終了
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qr) {
        window.webkit.messageHandlers.qr.postMessage('stop')
      }
  
      window.callbackQrSuccess = qr => {
      }
      window.callbackError = message => {
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'QR',
    destroyOnUnmount: false,
  })(withTranslation()(QR)))

const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
const BottomMessage = styled.p`
  font-size: 1.5em;
  margin-top: 560px; 
`
const AnimationArea = styled.div`
  float: right;
  img {
    width:500px;
  };
`
