import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  Row,
  Button,
  Container,
  Col,
  Input,
  Label,
  FormGroup,
} from 'reactstrap'
import { IoMdArrowRoundBack } from 'react-icons/io'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { containerStyle } from '../../constants/common'
import TitleHeader from '../../components/atomics/TitleHeader'
import { actions as settingAction } from '../../modules/setting'
import CustomContainer from '../../components/atomics/CustomContainer'
import * as vega3000 from '../../modules/vega3000'
import { actions as errorAction } from '../../modules/error'
import { actions as receiptAction } from '../../modules/receipt'
import {actions as loadingAction} from '../../modules/loading'

class Vega3000Config extends Component {

    state = { 
      count: 0, 
      interval: 0
    };
    
    clickDummyBtn = () => {
      this.setState({ count: ++this.state.count });
      if (this.state.count === 1) {
        this.count = setInterval(() => {
          this.setState({interval: this.state.interval + 1})
          if (this.state.interval >= 3) {
            this.setState({count: 0, interval: 0})
            clearInterval(this.count)
          }
        }, 1000)
      }
      if (this.state.count === 5) {
        this.props.clickBtn('/config/setting_select')
      }
    }

    render() {
      const {i18n, setParams, cico, vega3000Url, t} = this.props
      return (
        <section
          style={{ backgroundColor: '#fff', height: '100vh', overflow: 'hidden' }}
        >
          <div style={{ height: '10vh', display: 'flex' }}>
            <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
              <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
                <img
                  src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`}
                  alt=""
                  style={{ height: '4vw' }}
                />
              </Logo>
            </Row>
            <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
              <Button onClick={() => this.props.clickBtn('/config/setting_select')}>
                <IoMdArrowRoundBack />
              </Button>
            </Row>
          </div>
          <Line />
          <CustomContainer>
            <Container style={containerStyle}>
              <Row style={clear_both}>
                <TitleHeader
                  headerWidth="100vw"
                  marginTop="2vh"
                  headerTitle={t('決済端末設定')}
                />
                <Col xs={12}>
                  <FormGroup>
                    <Row>
                      <Col xs={2}>
                        <Label for="vega3000">
                          <Label className={i18n.language}>
                            {t('vega3000設定')}
                          </Label>
                        </Label>
                      </Col>
                      <Col>
                        <Input type="select" value={vega3000Url} onChange={(e) => (setParams({ vega3000Url: e.target.value }))}>
                          <option key="empty" value=""></option>
                          {cico.vega3000.map((item, key) => (
                            <option key={key} value={item.value_string}>
                              {`${item.key_name} ( ${item.value_string} )`}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Row>
                      <Col xs={2}>
                        <Label for="selectPrinter">
                          <Label className={i18n.language}>
                            {t('決済端末')}
                          </Label>
                        </Label>
                      </Col>
                      <Col>
                        <Button color="primary" onClick={() => this.props.outputReport('credit')}>クレジット日計出力</Button>{' '}
                        <Button color="primary" onClick={() => this.props.outputReport('ginren')}>銀聯日計出力</Button>{' '}
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CustomContainer>
        </section>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    vega3000Url: state.setting.vega3000Url,
    cico: state.setting.cico
  }
} 

const mapDispatchToProps = (dispatch) => {
  return {
    setParams: params => {
      dispatch(settingAction.setParams(params))
    },
    clickBtn: (path, flg) => {
      dispatch(push(path))
    },
    outputReport: type => {
      dispatch(loadingAction.openLoading())
      dispatch(loadingAction.onContinue())
      vega3000
        .report(type)
        .success((result) => {
          dispatch(receiptAction.printCreditReport(type, result))
          dispatch(loadingAction.offContinue())
        })
        .failure((result) => {
          dispatch(loadingAction.offContinue())
          dispatch(errorAction.displayError(`日計取得に失敗しました\nCODE：${result.resultCode}`))
        })
        .error((message) => {
          dispatch(loadingAction.offContinue())
          dispatch(errorAction.displayError(message))
        })

      // ローカル確認
      if (!vega3000.hasNative) {
        //vega3000.debugFailure('100', '')
        if (type === 'credit') {
          vega3000.debugSuccess({csv:'YYYY/MM/DD HH:MM:SS,99999-999-99999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,一括,9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,ボーナス,9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,分割,9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,ボ併,9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,リボ,9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,一括,-9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,ボーナス,-9999999 XXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,分割,-9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,ボ併,-9999999\r\nXXXXXXXXX,1,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,リボ,-9999999999\r\nXXXXXXXXX,売上小計\r\nXXXXXXXXX,一括,N,9999999999\r\nXXXXXXXXX,取消小計\r\nXXXXXXXXX,一括,N,-9999999999\r\nXXXXXXXXX,合計\r\nXXXXXXXXX,売上,N,9999999999\r\nXXXXXXXXX,取消,N,-9999999999\r\n売上合計\r\n一括,N,9999999999\r\n取消合計\r\n一括,N,-9999999999\r\n総合計\r\n売上,N,9999999999\r\n取消,N,-9999999999'})
          //vega3000.debugSuccess({csv:'YYYY/MM/DD HH:MM:SS,99999-999-99999\r\n売上合計\r\n一括,0,0\r\n取消合計\r\n一括,0,0\r\n総合計\r\n売上,0,0\r\n取消,0,0'})
        } else if (type === 'ginren') {
          vega3000.debugSuccess({csv:'YYYY/MM/DD HH:MM:SS,99999-999-99999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,売上,9999999,99999,9999999,9999999999999999999,9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,-9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,-9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,-9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,-9999999\r\nXXXXXXXXX,1,YYYY/MM/DD,HH:MM:SS,取消,9999999,99999,9999999,9999999999999999999,-9999999\r\n<総合計>\r\n売上,N,9999999999\r\n取消,N,-9999999999'})
        }
      }
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Vega3000Config))

/* CSS定義 */
const clear_both = {
  clear: 'both'
}

const Logo = styled.div`
  margin-right: auto;
`

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top: 0;
  opacity: 0;
`