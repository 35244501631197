import { createActions, handleActions } from 'redux-actions'
/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    toggle: () => ({}),
    close: () => ({}),
    setPosition: payload => payload,
  },
  { prefix: 'hoshino/numericpad'},
)
/*********************************
 * REDUCER
 ********************************/
export const initialState = {
  is_open: false,
  position: {
    m_left: null,
    m_right: null,
  }
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.toggle]: state => ({
    ...state,
    is_open: !state.is_open,
  }),
  [actions.close]: state => ({
    ...state,
    is_open: false,
    position: initialState.position
  }),
  [actions.setPosition]: (state, action) => ({
    ...state,
    position: {
      ...state.position,
      ...action.payload
    }
  }),
}, initialState)

export default reducer
