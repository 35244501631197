import { createActions, handleActions } from 'redux-actions'
import {
  GET_RESERVATION_DETAIL_URL,
  GET_RESERVATION_URL,
  GET_ROOM_URL,
  POST_CHECK_IN_URL,
  GET_MEAL_COUPON_URL,
  PUT_RESERVATION_URL,
  PUT_REGISTRATION_URL
} from '../constants/api-url'
import {actions as paymentAction} from './payment'
import { select, put, call, takeEvery, all, take } from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {NODERED_URL, UPLOAD_IMG_URL} from '../constants/api-config'
import {actions as MiddlewareAction, api} from './middleware'
import {actions as errorAction} from './error'
import {actions as loadingAction} from './loading'
import {actions as receiptActions} from './receipt'
import {isEmpty} from '../util/common'
import moment from 'moment'
import { SSL_OP_TLS_BLOCK_PADDING_BUG } from 'constants'
import i18n from 'i18next'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    initReserve: () => ({}),
    refreshReserve: () => ({}),
    refreshSelectedReserve: () => ({}),
    findReserve: payload => payload,
    selectReserve: payload => payload,
    setKeyCode: payload => payload,
    roomSetflg: payload => payload,
    qrSetflg: payload => payload,
    setSearchType: payload => payload,
    updateSearchReserve: payload => payload,
    successSearchReserve: payload => payload,
    successSearchReserveDetail: payload => payload,
    searchRoom: payload => payload,
    storePassportImage: payload => payload,
    imageCountup: payload => payload,
    imageUpload: payload => payload,
    successSearchRoom: payload => payload,
    updateRegistration: payload => payload,
    successUpdateRegistration: payload => payload,
    checkIn: payload => payload,
    successCheckIn: payload => payload,
    setBalance: payload => payload,
    searchMealCoupon: payload => payload,
    successSearchMealCoupon: payload => payload,
    updateReserve: payload => payload,
    successUpdateReserve: payload => payload,
    errorMessage: payload => payload,
    successPostPayment: payload => payload,
    setSelectAllRoom: payload => payload,
    hasRequiredItem: payload => payload,
  },
  { prefix: 'hoshino/reserve'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  searchParam: {},
  searchResult: [],
  selectedReserve: [],
  keyCode: '',
  searchType: '',
  passportImage: '',
  flg: false,
  qrsearchflg: false,
  imageCount: 0,
}
const reducer = handleActions({
  [actions.initReserve]: () => initialState,
  [actions.refreshReserve]: (state) => ({
    ...state,
    searchResult: [],
    selectedReserve: [],
    passportImage: '',
    flg: false,
    qrsearchflg: false,
    imageCount: 0,
  }),
  [actions.refreshSelectedReserve]: (state) => ({
    ...state,
    selectedReserve: [],
  }),
  [actions.selectReserve]: (state, action) => {
    let newArray = []
    if (state.selectedReserve.find(item => item.RoomAccountID === action.payload.RoomAccountID)){
      newArray = state.selectedReserve.filter(item => item.RoomAccountID !== action.payload.RoomAccountID)
    } else {
      newArray = [...state.selectedReserve, action.payload]
    }
    return {...state, selectedReserve: newArray}
  },
  [actions.setKeyCode]: (state, action) => ({...state, keyCode: action.payload}),
  [actions.roomSetflg]: (state, action) => ({...state, flg: action.payload}),
  [actions.qrSetflg]: (state, action) => ({...state, qrsearchflg: action.payload}),
  [actions.storePassportImage]: (state, action) => ({...state, passportImage: action.payload}),
  [actions.imageCountup]: (state, action) => ({...state, imageCount: state.imageCount+1}),
  [actions.setSearchType]: (state, action) => ({...state, searchType: action.payload}),
  [actions.successSearchReserve]: (state, action) => ({
    ...state,
    searchResult: action.payload.FindReservation_ResultList.map(reserve => ({
      ...reserve,
      GuestName: reserve.hasOwnProperty('GuestName') ? reserve.GuestName : reserve.ReservationGuestName
    }))
  }),
  [actions.successSearchReserveDetail]: (state, action) => {
    //予約検索結果に詳細情報を付加
    const updated = state.searchResult.map((item, key) => {
      if(key === action.payload.index){
        return {...item, detail: action.payload.json.GetRegistrationInfo_ResultList}
      }else{ return item }
    })
    return {...state, searchResult: updated}
  },
  [actions.successSearchRoom]: (state, action) => {
    //予約検索結果に部屋情報を付加
    const resultList = action.payload.json.FindRooms_ResultList
    const updated = state.selectedReserve.map((item, key) => {
      if(key === action.payload.index){
        return {...item, room: !isEmpty(resultList) ? resultList[0] : null}
      }else{ return item }
    })
    return {...state, selectedReserve: updated}
  },
  [actions.successCheckIn]: (state, action) => {
    //予約検索結果にチェックイン情報を付加
    const updated = state.selectedReserve.map((item, key) => {
      if(key === action.payload.index){
        return {...item, checkIn: action.payload.json.CheckIn_ResultList}
      }else{ return item }
    })
    return {...state, selectedReserve: updated}
  },
  [actions.setBalance]: (state, action) => {
    //予約検索結果に精算情報を付加
    const updated = state.selectedReserve.map((item, key) => {
      if(key === action.payload.index){
        return {...item, balance: action.payload.json.GetBalance_ResultList}
      }else{ return item }
    })
    return {...state, selectedReserve: updated}
  },
  [actions.successSearchMealCoupon]: (state, action) => {
    //予約検索結果に食券情報を付加
    const updated = state.selectedReserve.map((item, key) => {
      if(key === action.payload.index){
        return {...item, coupon: action.payload.json.GetMealCoupon_ResultList}
      }else{ return item }
    })
    return {...state, selectedReserve: updated}
  },
  [actions.successPostPayment]: (state, action) => {
    //予約検索結果に支払い情報を付加
    const updated = state.selectedReserve.map((item, key) => {
      if(key === action.payload.index){
        return {...item, payment: action.payload.json.ResultHeader, credit: action.payload.credit}
      }else{ return item }
    })
    return {...state, selectedReserve: updated}
  },
  [actions.updateSearchReserve]: (state, action) => ({...state, searchResult: action.payload}),
  [actions.setSelectAllRoom]: (state, action) => ({...state, selectedReserve: state.searchResult}),
}, initialState)



export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* reserveSaga() {
  yield takeEvery(actions.findReserve, findReserve)
  yield takeEvery(actions.imageUpload, imageUpload)
  yield takeEvery(actions.searchRoom, searchRoom)
  yield takeEvery(actions.updateRegistration, updateRegistration)
  yield takeEvery(actions.successUpdateRegistration, successUpdateRegistration)
  yield takeEvery(actions.checkIn, checkIn)
  yield takeEvery(actions.successCheckIn, successCheckIn)
  yield takeEvery(actions.searchMealCoupon, searchMealCoupon)
  yield takeEvery(actions.successSearchMealCoupon, successSearchMealCoupon)
  yield takeEvery(actions.updateReserve, updateReserve)
  yield takeEvery(actions.successUpdateReserve, successUpdateReserve)
  yield takeEvery(actions.errorMessage, errorMessage)
  yield takeEvery(actions.hasRequiredItem, hasRequiredItem)
}

function* imageUpload(action) {

  console.log('イメージアップロード開始')

  const {reserve: {
    selectedReserve,
    selectAllRoom,
  }} = yield select()

  let {imageCount, image} = action.payload

  // 部屋ごとの連番 使わなくなったが一応コメント保存
  // let subtotal = 0
  // let roomNo = ''
  // let sequence = ''
  // for(let reserve of selectedReserve) {
  //   let num = (reserve.detail.Person_M + reserve.detail.Person_F + reserve.detail.Person_C)
  //   if (subtotal + num > imageCount) {
  //     roomNo = reserve.room.RoomNo
  //     sequence = (imageCount + 1) - subtotal
  //     break
  //   }
  //   subtotal += num
  // }
  var date = moment()

  if(image) {
    URL = `${UPLOAD_IMG_URL}?key=BEB5土浦/` + date.format('YYMMDD') + '/'+ selectedReserve[0].ReserveNo + '_' + (imageCount + 1) + '_' + date.format('YYMMDDhhmmss') + '.png'
  } else {
    return
  }

  const url = URL
  const method = 'POST'

  let options = {
    method: method,
    headers: {
      'Content-Type': 'image/png',
      'Accept': '*/*',
    },
  }

  const image_bin = window.atob(image.substring(22))
  const image_buffer = new Uint8Array(image_bin.length)
  for (let i = 0; i < image_bin.length; i++) {
    image_buffer[i] = image_bin.charCodeAt(i);
  }

  try {
    options.body = new Blob([image_buffer.buffer], {type: 'image/png'})
    options.mode = 'cors'
    yield fetch(url, options)
  } catch (e) {
    console.log(e.toString())
  }

  console.log('イメージアップロード終了')

}

export function* findReserve(action) {
  yield put(actions.refreshSelectedReserve())

  let {searchType, value} = action.payload
  try {
    var {form: {reserveSearch: {values = {}}}} = yield select()
  } catch(e) {
    values = {}
  }

  // 予約情報を検索
  const searchResult = yield call(searchReserve, searchType, value || values[searchType])

  // 予約情報を検査
  if(searchResult.length === 0){
    yield put(actions.errorMessage('ご予約が見つかりませんでした\n入力内容をご確認ください'))
    yield put(actions.refreshReserve())
    return
  }
  const isDuplicateReserve = searchResult.some(item => searchResult[0].ReserveNo !== item.ReserveNo)
  if(isDuplicateReserve){
    yield put(actions.errorMessage('ご予約を特定できませんでした\n恐れ入りますが、他の方法で検索いただけますでしょうか'))
    yield put(actions.refreshReserve())
    return
  }

  // 予約詳細を取得
  const reservesWithDetail = yield call(searchReserveDetails, searchResult)

  // 結果確認のため再取得
  for (const reserve of reservesWithDetail) {
    if (isEmpty(reserve.detail)) {
      yield put(actions.errorMessage('ご予約を特定できませんでした\n恐れ入りますが、他の方法で検索いただけますでしょうか'))
      return
    }
  }

  // 支払額がマイナスはエラー
  const totalRoomCharge = reservesWithDetail.reduce((carry, reserve) => carry + (reserve.detail.RoomCharge ? parseInt(reserve.detail.RoomCharge, 10) : 0), 0)
  if (totalRoomCharge < 0) {
    yield put(push('/checkin'))
    yield put(actions.errorMessage('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
    yield put(receiptActions.printAlert([
      {label: 'ご宿泊者名', value: reservesWithDetail[0].ReservationGuestName + '　様'},
      {label: 'ご予約番号', value: reservesWithDetail[0].ReserveNo},
      {label: 'メッセージ', value: 'チェックイン Balance &lt; 0'},
    ]))
    return
  }

  // alert flg = Y
  const alertFlg = reservesWithDetail.find(item => item.AlertFlg === 'Y')
  if (alertFlg !== void 0) {
    // alertFlgが未定義ではない時
    yield put(push('/checkin'))
    yield put(actions.errorMessage('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
    yield put(receiptActions.printAlert([
      {label: 'ご宿泊者名', value: reservesWithDetail[0].ReservationGuestName + '　様'},
      {label: 'ご予約番号', value: reservesWithDetail[0].ReserveNo},
      {label: 'メッセージ', value: 'チェックイン MSGエラー'},
    ]))
    return
  }
　
  // CheckInProcessedFlg = Nのみ検索可能
  var filterd_reservesWithDetail = reservesWithDetail.filter(item => item.CheckInProcessedFlg === 'N')

  yield put(actions.updateSearchReserve(filterd_reservesWithDetail))

  if (filterd_reservesWithDetail.length === 0) {
    // チェックインされていない部屋が０の時
    yield put(actions.errorMessage('ご予約を特定できませんでした\n恐れ入りますが、他の方法で検索いただけますでしょうか'))
    return
  }

  // 部屋が一つの場合は部屋割当へ進む
  if (filterd_reservesWithDetail.length === 1) {
    yield put(actions.selectReserve(filterd_reservesWithDetail[0]))
    yield put(actions.searchRoom())
    return
  }

  // 複数部屋で事前決済済みであれば部屋割当へ進む
  // チェック対象は未チェックインの部屋だけ
  const filterdTotalRoomCharge = filterd_reservesWithDetail.reduce((carry, reserve) => carry + parseInt(reserve.detail.RoomCharge, 10), 0)
  if (filterdTotalRoomCharge == 0) {
    for(let item of filterd_reservesWithDetail) {
      yield put(actions.selectReserve(item))
    }
    yield put(actions.searchRoom())
    return
  }

  // 部屋が複数の場合は、複数部屋支払い方法選択画面へ
  yield put(push('/checkin/select_payment'))
}

function* searchReserve(searchType, value) {
  let URL = ''

  if(searchType === 'name') {
    URL = `${NODERED_URL}${GET_RESERVATION_URL}?SelectPtn=13&GuestName=${value}`
  }else if(searchType === 'reserve_num'){
    URL = `${NODERED_URL}${GET_RESERVATION_URL}?SelectPtn=14&ReferenceNo=${value}`
  }

  const payload = {
    url: URL,
    method: 'GET',
    successCB: actions.successSearchReserve
  }
  yield call(api, {payload})

  const {reserve: {searchResult}} = yield select()
  return searchResult
}

function* searchReserveDetails(searchResult) {
  yield all(searchResult.map((reserve, key) => {
    const URL = `${NODERED_URL}${GET_RESERVATION_DETAIL_URL}?RoomAccountID=${reserve.RoomAccountID}`
    const payload = {
      url: URL,
      method: 'GET',
      successCB: {cb: actions.successSearchReserveDetail, args: {index: key}},
      sleepTime: key*300,
    }
    return call(api, {payload})
  }))

  const {reserve: {searchResult: reservesWithDetail}} = yield select()
  return reservesWithDetail
}

function* searchRoom(action) {
  const {reserve: {selectedReserve}} = yield select()
  for (const reserve of selectedReserve){
    if(!reserve.detail.ReserveNo){
      yield put(push('/checkin'))
      yield put(actions.errorMessage('スタッフがご案内いたします\nご案内チケットをフロントカウンターまでお持ちください'))
      yield put(receiptActions.printAlert([
        {label: 'ご宿泊者名', value: reserve.ReservationGuestName + '　様'},
        {label: 'ご予約番号', value: reserve.ReserveNo},
        {label: 'メッセージ', value: 'チェックイン 宿泊者情報不足エラー'},
      ]))
      return
    }
  }

  yield all(selectedReserve.map((reserve, key) => {
    const URL = `${NODERED_URL}${GET_ROOM_URL}?SelectPtn=11&SmorkPtn=00&RoomAccountID=${reserve.RoomAccountID}`
    const payload = {
      url: URL,
      method: 'GET',
      successCB: {cb: actions.successSearchRoom, args: {index: key}},
      openLoadingFlag: 2,
      sleepTime: key*300,
    }
    return call(api, {payload})
  }))

  yield put(loadingAction.offContinue())

  // 結果確認のため再取得
  const {reserve: {selectedReserve: reservesWithRoom}} = yield select()
  for (const reserve of reservesWithRoom) {
    if (isEmpty(reserve.room)) {
      yield put(actions.errorMessage('アサイン可能な部屋がありません'))
      return
    }
  }
  yield put(push('/checkin/reconfirm'))
}

export function* searchMealCoupon(action) {
  const {reserve: {selectedReserve}} = yield select()
  const lang = i18n.language

  yield all(selectedReserve.map((reserve, key) => {
    const URL = `${NODERED_URL}${GET_MEAL_COUPON_URL}?RoomAccountID=${reserve.RoomAccountID}&RoomNo=${reserve.room.RoomNo}&SelectPtn=01&MealKbn=05&Seq=0&LanguageCode=${lang === 'ja' ? 'J' : 'E'}`
    const payload = {
      url: URL,
      method: 'GET',
      successCB: {cb: actions.successSearchMealCoupon, args: {index: key}},
      openLoadingFlag: 2,
      sleepTime: key*300,
    }
    return call(api, {payload})
  }))
  yield put(loadingAction.offContinue())
}

function* successSearchMealCoupon(action) {
}

function* updateRegistration(action) {
  const {
    form: {
      customerEditOption,
      customerEdit,
    },
    reserve: {
      selectedReserve
    }
  } = yield select()
  let code = ''
  if (customerEdit.values.country === 'kokunai') {
    code = isEmpty(customerEditOption) ? selectedReserve[0].detail.ZipCode : customerEditOption.values.ZipCode.replace(/[^\d]+/g, '')
  } else {
    code = isEmpty(customerEditOption) ? selectedReserve[0].detail.ZipCode : customerEditOption.values.ZipCode
  }

  let request = {
    RoomAccountID: selectedReserve[0].RoomAccountID,
    ReserveNo: selectedReserve[0].ReserveNo,
    CheckInDate: selectedReserve[0].CheckInDate.substring(0, 10),
    CheckOutDate: selectedReserve[0].CheckOutDate.substring(0, 10),
    RoomNights: selectedReserve[0].RoomNights,
    RoomCharge: selectedReserve[0].RoomCharge,
    MealKbn: selectedReserve[0].MealKbn,
    ClubNo: selectedReserve[0].ClubNo,
    MilageFlg: selectedReserve[0].MilageFlg,
    RateCoverFlg: selectedReserve[0].RateCoverFlg,
    AlertFlg: selectedReserve[0].AlertFlg,
    ReceiptDate: selectedReserve[0].detail.ReceiptDate.substring(0, 10),
    MealMaxCount: selectedReserve[0].detail.MealMaxCount,    
    ReceiptName: customerEdit.values.ReceiptName,
    GuestName: isEmpty(customerEditOption) ? selectedReserve[0].GuestName : customerEditOption.values.GuestName,
    CompanionName1: isEmpty(customerEditOption) ? '' : customerEditOption.values.with,
    ZipCode: code,
    Address: isEmpty(customerEditOption) ? selectedReserve[0].ReservationAddress : customerEditOption.values.ReservationAddress,
    Telephone: isEmpty(customerEditOption) ? selectedReserve[0].detail.Telephone : customerEditOption.values.Telephone,
    MailAddress: isEmpty(customerEditOption) ? selectedReserve[0].detail.MailAddress : customerEditOption.values.MailAddress,
    Person_M: isEmpty(customerEditOption) ? selectedReserve[0].detail.Person_M : customerEditOption.values.Person_M,
    Person_F: isEmpty(customerEditOption) ? selectedReserve[0].detail.Person_F : customerEditOption.values.Person_F,
    Person_C: isEmpty(customerEditOption) ? selectedReserve[0].detail.Person_C : customerEditOption.values.Person_C,
  }
  // Addressとして、値がない場合は全角スペースを登録するよう修正し、TAPに反映されるか確認する
  if (isEmpty(request.Address)) {
    request.Address = '記載なし'
  }

  const URL = `${NODERED_URL}${PUT_REGISTRATION_URL}`
  const payload = {
    url: URL,
    method: 'PUT',
    request: request,
    successCB: {cb: actions.successUpdateRegistration},
  }

  yield call(api, {payload})

}

function* successUpdateRegistration(action) {
  const {form} = yield select()

  if (form.customerEdit && form.customerEdit.values && form.customerEdit.values.country === 'kaigai') {
    yield put(push('/checkin/read_passport'))
    return
  }

  yield put(actions.checkIn())
}

function* checkIn(action) {
  const {reserve: {selectedReserve}} = yield select()

  yield all(selectedReserve.map((item, key) => {
    const request = {
      LateCheckOutFlag: 'N',
      RoomNo: item.room.RoomNo,
      RoomAccountID: item.RoomAccountID,
    }
    const URL = `${NODERED_URL}${POST_CHECK_IN_URL}`
    const payload = {
      url: URL,
      method: 'POST',
      request: request,
      successCB: {cb: actions.successCheckIn, args: {index: key}},
      openLoadingFlag: 2,
      sleepTime: key*3000,
    }
    return call(api, {payload})
  }))

  // 結果確認のため再取得
  const {reserve: {selectedReserve: reservesWithCheckIn}} = yield select()
  for (const reserve of reservesWithCheckIn) {
    if (isEmpty(reserve.checkIn)) {
      yield put(loadingAction.offContinue())
      yield put(actions.errorMessage('チェックインできませんでした'))
      yield put(receiptActions.printAlert([
        {label: 'ご宿泊者名', value: reserve.ReservationGuestName + '　様'},
        {label: 'ご予約番号', value: reserve.ReserveNo},
        {label: 'メッセージ', value: 'チェックイン チェックイン失敗'},
      ]))
      return
    }
  }
  
  yield put(actions.updateReserve())
}

function* successCheckIn(action) {
}

function* updateReserve(action) {
  const {reserve: {
    selectedReserve
  }} = yield select()

  if (selectedReserve.length <= 0) {
    return
  }
  const selected = selectedReserve[0]

  const request = {
    RoomNo: selected.room.RoomNo,
    RoomAccountID: selected.RoomAccountID,
    ModifyStayData_DataList: selectedReserve.map(reserve => ({
      FieldID: '30002',
      ModifyData: reserve.RoomAccountID
    })),
  }
  let URL = `${NODERED_URL}${PUT_RESERVATION_URL}`
  const payload = {
    url: URL,
    method: 'PUT',
    request,
    successCB: actions.successUpdateReserve
  }
  yield put(MiddlewareAction.api(payload))
}

function* successUpdateReserve(action) {
  console.log(action.payload)
  yield put(paymentAction.getBalance())
}

function* errorMessage(action) {
  // メッセージは通常通り
  yield put(errorAction.displayError(action.payload))

  try {
    const {router: {location: {pathname}}} = yield select()
    if (pathname !== '/checkin/qr') {
      return;
    }
  } catch ($e) {
    return;
  }
  console.log('wait close')
  // QR読み込み画面からであればCloseを待つ
  yield take(errorAction.close)
  console.log('on close')

  const {router: {location: {pathname: afterPathname}}} = yield select()
  if (afterPathname !== '/checkin/qr') {
    return;
  }
  // QR読み込み画面からであればQrを再開
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qr) {
    window.webkit.messageHandlers.qr.postMessage('start')
  }
}
function* hasRequiredItem(action) {
  const {
    reserve: {selectedReserve},
    form: {customerEditOption, customerEdit}
  } = yield select()
  if (customerEdit.values.country === 'kokunai' && selectedReserve[0].detail.ZipCode.length === 0) {
    if (!customerEditOption || customerEditOption.values.ZipCode.length === 0 ) {
      yield put(push('/checkin/edit/option'))
      yield put(actions.errorMessage('居住地確認のため、郵便番号をご入力ください'))
      return;
    }
  }
  if (customerEditOption && customerEditOption.values.ZipCode.replace(/[-]+/g, '').length > 7) {
    console.log(customerEditOption.values.ZipCode)
    console.log(customerEditOption.values.ZipCode.replace(/[-]+/g, '').length)
    yield put(push('/checkin/edit/option'))
    yield put(actions.errorMessage('郵便番号は7桁以下でご入力ください'))
    return;
  }
  if (customerEditOption && customerEditOption.values.ZipCode.match(/[^-\d]+/g)) {
    yield put(push('/checkin/edit/option'))
    yield put(actions.errorMessage('郵便番号は数字で入力してください'))
    return;
  }
  yield put(actions.updateRegistration())
}
