import { createActions, handleActions } from 'redux-actions'
import i18n from 'i18next'
import { select, call, takeEvery, all, put } from 'redux-saga/effects'
import {print} from './middleware'
import {RECEIPT_IMAGE_LOGO, RECEIPT_IMAGE_LOGO_EN, RECEIPT_IMAGE_MINI_LOGO_EN} from '../constants/receipt_image_logo'
import {RECEIPT_IMAGE_STAMP} from '../constants/receipt_image_stamp'
import {FACILITY_INITIAL} from '../constants/settings'
import {isEmpty, _formatYMD_UTC, _formatYMDHM_UTC, _formatYMDHM} from '../util/common'
import moment from 'moment'
import rollbar from './rollbar'
import {actions as errorAction} from './error'

/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    printReceipt: payload => payload,
    printTicket: payload => payload,
    printRoomNo: payload => payload,
    printAlert: payload => payload,
    printCash: payload => payload,
    printCreditReport: (type, result) => ({type, result}),
  },
  { prefix: 'hoshino/auth'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
}
const reducer = handleActions({
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* receiptSaga() {
  yield takeEvery(actions.printReceipt, printReceipt)
  yield takeEvery(actions.printTicket, printTicket)
  yield takeEvery(actions.printRoomNo, printRoomNo)
  yield takeEvery(actions.printAlert, printAlert)
  yield takeEvery(actions.printCash, printCash)
  yield takeEvery(actions.printCreditReport, printCreditReport)
}

export function* printReceipt(action) {
  const { reserve } = action.payload
  const {
    reserve: {
      selectedReserve,
    },
    form: {
      customerEdit: {
        values: {
          ReceiptName
        }
      }
    }
  } = yield select()

  let receiptAmount = 0
  let receiptTax = 0
  let amount = 0
  selectedReserve.forEach(item => {
    receiptAmount += Number(item.balance.ReceptAmt)
    receiptTax += Number(item.balance.Tax)
    amount += Number(item.balance.Balance)
  })
 
  let lang = i18n.language
  let body = ''
  if (receiptAmount === 0 || selectedReserve[0].RoomAccountID !== reserve.RoomAccountID) {
    // 部屋番号
    if (lang === 'ja') {
      body = getRoomNoBodyJa(reserve)
    } else {
      body = getRoomNoBodyEn(reserve)
    }
  } else {
    // 領収書のみ
    if (lang === 'ja') {
      body = getReceiptBodyJa(reserve, ReceiptName, receiptAmount, receiptTax, reserve.credit)
    } else {
      body = getReceiptBodyEn(reserve, ReceiptName, receiptAmount, receiptTax, reserve.credit)
    }
  }
  const payload = {
    body
  }

  return yield call(print, {payload})
}

function getReceiptBodyJa(selectedReserve, receiptName, receiptAmount, receiptTax, credit)
{
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO}
  <text>--------------------------------------------</text><feed/>
  <text width="2" height="2" linespc="66"/>
  <text>お部屋番号    ${selectedReserve.room.RoomNo}</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <text>--------------------------------------------</text><feed/>
  <text align="left" />
  <text x="20">お名前</text><text x="200">:</text><text x="250">${selectedReserve.detail.GuestName}　様</text><feed/>
  <text x="20">ご人数</text><text x="200">:</text><text x="250">${selectedReserve.detail.Person_M + selectedReserve.detail.Person_F + selectedReserve.detail.Person_C}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text x="20">宿泊期間</text><text x="200">:</text><text x="250">${_formatYMD_UTC(selectedReserve.detail.CheckInDate)} - ${_formatYMD_UTC(selectedReserve.detail.CheckOutDate)}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text width="2" height="2" linespc="66"/>
  <text>領  収  書</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <text>${receiptName}  様</text><feed/>
  <text align="left" em="1"/>
  <text x="40">領収金額</text>
  <text x="400">¥${new Number(receiptAmount).toLocaleString()}</text>
  <feed/>
  <text align="center" em="0"/>
  <text>--------------------------------------------</text><feed/>
  <text>(内消費税 ¥${new Number(receiptTax).toLocaleString()})</text><feed/>
  <text>クレジットカードにて領収いたしました。</text><feed/>
  <feed/>
  <text align="left"/>
  <text x="20">〒300-0035 茨城県土浦市有明町1-30</text><feed/>
  <text x="20">プレイアトレ土浦3階 星野リゾート BEB5土浦</text><feed/>
  <feed/>
  <text align="left" font="font_a"/>
  <text x="20">${credit ? ('取引番号:' + credit.humanReadableIdentifier) : ''}</text><text x="435">${moment().format('YYYY/MM/DD')}</text><feed/>
  <text align="center"/>
  <text>--------------------------------------------</text><feed/>
  `

  if (credit) {
    // クレジット印字
    body += `
    <text>ｸﾚｼﾞｯﾄｶｰﾄﾞご利用明細</text><feed/>
    <text align="left" />
    <text x="20">　　　ご利用日　:　${credit.date}</text><feed/>
    <text x="20">　　カード番号　:　${credit.cardSuffix}</text><feed/>
    <text x="20">　　お支払方法　:　一括</text><feed/>
    <text x="20">　　　承認番号　:　${credit.approvalCode}</text><feed/>
    <text x="20">　　　伝票番号　:　${credit.humanReadableIdentifier}</text><feed/>
    <text x="20">　　ご利用金額　:　¥${new Intl.NumberFormat('ja-JP').format(credit.amount)}</text><feed/>
    <text align="center"/>
    <text>--------------------------------------------</text><feed/>
    `
  }

  return body
}

function getReceiptBodyEn(selectedReserve, receiptName, receiptAmount, receiptTax, credit)
{
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO_EN}
  <text>--------------------------------------------</text><feed/>
  <text width="2" height="2" linespc="66"/>
  <text>Room No.  ${selectedReserve.room.RoomNo}</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <text>--------------------------------------------</text><feed/>
  <text align="left" />
  <text x="20">MR./MS.</text><text x="200">:</text><text x="250">${selectedReserve.detail.GuestName}</text><feed/>
  <text x="20">PAX</text><text x="200">:</text><text x="250">${selectedReserve.detail.Person_M + selectedReserve.detail.Person_F + selectedReserve.detail.Person_C}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text x="20">PERIOD</text><text x="200">:</text><text x="250">${_formatYMD_UTC(selectedReserve.detail.CheckInDate)} - ${_formatYMD_UTC(selectedReserve.detail.CheckOutDate)}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text width="2" height="2" linespc="66"/>
  <text>RECEIPT</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <text>MR./MS.  ${receiptName}</text><feed/>
  <text align="left" em="1"/>
  <text x="40">AMOUNT DUE</text>
  <text x="400">¥${new Number(receiptAmount).toLocaleString()}</text>
  <feed/>
  <text align="center" em="0"/>
  <text>--------------------------------------------</text><feed/>
  <text>(Consumption tax ¥${new Number(receiptTax).toLocaleString()})</text><feed/>
  <text>Paid by credit card.</text><feed/>
  <feed/>
  <text align="left"/>
  <text x="20">BEB5Tsuchiura</text><feed/>
  <text x="20">Play atre Tsuchiura 3F, 1-30 Ariakecho,</text><feed/>
  <text x="20">Tsuchiura city, Ibaraki prefecture,</text><feed/>
  <text x="20">300-0035, Japan</text><feed/>
  <feed/>
  <text align="left" font="font_a"/>
  <text x="20">${credit ? ('Trx#:' + credit.humanReadableIdentifier) : ''}</text><text x="435">${moment().format('YYYY/MM/DD')}</text><feed/>
  <text align="center"/>
  <text>--------------------------------------------</text><feed/>
  `

  if (credit) {
    // クレジット印字
    body += `
    <text>Credit Card Statement</text><feed/>
    <text align="left" />
    <text x="20">        Date　:　${credit.date}</text><feed/>
    <text x="20">       Card#　:　${credit.cardSuffix}</text><feed/>
    <text x="20">Entry Method　:　Lump Sum Payment</text><feed/>
    <text x="20">        Auth　:　${credit.approvalCode}</text><feed/>
    <text x="20">        Slip　:　${credit.humanReadableIdentifier}</text><feed/>
    <text x="20">      Amount　:　¥${new Intl.NumberFormat('ja-JP').format(credit.amount)}</text><feed/>
    <text align="center"/>
    <text>--------------------------------------------</text><feed/>
    `
  }

  return body
}

export function* printTicket(action) {
  const {reserve} = action.payload
  if (isEmpty(reserve.coupon)) {
    rollbar.debug('receipt.printTicket 朝食券なし')
    return
  }

  for (var i = 0; i<reserve.coupon.length; i++) {
    const coupon = reserve.coupon[i]
    rollbar.debug('receipt.printTicket 朝食券印刷', coupon)
    const shops = Object.entries(coupon).map(([key, value]) => {
      if (key.indexOf('Remarks_') !== 0 || isEmpty(value)) {
        return ''
      }
      return `<text x="35">■${value}</text><feed/>`
    }).join()

    yield all([...Array(coupon.Count)].map(() => {

      const body = `
    <text align="center" lang="ja" linespc="36"/>
    <page>
    <area x="20" y="0" width="800" height="140"/>
    <position x="50" y="120"/>
    ${RECEIPT_IMAGE_MINI_LOGO_EN}
    <position x="260" y="80"/>
    <text width="2" height="2"/>
    <text>ご朝食券</text>
    <position x="264" y="110"/>
    <text width="1" height="1"/>
    <text>BREAKFAST TICKET</text>
    </page>
    <text align="center"/>
    <text>--------------------------------------------</text><feed/>
    <feed unit="6"/>
    <text align="left"/>
    <text x="20">お部屋番号(Room Number)</text><text x="300">: ${reserve.room.RoomNo}</text><feed/>
    <text x="20">利用日(Valid Data)</text><text x="300">: ${_formatYMD_UTC(coupon.UseDate)}</text><feed/>
    <text align="center"/>
    <feed unit="6"/>
    <text>--------------------------------------------</text><feed/>
    <feed unit="6"/>
    <text align="left"/>
    <text x="20">●利用可能店舗</text><feed/>
    <text x="50">Redeem your ticket at the following shop.</text><feed/>
    ${shops}
    <feed/>   
    <text x="20">●利用時間は午前7:00〜午前11:00です。</text><feed/> 
    <text x="50">Breakfast is available from 7:00 am </text><feed/>
    <text x="50">to 11:00 am.</text><feed/>
    <text x="20">※7時より前の喫食をご希望の方は前日19:30までに</text><feed/>
    <text x="50">フロントスタッフにお問い合わせください。</text><feed/> 
    <feed/> 
    <text x="20">●朝食の内容についてはフロントの案内を</text><feed/>
    <text x="20">　ご覧ください。</text><feed/>
    <text x="50">Please check the content of breakfast</text><feed/>
    <text x="50">at the front desk.</text><feed/>
    <feed/>
    <text x="20">●この朝食券は各店舗のスタッフにお渡し下さい。</text><feed/>
    <text x="50">Kindly present this ticket to the staff</text><feed/>
    <text x="50">at each shop.</text><feed/>
    <feed/>
    <text x="20">●本券は再発行致しかねます。</text><feed/>
    <text x="50">This ticket can not be reissued.</text><feed/>
    <feed/>
    <text x="20">●本券は金券ではございません。</text><feed/>
    <text x="50">No monetary value.</text><feed/>
    <feed/>
    `
  
      const payload = {
        body
      }
  
      return call(print, {payload})
    }))
  }
}

function* printRoomNo() {
  const {
    reserve: {selectedReserve},
  } = yield select()

  let lang = i18n.language

  yield all(selectedReserve.map(reserve => {
    let body = ''
    if (lang === 'ja') {
      body = getRoomNoBodyJa(reserve)
    } else {
      body = getRoomNoBodyEn(reserve)
    }
  
    const payload = {
      body
    }
  
    return call(print, {payload})
  }))
}

function getRoomNoBodyJa(selectedReserve) {
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO}
  <feed/>
  <text width="2" height="2" linespc="66"/>
  <text>お部屋番号    ${selectedReserve.room.RoomNo}</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <feed/>
  <text align="left" />
  <text x="20">お名前</text><text x="200">:</text><text x="250">${selectedReserve.detail.GuestName}　様</text><feed/>
  <text x="20">ご人数</text><text x="200">:</text><text x="250">${selectedReserve.detail.Person_M + selectedReserve.detail.Person_F + selectedReserve.detail.Person_C}</text><feed/>
  <text x="20">ご利用期間</text><text x="200">:</text><text x="250">${_formatYMD_UTC(selectedReserve.detail.CheckInDate)} - ${_formatYMD_UTC(selectedReserve.detail.CheckOutDate)}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <feed/>
  <text align="center" />
  <text>星野リゾートBEB5土浦を</text><feed/>
  <text>ご利用頂き誠にありがとうございます</text><feed/>
  <text>ごゆっくりおくつろぎ下さいませ</text><feed/>
  <feed/>
  <text align="center"/>
  <text>--------------------------------------------</text><feed/>
  `

  return body
}

function getRoomNoBodyEn(selectedReserve) {
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO_EN}
  <feed/>
  <text width="2" height="2" linespc="66"/>
  <text>Room No.    ${selectedReserve.room.RoomNo}</text><feed/>
  <text width="1" height="1" linespc="36"/>
  <feed/>
  <text align="left" />
  <text x="20">MR./MS.</text><text x="200">:</text><text x="250">${selectedReserve.detail.GuestName}</text><feed/>
  <text x="20">PAX</text><text x="200">:</text><text x="250">${selectedReserve.detail.Person_M + selectedReserve.detail.Person_F + selectedReserve.detail.Person_C}</text><feed/>
  <text x="20">PERIOD</text><text x="200">:</text><text x="250">${_formatYMD_UTC(selectedReserve.detail.CheckInDate)} - ${_formatYMD_UTC(selectedReserve.detail.CheckOutDate)}</text><feed/>
  <feed line="1" linespc="10"/><text linespc="36"/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <feed/>
  <text align="center" />
  <text>Welcome to Hoshino Resorts BEB5 Tsutchiura</text><feed/>
  <text>We are delighted to have you with us</text><feed/>
  <text>and hope you will enjoy your stay.</text><feed/>
  <feed/>
  <text align="center"/>
  <text>--------------------------------------------</text><feed/>
  `

  return body
}

export function* printCSinfomation(ReserveNo, RoomNo) {

  let lang = i18n.language

  var l = 4
  var c = 'abcdefghijklmnopqrstuvwxyz0123456789'
  var cl = c.length
  var randomString = ''
  for(var i=0; i<l; i++){
    randomString += c[Math.floor(Math.random()*cl)]
  }

  let body = ''
  if (lang === 'ja') {
    body = getCSinformationBodyJa(ReserveNo, RoomNo, randomString)
  } else {
    body = getCSinformationBodyEn(ReserveNo, RoomNo, randomString)
  }
  
  const payload = {
    body
  }
  
  return yield call(print, {payload})
}

function getCSinformationBodyJa(ReserveNo, RoomNo, randomString) {
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO}
  <feed/>
  <feed unit="6"/>
  <text>--------------------------------------------</text><feed/>
  <feed unit="6"/>
  <text align="left"/>
  <text x="20">ご利用いただき、誠にありがとうございました</text><feed/>
  <text x="20">滞在のご感想をお聞かせください！</text><feed/>
  <feed/>
  <symbol type="qrcode_model_2" level="default" width="6" height="6" size="0" align="center">https://gokanso.jp/thanks/index.cfm?id=${randomString + ReserveNo + FACILITY_INITIAL + RoomNo + randomString}&lang=ja</symbol>
  <feed/>
  <text align="left"/>
  <text x="20">URL:https://gokanso.jp/</text><feed/>
  <text x="20">Password:${ReserveNo + FACILITY_INITIAL + RoomNo}</text><feed/>
  <text x="20">※QRコードからアクセスいただく場合は</text><feed/>
  <text x="20">パスワードは不要です</text><feed/>
  <text x="20">※PCからアクセスいただく場合はURLに</text><feed/>
  <text x="20">アクセスの上、パスワードを入力ください。</text><feed/>
  <feed/>
  <feed unit="6"/>
  <text x="20">お客様からのご意見・感想をお待ち</text><feed/>
  <text x="20">申し上げるとともに、またのお越しを</text><feed/>
  <text x="20">心よりお待ち申し上げております</text><feed/>
  <feed/>
  <feed unit="6"/>
  <text x="20">BEB5土浦 総支配人</text><feed/>
  <feed/>
  <text>--------------------------------------------</text><feed/>
  `

  return body
}

function getCSinformationBodyEn(ReserveNo, RoomNo, randomString) {
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO_EN}
  <feed/>
  <feed unit="6"/>
  <text>--------------------------------------------</text><feed/>
  <feed unit="6"/>
  <text align="left"/>
  <text x="20">We hope you enjoyed your stay</text><feed/>
  <text x="20">Please provide your comments</text><feed/>
  <text x="20">to help make your future stay</text><feed/>
  <text x="20">with us even better!</text><feed/>
  <feed/>
  <symbol type="qrcode_model_2" level="default" width="6" height="6" size="0" align="center">https://gokanso.jp/thanks/index.cfm?id=${randomString + ReserveNo + FACILITY_INITIAL + RoomNo + randomString}&lang=en</symbol>
  <feed/>
  <text align="left"/>
  <text x="20">URL:https://gokanso.jp/</text><feed/>
  <text x="20">Password:${ReserveNo + FACILITY_INITIAL + RoomNo}</text><feed/>
  <text x="20">※When accessing from PC, input the URL</text><feed/>
  <text x="20">followed by the Password indicated above.</text><feed/>
  <text x="20">※For guests who have provided their</text><feed/>
  <text x="20">email address, an email notification</text><feed/>
  <text x="20">will be sent as well.</text><feed/>
  <feed/>
  <text x="20">Thank you for choosing to be our guest</text><feed/>
  <text x="20">We thank you for completing the survey.</text><feed/>
  <text x="20">Best Wishes,</text><feed/>
  <feed/>
  <feed unit="6"/>
  <text x="20">General Manager BEB5Tsuchiura</text><feed/>
  <feed/>
  <text>--------------------------------------------</text><feed/>
  `

  return body
}


function* printAlert(action) {
  const messages = action.payload
  let body = alertReceipt(messages)

  const payload = {
    body
  }

  return yield call(print, {payload})
}


function alertReceipt(messages){
  const message_xml = messages.map(message => `<text x="20">${message.label}</text><text x="200">:</text><text x="250">${message.value}</text><feed/>`)

  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO}
  <feed/>
  <text align="center" />
  <text width="2" height="2"/>
  <text>ご案内レシート</text><feed/>
  <text width="1" />
  <text>Kiosk AcKnowledgement Slip</text><feed/>
  <text height="1" />
  <feed/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text align="left" font="font_a"/>
  <text x="40">フロントカウンターにて対応致します。</text><feed/>
  <text x="40">レシートをスタッフにお渡しください。</text><feed/>
  <text x="40">We will respond at the front counter.</text><feed/>
  <text x="40">Please hand the receipt to the staff.</text><feed/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text align="left" />
  ${message_xml}
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  `
  return body
}

function* printCash() {
  const {reserve: {selectedReserve}} = yield select()

  let receiptAmount = 0
  selectedReserve.forEach(item =>{
    receiptAmount += Number(item.balance.Balance)
  })

  let body = cashReceipt(selectedReserve, receiptAmount)

  const payload = {
    body
  }
  return yield call(print, {payload})
}

function cashReceipt(selectedReserve, receiptAmount){
  let body = `
  <text align="center" lang="ja" linespc="36"/>
  ${RECEIPT_IMAGE_LOGO}
  <feed/>
  <text align="center" />
  <text width="2" height="2"/>
  <text>ご案内レシート</text><feed/>
  <text width="1" height="2" />
  <text>Kiosk AcKnowledgement Slip</text><feed/>
  <text height="1" />
  <feed/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text align="left" font="font_a"/>
  <text x="40">フロントにて現金決済を承ります。</text><feed/>
  <text x="40">本レシートをスタッフにお渡しください。</text><feed/>
  <text x="40">Payment by cash is completed at the</text><feed/>
  <text x="40">front desk. </text><feed/>
  <text x="40">Please hand the invoice to our staff. </text><feed/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  <text align="left" />
  <text x="20">ご宿泊者名</text><text x="200">:</text><text x="250">${selectedReserve[0].GuestName}　様</text><feed/>
  <text x="20">ご予約番号</text><text x="200">:</text><text x="250">${selectedReserve[0].ReserveNo}　</text><feed/>
  <text x="20">お部屋番号</text><text x="200">:</text><text x="250">${selectedReserve[0].room.RoomNo}</text><feed/>
  <text x="20">ご請求金額</text><text x="200">:</text><text x="250">¥${new Number(receiptAmount).toLocaleString()}</text><feed/>
  <text align="center" />
  <text>--------------------------------------------</text><feed/>
  `
  return body
}

// CSV の印刷時の表示に合わせて加工するため、keyWords配列の要素と一致するかを調べる
function matchInspection(cols, index) {
  let keyWords = ['売上小計', '取消小計', '合計', '売上合計', '取消合計', '総合計']
  for (let i in keyWords) {
    for (let j in cols) {
      if (keyWords[i] == cols[j] || index == 1) {
        return true
      }
    }
  }
  return false
}

function* printCreditReport(action) {
  try {
    const {
      type,
      result: {
        csv = ''
      }
    } = action.payload

    ///// デバッグ印字
    // const debug_rows = csv.split('\r\n').map((row) => row.replace(/</g, '&lt;').replace(/>/g, '&gt;'))
    // const debug_print = debug_rows.map((row) => `<text>${row}_</text>`).join('<feed/>')
    // yield call(print, {
    //   payload: {
    //     body: `<text align="left" lang="ja" linespc="36"/><text>デバッグ出力</text><feed/><feed/>${debug_print}<feed/>`
    //   }
    // })
    ////////////////

    // CSV分離 印刷行取得
    const rows = csv.replace(/\r/g, '').split('\n').map((row) => row.replace(/</g, '&lt;').replace(/>/g, '&gt;').split(','))

    let print_rows = [];
    if (type === 'credit') {
      print_rows = makeCreditReceiptRows(rows)
    } else if (type === 'ginren') {
      print_rows = makeGinrenReceiptRows(rows)
    }

    const print_text = print_rows.join('<feed/>')

    const body = `
      <text align="left" lang="ja" linespc="36"/>
      ${print_text}
      <feed/>
    `

    const payload = {
      body
    }
    return yield call(print, {payload})
  } catch (e) {
    yield put(errorAction.displayError(e.toString()))
  }
}

function makeCreditReceiptRows(rows)
{
  let print_rows = [
    '<text align="left">************************************************</text>',
    '<text align="center">クレジット日計</text>',
    '<text align="left">************************************************</text>',
    '',
    `<text>集計日：${rows[0][0]}</text>`,
    `<text>端末番号：${rows[0][1]}</text>`,
    '',
  ]
  let row_num = 1

  // 明細行をカード会社でグルーピング
  let groups = {}
  for (let detail = rows[row_num]; detail && detail.length === 12; detail = rows[++row_num]) {
    const card_co = detail[0]
    const date = detail[3]

    if (!(card_co in groups)) {
      groups[card_co] = {
        detail: {},
        sales_total: [],
        cancel_total: [],
        total: [],
      }
    }

    if (!(date in groups[card_co].detail)) {
      groups[card_co].detail[date] = []
    }

    groups[card_co].detail[date].push(detail)
  }

  // 小計行をカード会社でグルーピング
  let label = ''
  for (let subtotal = rows[row_num]; subtotal && subtotal[0] !== '売上合計'; subtotal = rows[++row_num]) {
    if (subtotal.length === 2) {
      label = subtotal[1]
      continue
    }

    const card_co = subtotal[0]

    if (label === '売上小計') {
      groups[card_co].sales_total.push(subtotal)
    } else if (label === '取消小計') {
      groups[card_co].cancel_total.push(subtotal)
    } else if (label === '合計') {
      groups[card_co].total.push(subtotal)
    }
  }

  // カード会社別印字
  for (let card_co in groups) {
    print_rows.push('')
    print_rows.push(`<text>&lt;${card_co}&gt;</text>`)
    print_rows.push('<text>時刻        処理番号    伝票番号    承認番号</text>')
    print_rows.push('<text>            会員番号                金額</text>')
    print_rows.push('<text>------------------------------------------------</text>')

    for (let date in groups[card_co].detail) {
      print_rows.push(`<text>${date}</text>`)
      print_rows.push('<text>------------------------------------------------</text>')

      groups[card_co].detail[date].forEach(detail => {
        const time = detail[4]
        const seq = detail[6]
        const slip_no = detail[7]
        const apploval_no = detail[8]
        const card_no = detail[9]
        const amount = detail[11]
    
        print_rows.push(`<text align="left">${time}    ${seq}     ${slip_no}       ${apploval_no}</text>`)
        print_rows.push(`<text align="left">            ${(card_no + '          ').slice(0, 20)}    ¥${Number(amount).toLocaleString()}</text>`)
      })
    }

    print_rows.push('')
    print_rows.push('<text align="left">&lt;売上小計&gt;   件数   金額</text>')
    print_rows.push('<text>------------------------------------------------</text>')
    groups[card_co].sales_total.forEach(total => {
      const method = total[1]
      const count = total[2]
      const amount = total[3]

      print_rows.push(`<text align="left">      ${method}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
    })

    print_rows.push('')
    print_rows.push('<text align="left">&lt;取消小計&gt;   件数   金額</text>')
    print_rows.push('<text>------------------------------------------------</text>')
    groups[card_co].cancel_total.forEach(total => {
      const method = total[1]
      const count = total[2]
      const amount = total[3]

      print_rows.push(`<text align="left">      ${method}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
    })

    print_rows.push('')
    print_rows.push('<text align="left">&lt;合計&gt;       件数   金額</text>')
    print_rows.push('<text>------------------------------------------------</text>')
    groups[card_co].total.forEach(total => {
      const method = total[1]
      const count = total[2]
      const amount = total[3]

      print_rows.push(`<text align="left">      ${method}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
    })

    print_rows.push('<text align="left">************************************************</text>')
    print_rows.push('')
  }

  print_rows.push('')

  // 売上合計
  for (let total = rows[row_num]; total && total[0] !== '取消合計'; total = rows[++row_num]) {
    const label = total[0]

    if (total.length === 1) {
      print_rows.push('<text align="left">&lt;売上合計&gt;   件数   金額</text>')
      print_rows.push('<text>------------------------------------------------</text>')
      continue
    }

    const count = total[1]
    const amount = total[2]

    print_rows.push(`<text align="left">      ${label}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
  }

  // 取消合計
  for (let total = rows[row_num]; total && total[0] !== '総合計'; total = rows[++row_num]) {
    const label = total[0]

    if (total.length === 1) {
      print_rows.push('<text align="left">&lt;取消合計&gt;   件数   金額</text>')
      print_rows.push('<text>------------------------------------------------</text>')
      continue
    }

    const count = total[1]
    const amount = total[2]

    print_rows.push(`<text align="left">      ${label}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
  }

  // 総合計
  for (row_num; row_num < rows.length; row_num++) {
    const total = rows[row_num]
    const label = total[0]

    if (total.length === 1) {
      print_rows.push('<text align="left">&lt;総合計&gt;     件数   金額</text>')
      print_rows.push('<text>------------------------------------------------</text>')
      continue
    }

    const count = total[1]
    const amount = total[2]

    print_rows.push(`<text align="left">      ${label}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
  }

  print_rows.push('<text align="left">************************************************</text>')
  print_rows.push('')

  return print_rows
}

function makeGinrenReceiptRows(rows)
{
  let print_rows = [
    '<text align="left">************************************************</text>',
    '<text align="center">銀聯日計</text>',
    '<text align="left">************************************************</text>',
    '',
    `<text>集計日：${rows[0][0]}</text>`,
    `<text>端末番号：${rows[0][1]}</text>`,
    '',
  ]
  let row_num = 1

  // 明細行をカード会社でグルーピング
  let groups = {}
  for (let detail = rows[row_num]; detail && detail.length === 10; detail = rows[++row_num]) {
    const card_co = detail[0]
    const date = detail[2]

    if (!(card_co in groups)) {
      groups[card_co] = {
        detail: {},
        sales_total: [],
        cancel_total: [],
        total: [],
      }
    }

    if (!(date in groups[card_co].detail)) {
      groups[card_co].detail[date] = []
    }

    groups[card_co].detail[date].push(detail)
  }

  // カード会社別印字
  for (let card_co in groups) {
    print_rows.push('')
    print_rows.push(`<text>&lt;${card_co}&gt;</text>`)
    print_rows.push('<text>時刻        処理番号    伝票番号    承認番号</text>')
    print_rows.push('<text>            会員番号                金額</text>')
    print_rows.push('<text>------------------------------------------------</text>')

    for (let date in groups[card_co].detail) {
      print_rows.push(`<text>${date}</text>`)
      print_rows.push('<text>------------------------------------------------</text>')

      groups[card_co].detail[date].forEach(detail => {
        const time = detail[3]
        const seq = detail[5]
        const slip_no = detail[6]
        const apploval_no = detail[7]
        const card_no = detail[8]
        const amount = detail[9]
    
        print_rows.push(`<text align="left">${time}    ${seq}     ${slip_no}       ${apploval_no}</text>`)
        print_rows.push(`<text align="left">            ${(card_no + '          ').slice(0, 20)}    ¥${Number(amount).toLocaleString()}</text>`)
      })
    }

    print_rows.push('<text align="left">************************************************</text>')
    print_rows.push('')
  }

  print_rows.push('')

  // 総合計
  for (row_num; row_num < rows.length; row_num++) {
    const total = rows[row_num]
    const label = total[0]

    if (total.length === 1) {
      print_rows.push('<text align="left">&lt;総合計&gt;     件数   金額</text>')
      print_rows.push('<text>------------------------------------------------</text>')
      continue
    }

    const count = total[1]
    const amount = total[2]

    print_rows.push(`<text align="left">      ${label}   ${(count + '      ').slice(0, 6)}  ¥${Number(amount).toLocaleString()}</text>`)
  }

  print_rows.push('<text align="left">************************************************</text>')
  print_rows.push('')

  return print_rows
}
