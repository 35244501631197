import React, {Component} from 'react'
import {SUB_COLOR, ACC_COLOR, LABEL_BG_COLOR} from '../../constants/common'

class Button extends Component {
  render() {
    const {style, btnColor, btnLabel, fontSize, btnHeight, btnFloat, marginTop, btnWidth, btnImage, disabled, bottom, right, OnClick=()=>{}} = this.props
    
    //disabledの時にボタンを非活性にする
    let backgroundColor = ''
    if (btnColor) {
      backgroundColor = btnColor
    } else {
      if (disabled === true) {
        backgroundColor = LABEL_BG_COLOR
      } else {
        backgroundColor = SUB_COLOR
      }
    }
    
    let btnboxShadow = ''
    if (btnColor) {
      btnboxShadow = btnColor
    } else {
      if (disabled === true) {
        btnboxShadow = '0px 4px ' + LABEL_BG_COLOR
      } else {
        btnboxShadow =  '0px 4px ' + ACC_COLOR
      }
    }

    const button = {
      backgroundColor:backgroundColor,
      borderRadius:'0.4vw',
      boxShadow:btnboxShadow,
      color:'#fff',
      fontSize:fontSize?'2rem':'1.5rem',
      float:btnFloat?btnFloat:'right',
      height:btnHeight?btnHeight:'4.5vw',
      lineHeight:btnHeight?btnHeight:'4.5vw',
      marginTop:marginTop?marginTop:'',
      textAlign:'center',
      width:btnWidth?btnWidth:'12vw',
      position: 'fixed',
      bottom: bottom?bottom:'5vh',
      right: right?right:'9.5vw',
      ...style
    }

    return (
      <div style={button} onClick={()=>disabled ? '':OnClick()} disabled={disabled}><img src={btnImage}></img>{btnLabel}</div>
    );
  }
}
Button.propTypes = {
}

export default Button
