import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, Row, Col, Card, CardBody, ModalHeader, Spinner} from 'reactstrap'
import styled from 'styled-components'
import {actions as errorAction} from '../../../modules/error'
import {isEmpty} from '../../../util/common'
import {FaArrowAltCircleRight, FaBlackTie} from 'react-icons/fa'
import {push} from 'connected-react-router'
import { ok } from 'assert'
import { withTranslation } from 'react-i18next'
import Btn from '../../../components/atomics/Button'

/**
 * 銀聯支払い中モーダル
 */
class PayGinrenSequence extends Component {
  
  render() {
    const {
      sequence,
      t,
      i18n
    } = this.props

    return (
      <section id="pay_credit_sequence">
        <PayModal
          backdrop="static"
          isOpen={true}
          size="lg"
          className="modal-dialog-centered"
        >
          <PayModalHeader className={[i18n.language]}>
            {t('銀聯支払い')}
          </PayModalHeader>
          {sequence === 'read' && (
            <PayModalBody>
              <Row>
                <Col xs={6}>
                  <div style={{textAlign: 'center'}}>
                    <img src="https://dfc78s572732n.cloudfront.net/animation/vega.gif" alt="" height="350"/>
                  </div>
                </Col>
                <Col xs={6}>
                  <div style={{textAlign: 'center', fontSize: '1.6rem', fontWeight: 'bold', margin: '15vh 5vh 0 0'}}>
                    {t('カードを入れてください')}
                  </div>
                  <div style={{ textAlign: 'left', fontSize: '1.1rem', fontWeight: 'bold', margin: '10vh 0 1vh'}}>
                    <p style={{margin: '0'}}>{t('支払い方法選択画面に戻りたい場合は、')}</p>
                    <p style={{margin: '0'}}>{t('決済端末右上の赤いボタンを押してください')}</p>
                  </div>
                </Col>
              </Row>
            </PayModalBody>
          )}
          {sequence.match(/^(pin|connecting)$/) && (
            <PayModalBody>
              <Row>
                <Col xs={6}>
                  <div style={{textAlign: 'center'}}>
                    <img src="https://dfc78s572732n.cloudfront.net/animation/iOS-Photo-1591236835.jpg" alt="" height="350"/>
                  </div>
                </Col>
                <Col xs={6}>
                  <div style={{textAlign: 'center', fontSize: '1.6rem', whiteSpace: 'nowrap', fontWeight: 'bold', margin: '15vh 5vh 0 0'}}>
                    {t('暗証番号を入力してください')}
                  </div>
                </Col>
              </Row>
            </PayModalBody>
          )}
          {sequence === 'complete' && (
            <ModalBody>
              <div style={{textAlign: 'center', fontSize: '2.1rem', fontWeight: 'bold', margin: '9vh 0'}}>
                {t('カードを抜いてください')}
              </div>
            </ModalBody>
          )}
          {sequence === 'failure' && (
            <ModalBody>
              <div style={{textAlign: 'left', fontSize: '1.5rem', fontWeight: 'bold', margin: '50px'}}>
                {t(this.props.message)}
              </div>
            </ModalBody>
          )}
          {isEmpty(sequence) && <PayModalBody></PayModalBody>}
        </PayModal>
        <PaySeqModal
          backdrop="static"
          isOpen={sequence === 'connecting'}
          size="md"
          className="modal-dialog-centered"
        >
          <PaySeqModalBody>
            <div style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', margin: '20px'}}>
              <Spinner color="primary" style={{marginRight: '1rem'}}/>
              {t('センター問い合わせ中')}
            </div>
          </PaySeqModalBody>
        </PaySeqModal>
      </section>
    )
  }
}

PayGinrenSequence.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayGinrenSequence))

const PayModal = styled(Modal)`
  min-height: 40vh;
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
`

const PayModalHeader = styled(ModalHeader)`
  background-color: #0A82B1;
  .modal-title {
    margin: auto;
    font-size: 2rem;
    color: white;
  }
`

const PayCancel = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.5rem;
  font-size: 1rem;
  color: white;
`

const PayModalBody = styled(ModalBody)`
  min-height: 40vh;
`
const CardLogoArea = styled.div`
  display:flex;
  padding:10px;
  justify-content: center;
`
const CardLogo = styled.div`
  display:flex;
  padding:5px;
`
const LogoImg = styled.img`
  height:calc(100vw/35);
  flex-basis: auto;
`

const PaySeqModal = styled(Modal)`
  .modal-content {
    border-radius: 1rem;
    .modal-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .modal-footer {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      justify-content: center;
    }
  }
`
const PaySeqModalBody = styled(ModalBody)`
`
const PaySeqModalFooter = styled(ModalFooter)`
  
`