import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
  Button,
  Card,
} from 'reactstrap'
import { IoMdArrowRoundBack } from 'react-icons/io'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {GREY_COLOR, containerStyle} from '../../constants/common'
import CustomInput from '../../components/atomics/CustomInput'
import styled from 'styled-components'
import { reduxForm, destroy as formDestroy, change } from 'redux-form'
import Btn from '../../components/atomics/Button'
import TitleHeader from '../../components/atomics/TitleHeader'
import IconBtn from '../../components/atomics/IconButton'
import {actions as checkOutAction} from '../../modules/checkOut'
import {actions as errorAction} from '../../modules/error'
import '../../css/CustomerEdit.css'

const isApri = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.icCard

/**
 * 手動ルームキー削除（鍵読み込み）
 */
class DeleteKeyTouch extends Component {
  state = { 
    count: 0, 
    interval: 0
  }

  componentDidMount() {
    this.props.start()
  }

  componentWillUnmount() {
    this.props.end()
  }

  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.clickBtn('/config/setting_select')
    }
  }

  render() {
    const { formValues } = this.props

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
            <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
              <img
                src="https://dfc78s572732n.cloudfront.net/logo/black/ja/logo-bk3.png"
                alt=""
                style={{height:'4vw'}}
              />
            </Logo>
          </Row>
          <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
            <Button onClick={() => this.props.clickBtn('/config/setting_select')}>
              <IoMdArrowRoundBack />
            </Button>
          </Row>
        </div>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle='スマカギ用鍵削除画面'/>
              <div style={{fontSize:'1.3rem', margin:'0 auto'}}>※タップと連携しておりません。チェックアウト等の必要処理はタップにて手動で行ってください。</div>
            </Row>
            {!formValues.reserveNo && (
              <ConfirmArea style={{marginTop:'7.5vh'}}>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '3rem',
                    marginTop: '20vh'
                  }}
                >
                  ICリーダーにカードキーをかざしてください
                </div>
                {!isApri && (
                  <Card
                    style={{
                      position: 'absolute',
                      right: '0',
                      bottom: '0',
                      padding: '20px',
                      zIndex: '9999',
                    }}
                  >
                    <p><Button onClick={() => window.callbackGetId('9d5cfbe0')}>9d5cfbe0</Button></p>
                    <p><Button onClick={() => window.callbackGetId('5eba794a')}>5eba794a</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345671')}>12345671</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345672')}>12345672</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345673')}>12345673</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345674')}>12345674</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345675')}>12345675</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345676')}>12345676</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345677')}>12345677</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345678')}>12345678</Button></p>
                    <p><Button onClick={() => window.callbackGetId('12345679')}>12345679</Button></p>
                  </Card>
                )}
              </ConfirmArea>
            )}
            {formValues.reserveNo && (
              <div>
                <ConfirmArea style={{marginTop:'7.5vh'}}>
                  <RowCenter style={{height:'7vw', margin:'0'}}>
                    <TableHeader xs={4} style={{height:'7vw'}}>予約番号</TableHeader>
                    <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                      <TableList>
                        {formValues.reserveNo}
                      </TableList>
                    </TableData>
                  </RowCenter>
                  <RowCenter style={{height:'7vw', margin:'0'}}>
                    <TableHeader xs={4} style={{height:'7vw'}}>部屋番号</TableHeader>
                    <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                      <TableList>
                        {formValues.roomNo}
                      </TableList>
                    </TableData>
                  </RowCenter>
                  {(formValues.tenants || []).map((tenant, idx) => (
                    <RowCenter key={tenant.tenant_id} style={{height:'7vw', margin:'0', whiteSpace:'pre-line'}}>
                      <TableHeader xs={4} style={{height:'7vw'}}>解錠可能場所{idx + 1}</TableHeader>
                      <TableData xs={8} style={{height:'7vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                        <TableList>
                          {tenant.tenant_name}
                        </TableList>
                      </TableData>
                    </RowCenter>
                  ))}
                </ConfirmArea>
                <Btn btnLabel='削除しない' marginTop='10vh' style={{left: '9.5vw', right: 'auto'}} OnClick={()=>this.props.clickCancel()}/>
                <Btn btnLabel='削除する' marginTop='10vh' OnClick={()=>this.props.clickDelete()}/>
              </div>
            )}
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

DeleteKeyTouch.propTypes = {}

const mapStateToProps = state => {
  return {
    formValues: state.form.DeleteKeyTouch ? state.form.DeleteKeyTouch.values : {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      if (isApri) {
        window.webkit.messageHandlers.icCard.postMessage('removeCard')
      } else {
        console.log('アプリ連携なし icCard')
      }
      
      // カード読み取りのコールバック
      window.callbackGetId = (uid) => {
        dispatch(checkOutAction.manuallyKeyTouch({
          card_id: uid,
          finish: (reserveNo, roomNo, tenants) => {
            if (!reserveNo) {
              // エラー表示されている
              // カード再読み込み
              if (isApri) {
                window.webkit.messageHandlers.icCard.postMessage('removeCard')
              }
              return
            }

            dispatch(change('DeleteKeyTouch', 'uid', uid))
            dispatch(change('DeleteKeyTouch', 'reserveNo', reserveNo))
            dispatch(change('DeleteKeyTouch', 'roomNo', roomNo))
            dispatch(change('DeleteKeyTouch', 'tenants', tenants))
          }
        }))
      }
      window.callbackRemoveCard = () => {
        window.webkit.messageHandlers.icCard.postMessage('getId')
      }
      window.callbackTimeout = () => {
        window.webkit.messageHandlers.icCard.postMessage('getId')
      }
      window.callbackError = (msg) => {
        console.log(msg)
        dispatch(errorAction.displayError(msg))
        window.webkit.messageHandlers.icCard.postMessage('removeCard')
      }
    },
    end: () => {
      // カード読み取りのコールバックを消しておく
      window.callbackGetId = (uid) => {
      }
      window.callbackRemoveCard = () => {
      }
      window.callbackTimeout = () => {
      }
      window.callbackError = (msg) => {
      }
    },
    clickCancel: () => {
      dispatch(formDestroy('DeleteKeyTouch'))
      if (isApri) {
        window.webkit.messageHandlers.icCard.postMessage('removeCard')
      }
    },
    clickDelete: () => {
      dispatch(checkOutAction.manuallyKeyDelete({finish: () => {
        dispatch(formDestroy('DeleteKeyTouch'))
        if (isApri) {
          window.webkit.messageHandlers.icCard.postMessage('removeCard')
        }
      }}))
    },
    clickBtn: (path, flg) => {
      dispatch(push(path))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'DeleteKeyTouch',
  })(withTranslation()(DeleteKeyTouch)))

const ConfirmArea = styled.div`
  margin:0 auto;
`
const Logo = styled.div`
  margin-right: auto;
`
const Residence = styled.div`
  background-color:#F2F0F0;
  border:1px solid ${GREY_COLOR};
  box-shadow:0px 2px  ${GREY_COLOR};
  float:left;
  height:4.5vw;
  line-height:4.5vw;
  text-align:center;
  width:15vw;
`
const TableList = styled.div`
  width: 60vw;
`
const TableHeader = styled(Col)`
  background-color:${GREY_COLOR};
  border-bottom:1px solid #fff;
  color:#333;
  line-height:1.5rem;
  font-size:1.5rem;
  padding:1.5vh;
`
const TableData = styled(Col)`
  border-top:1px solid ${GREY_COLOR};
  border-right:1px solid ${GREY_COLOR};
  // border-right:1px solid ${GREY_COLOR};
  // border-bottom:1px solid ${GREY_COLOR};
  font-size:1.5rem;
  display: flex !important;
  align-items: center !important;
  padding:1.5vh;
`
const PageNumber = styled.div`
  margin-top:1.5vh;
  margin-right:1.5vw;
`
const NextPage = styled.div`
  margin-top:1.5vh;
`
const clear_both = {
  clear:'both'
}

const DummyCardButton = styled(Button)`
  margin: 2px;
`
