import {all, call} from 'redux-saga/effects';
import {authSaga} from './auth'
import {confirmSaga} from './confirm'
import {loadingSaga} from './loading'
import {masterSaga} from './master'
import {reserveSaga} from './reserve'
import {middlewareSaga} from './middleware'
import {paymentSaga} from './payment'
import {receiptSaga} from './receipt'
import {keyIssueSaga} from './keyIssue'
import {checkOutSaga} from './checkOut'
import {settingSaga} from './setting'
import {keysManuallySaga} from './keysManually'

export default function* indexSaga() {
  yield all([
    call(authSaga),
    call(confirmSaga),
    call(loadingSaga),
    call(masterSaga),
    call(reserveSaga),
    call(middlewareSaga),
    call(paymentSaga),
    call(receiptSaga),
    call(keyIssueSaga),
    call(checkOutSaga),
    call(settingSaga),
    call(keysManuallySaga),
  ])
}