import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Container,
  Button,
} from 'reactstrap'
import {goBack} from 'connected-react-router'
import Header from '../../components/molecules/Header'
import CustomContainer from '../../components/atomics/CustomContainer'
import Paper from '../../components/atomics/Paper'
import {actions as paymentAction} from '../../modules/payment'
import {RowCenter} from '../../util/styled'
import {ACC_COLOR, BROWN_COLOR} from '../../constants/common'
import styled from 'styled-components'

/**
 * 予約検索
 */
class ConfirmPassport extends Component {
  render() {
    return (
      <section>
        <Header headerTitle={'お支払い'}/>
        <CustomContainer>
          <Container>
            <Paper height='70vh'>
              <RowCenter>
                <Message>'お支払い方法を選択してください'</Message>
              </RowCenter>
              <RowCenter>
                <Button style={yesButtonStyle} onClick={()=>this.props.clickButton()}>
                    '現金'
                </Button>
                <Button style={noButtonStyle} onClick={()=>this.props.clickButton()}>
                    'クレジットカード'
                </Button>
              </RowCenter>
            </Paper>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

ConfirmPassport.propTypes = {}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickReturn: () => dispatch(goBack()),
    clickButton: () => dispatch(paymentAction.getBalance()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassport)

const Message = styled.p`
  font-size: 40px;
  margin-top: 10vh;
  margin-bottom: 10vh;
`
const yesButtonStyle = {
  background: ACC_COLOR,
  border: ACC_COLOR,
  height: '160px',
  width: '280px',
  marginRight: '20vw',
  fontSize: '60px'
}
const noButtonStyle = {
  background: BROWN_COLOR,
  border: ACC_COLOR,
  height: '160px',
  width: '280px',
  fontSize: '60px'
}