import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap'
import {actions as receiptActions} from '../../modules/receipt'
import {PRO_HOST} from '../../constants/api-config'
import {ACC_COLOR, SUB_COLOR} from '../../constants/common'
import CustomContainer from '../../components/atomics/CustomContainer'
import { reduxForm } from 'redux-form'

/**
 * 画面
 */
class Printer extends Component {

  render() {

    return (
      <CustomContainer center='true' style={{height:'100vh', width:'100vw'}}>
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <Card>
                <CardHeader className={'text-center'} style={{background: SUB_COLOR, color: '#FFF'}}>
                      星野リゾート{window.location.hostname !== PRO_HOST && <span style ={{color: ACC_COLOR}}>(開発環境)</span>}
                </CardHeader>
                <CardBody>
                  <div>
                    <Button block color={'info'} style={{margin: '0 .3rem', background: ACC_COLOR}}
                      onClick={() => this.props.clickPrintBtn()}
                    >
                      プリント
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CustomContainer>
    )
  }
}

Printer.propTypes = {
  stores: PropTypes.array
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickPrintBtn: () => {
      dispatch(receiptActions.printReceipt())
      dispatch(receiptActions.printTicket())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'printer'
  })(Printer))
