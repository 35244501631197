import { createActions, handleActions } from 'redux-actions'
import {
  GET_BALANCE_URL,
  POST_PAYMENT_URL
} from '../constants/api-url'
import { select, put, call, takeEvery, all } from 'redux-saga/effects'
import {delay} from '@redux-saga/core/effects';
import {NODERED_URL} from '../constants/api-config'
import {api} from './middleware'
import {actions as errorAction} from './error'
import {actions as reserveAction, searchMealCoupon} from './reserve'
import {actions as loadingAction} from './loading'
import {actions as receiptActions} from './receipt'
import {isEmpty} from '../util/common'
import {push} from 'connected-react-router' 
import moment from 'moment'
/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    initReserve: () => ({}),
    getBalance: () => ({}),
    successGetBalance: payload => payload,
    postPayment: payload => payload,
    successPostPayment: payload => payload,
    handleRoot: () => ({}),
    requestTerminalPayment: payload => payload,
    cashPayment: () => ({}),
    sendKeyIssue: () => ({}),
  },
  { prefix: 'hoshino/payment'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  searchParam: {},
  balanceList: [],
  paymentResult: null,
}
const reducer = handleActions({
  [actions.initReserve]: () => initialState,
  //[actions.successGetBalance]: (state, action) => ({...state, balanceList: [...state.balanceList, action.payload.json.GetBalance_ResultList]}),
  [actions.successPostPayment]: (state, action) => ({
    ...state,
    paymentResult: action.payload,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* paymentSaga() {
  yield takeEvery(actions.getBalance, getBalance)
  yield takeEvery(actions.successGetBalance, successGetBalance)
  yield takeEvery(actions.postPayment, postPayment)
  yield takeEvery(actions.handleRoot, handleRoot)
  yield takeEvery(actions.requestTerminalPayment, requestTerminalPayment)
  yield takeEvery(actions.cashPayment, cashPayment)
  yield takeEvery(actions.sendKeyIssue, sendKeyIssue)
}

function* getBalance(action) {
  const {reserve: {selectedReserve}} = yield select()
  yield all(selectedReserve.map((item, key) => {
    const URL = `${NODERED_URL}${GET_BALANCE_URL}?RsvStyPtn=RSV&RoomNo=${item.room.RoomNo}&RoomAccountID=${item.RoomAccountID}`
    const payload = {
      url: URL,
      method: 'GET',
      successCB: {cb: actions.successGetBalance, args: {index: key}},
      openLoadingFlag: 2,
    }
    return call(api, {payload})
  }))
  yield put(loadingAction.offContinue())
  yield put(actions.handleRoot())
}

function* successGetBalance(action) {
  const {GetBalance_ResultList} = action.payload.json
  if(isEmpty(GetBalance_ResultList)){
    yield put(errorAction.displayError('未収残高が取得できませんでした'))
  }
  yield put(reserveAction.setBalance(action.payload))
}

function* handleRoot(action) {
  const {
    // payment: {balanceList},
    reserve: {selectedReserve},
  } = yield select()

  let amount = 0
  let receiptAmount = 0
  selectedReserve.forEach(item => {
    amount += Number(item.balance.Balance)
    receiptAmount += Number(item.balance.ReceptAmt)
  })

  if(amount === 0){
    yield call(searchMealCoupon, {})

    yield put(push('/checkin/room_key_issue'))
  }else{
    yield put(push('/checkin/payment'))
  }
}

function* postPayment(action) {
  const { credit } = action.payload
  const {reserve: {
    selectedReserve,
  }} = yield select()

  const resv_balance = selectedReserve.reduce((sum, reserve) => sum + parseInt(reserve.balance.Balance, 10), 0)

  const URL = `${NODERED_URL}${POST_PAYMENT_URL}`
  const request = {
    RoomNo: selectedReserve[0].room.RoomNo,
    RoomAccountID: selectedReserve[0].RoomAccountID,
    PayBill_PayList: {
      PayPtn: '02',
      Net: resv_balance,
    },
  }
  const payload = {
    url: URL,
    method: 'POST',
    request: request,
    openLoadingFlag: 2,
    sleepTime: 300,
  }

  const result = yield call(api, {payload})
  if(result.ResultHeader.ResultNo !== '00000'){
    yield put(errorAction.displayError('精算できませんでした'))
    return
  }

  yield put(actions.successPostPayment({json: result, credit, index: 0}))
  yield put(reserveAction.successPostPayment({json: result, credit, index: 0}))
  yield put(loadingAction.offContinue())
}

function* sendKeyIssue() {
  yield call(searchMealCoupon, {})
  yield put(push('/checkin/room_key_issue'))
}

function* requestTerminalPayment(action) {
  const {
    reserve: {selectedReserve},
  } = yield select()

  let amount = 0
  selectedReserve.forEach(item => amount += Number(item.balance.Balance))

  action.payload(amount, 0, yield select())
}

function* cashPayment() {
  yield put(loadingAction.openLoading())
  yield put(loadingAction.onContinue())
  yield put(receiptActions.printCash())
  yield delay(2000)
  yield put(loadingAction.offContinue())

  yield put(push('/checkin'))
}
