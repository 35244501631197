import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import {actions as errorAction} from '../../modules/error'
import {isEmpty} from '../../util/common'
import {FaArrowAltCircleRight} from 'react-icons/fa'
import {push} from 'connected-react-router'
import { ok } from 'assert'
import { withTranslation } from 'react-i18next'

/**
 * エラー表示モーダル
 */
class Error extends Component {
  
  render() {
    const {isOpen, errorMessages, note = '', displayBtnFlg, t} = this.props
    let errorString = ''
    let errorArray = []
      

    //エラーメッセージが配列の場合、一度文字列に変換
    if (Array.isArray(errorMessages)) {
      errorMessages.map(error => {
        errorString += error + '\n'
      })
    } else {
      errorString = errorMessages
    }

    //200文字以上は省略
    errorString =
        !isEmpty(errorString) && errorString.length > 200
          ? errorString.slice(0, 200) + '...'
          : errorString

    //エラーメッセージを改行コードで配列の要素に分ける
    if (!isEmpty(errorString)) {
      errorArray = errorString.split('\n').map((error, key) => {
        return (
          <div key={key} style={{display: 'block', paddingTop: '4vh', whiteSpace: 'pre-line'}}>
            {t(error)}
          </div>
        )
      })
    }

    return (
      <section id="error">
        <Modal
          backdrop="static"
          dialogClassName="error-modal"
          isOpen={isOpen}
          onHide={() => {
            this.props.close()
          }}
          style={{minHeight: '50vh', minWidth: '80vw', paddingTop: '15vh'}}
        >
          <ModalBody style={{minHeight: '50vh', minWidth: '80vw'}}>
            <div
              className={'text-center'}
              style={{
                fontSize: '2rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                paddingTop: '15vh'
              }}
            >
              <div>
                {errorArray.map(error => {
                  return error
                })}
              </div>
              <hr/>
              <div>
                {!isEmpty(note) &&
                  <span>
                    <FaArrowAltCircleRight/>{note}
                  </span>
                }
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{margin: '2rem auto', textAlign: 'center', borderTop: '0 solid'}}>
            {displayBtnFlg === false ? null :<Button style={{boxShadow:'0px 4px ' + '#707070', width:'12vw', height:'4.5vw', fontSize:'1.5rem'}} onClick={() => this.props.close()}>{t('閉じる')}</Button>}
          </ModalFooter>
        </Modal>
      </section>
    )
  }
}

// Error.propTypes = {
//   error: PropTypes.shape({
//     isOpen: PropTypes.bool,
//     errorMessages: PropTypes.string,
//     note: PropTypes.string,
//     displayBtnFlg: true
//   }),
// }

const mapStateToProps = state => {
  return {
    isOpen: state.error.isOpen,
    errorMessages: state.error.errorMessages,
    note: state.error.note,
    displayBtnFlg: state.error.displayBtnFlg
  }
}
const mapDispatchToProps = dispatch => {
  return {
    close: () => {
      dispatch(errorAction.close())
    },
    backTop: () => {
      dispatch(push('/checkout/key-touch'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Error))
