import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, Button} from 'reactstrap'
import { actions as confirmAction } from '../../modules/confirm-bk'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'

/**
 * 確認用ポップアップ
 */
export class Confirm extends Component {
  componentDidMount() {
    this.props.init()
  }

  render() {
    const { isOpen, confirmMessages, isSingleButton, transitionUrl, t} = this.props

    let confirmString = confirmMessages
    let url = transitionUrl.payload

    return (
      <section id="error">
        <Modal style={{minHeight: '50vh', minWidth: '80vw', paddingTop: '15vh'}} backdrop="static" isOpen={isOpen} onExited={this.props.init}>
          <ModalBody style={{minHeight: '50vh', minWidth: '80vw'}}>
            <div
              className={'text-center'}
              style={{
                fontSize: '2rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                paddingTop: '15vh',
                whiteSpace: 'pre-line',
                lineHeight: '2.5'
              }}
            >
              {confirmString.payload}
            </div>
          </ModalBody>
          <ModalFooter
            style={{margin: '2rem auto', textAlign: 'center', borderTop: '0 solid'}}
          >
            {!isSingleButton && (
              <Button
                style={{background: '#FFF', color: '#707070', margin: '0 5vh', width: '12vw', height: '4.5vw', fontSize:'1.5rem', boxShadow:'0px 4px ' + '#707070'}}
                onClick={() => {
                  this.props.cancelCallback()
                  this.props.closeConfirm()
                }}
              >
                {t('戻る')}
              </Button>
            )}
            <OkBtn
              style={{backgroundColor: '#484747', margin: '0 5vh', fontSize:'1.5rem', boxShadow:'0px 4px' + '#707070'}}
              onClick={() => {
                this.props.doOkCallback()
                this.props.doTransition(url)
                this.props.closeConfirm()
              }}
            >
              {t('中止する')}
            </OkBtn>
          </ModalFooter>
        </Modal>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.confirm.isOpen,
    confirmMessages: state.confirm.confirmMessages,
    transitionUrl:state.confirm.transitionUrl,
    okCallback: state.confirm.okCallback,
    cancelCallback: state.confirm.cancelCallback,
    isSingleButton: state.confirm.isSingleButton,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(confirmAction.init())
    },
    closeConfirm: () => {
      dispatch(confirmAction.closeConfirm())
    },
    doOkCallback: (transitionUrl) => {
      dispatch(confirmAction.doOkCallback())
    },
    doTransition:(path)=>{
      dispatch(push(path))
    }
  }
}
Confirm.propTypes = {
  isOpen: PropTypes.bool,
  confirmMessages: PropTypes.string,
  transitionUrl: PropTypes.string,
  okCallback: PropTypes.any,
  closeConfirm: PropTypes.any,
  isSingleButton: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Confirm))

const OkBtn = styled(Button)`
  width: 12vw;
  height: 4.5vw;
`
