import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap'
import {PRO_HOST} from '../../constants/api-config'
import {ACC_COLOR, SUB_COLOR} from '../../constants/common'
import CustomContainer from '../../components/atomics/CustomContainer'

/**
 * 画面
 */
class Qr extends Component {
  componentDidMount() {
    this.props.startQr()
  }
  componentWillUnmount() {
    this.props.endQr()
  }

  render() {

    return (
      <CustomContainer center='true' style={{height:'100vh', width:'100vw'}}>
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <Card>
                <CardHeader className={'text-center'} style={{background: SUB_COLOR, color: '#FFF'}}>
                      星野リゾート{window.location.hostname !== PRO_HOST && <span style ={{color: ACC_COLOR}}>(開発環境)</span>}
                </CardHeader>
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CustomContainer>
    )
  }
}

Qr.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startQr: () => {
      console.log('startQR')

      // カメラ起動
      window.webkit.messageHandlers.qr.postMessage('start')

      window.callbackQrSuccess = qr => {
        console.log(qr)
      }

      window.callbackError = message => {
        console.log(message)
      }
    },
    endQr: () => {
      // カメラ終了
      window.webkit.messageHandlers.qr.postMessage('start')

      window.callbackQrSuccess = qr => {
      }
      window.callbackError = message => {
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Qr)
