import {isEmpty} from '../util/common'
import { createActions, handleActions } from 'redux-actions'
/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    displayNotice: payload => payload,
    close: () => ({}),
  },
  { prefix: 'hoshino/notice'},
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  isOpen: false,
  message: '',
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.displayNotice]: (state, action) => {
    let mes = action.payload
    mes = !isEmpty(mes) && mes.length > 500 ? mes.slice(0, 500) + '...' : mes

    return {
      ...state,
      isOpen: true,
      message: mes,
    }
  },
  [actions.close]: () => initialState,
}, initialState)

export default reducer
