import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery } from 'redux-saga/effects'
/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    setConfirmMessages: payload => payload,
    openConfirm: () => ({}),
    closeConfirm: () => ({}),
    setOkCallback: payload => payload,
    setCancelCallback: payload => payload,
    doOkCallback: () => ({}),
  },
  { prefix: 'hoshino/confirm'},
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  isOpen: false,
  confirmMessages: '',
  okCallback: [],
  cancelCallback: {},
  isSingleButton: false, //trueならOKボタンのみ表示
}
const reducer = handleActions({
  [actions.init]: () => ({
    ...initialState,
    cancelCallback: actions.closeConfirm,
  }),
  [actions.openConfirm]: state => ({
    ...state,
    isOpen: true
  }),
  [actions.closeConfirm]: state => ({
    ...state,
    isOpen: false
  }),
  [actions.setConfirmMessages]: (state, payload) => ({
    ...state,
    isOpen: true,
    confirmMessages: payload,
  }),
  [actions.setOkCallback]: (state, payload) => ({
    ...state,
    okCallback: payload.cb_list,
    isSingleButton: payload.is_single_button
  }),
  [actions.setCancelCallback]: (state, payload) => ({
    ...state,
    cancelCallback: payload,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* confirmSaga() {
  yield takeEvery(actions.doOkCallback, doOkCallback)
}

function* doOkCallback(action) {
  const { confirm } = yield select()
  //okCallback配列内の関数を順次実行
  for (const func of confirm.okCallback) {
    put(func())
  }
}