import React, {Component} from 'react'
import Header from '../../components/molecules/Header'
import {connect} from 'react-redux'
import {ACC_COLOR, FONT_COLOR} from '../../constants/common'
import styled from 'styled-components'
import {push} from 'connected-react-router'

class NotFound extends Component {
  render() {

    return (
      <section>
        <Header title={'エラー'} ImgUrl={'https://d2h6ki1t635jo7.cloudfront.net/img/logo.png'}/>
        <MsgContainer>
          <MsgArea>
            <ErrCd><p>404</p></ErrCd>
            <ErrMsgEn><p>Page Not Found</p></ErrMsgEn>
            <ErrMsgJa><p>ページが見つかりませんでした。</p></ErrMsgJa>
            <BackBtn onClick={() => this.props.clickTopBtn()}>
              <a style={{color:'#fff', textDecoration:'none'}}>
                <div>TOPへ戻る</div>
              </a>
            </BackBtn>
          </MsgArea>
        </MsgContainer>
      </section>
    );
  }
}


NotFound.propTypes = {
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickTopBtn: () => {
      dispatch(push('/'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)

/* CSS定義 */
const MsgContainer = styled.section`
      width:100vmax;
    `

const MsgArea = styled.div`
      top:31vmin;
      position:absolute;
      width:100vw;
    `

const ErrCd = styled.div`
      color:${ACC_COLOR};
      font-size:20vmin;
      text-align:center;
      line-height:14vmin;
    `

const ErrMsgEn = styled.div`
      color:${FONT_COLOR};
      font-size:4.6vmin;
      text-align:center;
      letter-spacing: 0.03em;
    `

const ErrMsgJa = styled.div`
      color:${FONT_COLOR};
      font-size:2vmin;
      font-weight:bold;
      text-align:center;
    `

const BackBtn = styled.div`
      background-color: #D90025;
      border-radius: 0.35vw;
      color: #fff;
      font-size: 2.3vmin;
      font-weight: bold;
      height: 4.2vmin;
      line-height: 4.2vmin;
      text-align: center;
      margin: 0 auto;
      width: 23vmin;
      &:hover{
        opacity:0.8;
      }
    `
