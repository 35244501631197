import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
} from 'reactstrap'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {GREY_COLOR, RED_COLOR, WHITE_COLOR, containerStyle} from '../../constants/common'
import CustomInput from '../../components/atomics/CustomInput'
import styled from 'styled-components'
import { reduxForm } from 'redux-form'
import {actions as reserveAction} from '../../modules/reserve'
import Wizard from '../../components/atomics/StepWizard'
import Btn from '../../components/atomics/Button'
import TitleHeader from '../../components/atomics/TitleHeader'
import IconBtn from '../../components/atomics/IconButton'
import {actions as confirmAction} from '../../modules/confirm'
import '../../css/CustomerEdit.css'
import {isEmpty} from '../../util/common'

/**
 * 予約検索
 */
class CustomerEdit extends Component {
  render() {
    const {t, formValues = {}} = this.props
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')
    
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn('/checkin/reconfirm')}/>
          <Wizard index={3}/> 
          <IconBtn btnLabel={t('中止')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/cancel.png' OnClick={() => this.props.clickCancel('/checkin', msg)}/>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('予約者情報を入力してください')}/>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <RowCenter style={{height:'7vw', margin:'0'}}>
                  <TableHeader xs={4} style={{height:'7vw'}}>{t('居住地')}<Confirm>{t('必須')}</Confirm></TableHeader>
                  <TableData xs={8} style={{height:'7vw'}}>
                    <Residence className="country_class" style={{marginRight:'3vw'}}>
                      <CustomInput bordered name="country" id="kokunai" component="input" type="radio" value="kokunai"/>
                      <label style={{height:'4.5vw', fontSize:'1.5rem'}} htmlFor="kokunai">{t('国内')}</label>
                    </Residence>
                    <Residence className="country_class">
                      <CustomInput bordered name="country" id="kaigai" component="input" type="radio" value="kaigai"/>
                      <label style={{height:'4.5vw', fontSize:'1.5rem'}} htmlFor="kaigai">{t('海外')}</label>
                    </Residence>
                  </TableData>
                </RowCenter>
                <RowCenter style={{height:'10vw', margin:'0'}}>
                  <TableHeader xs={4} style={{height:'10vw'}} className={[i18n.language]}>{t('領収書宛名')}<Confirm style={{backgroundColor:'#31A237'}}>{t('変更可')}</Confirm></TableHeader>
                  <TableData xs={8} style={{height:'10vw', borderBottom:'1px solid ' + GREY_COLOR}}>
                    <TableList>
                      <CustomInput bordered name="ReceiptName" component="input" type="text" placeholder="" />
                    </TableList>
                  </TableData>
                </RowCenter>
                <RowCenter style={{margin:'0'}} onClick={()=>this.props.clickNext()}>
                  <PageNumber>1/2</PageNumber>
                  <NextPage>{'>>'}</NextPage>
                </RowCenter>
              </div>
            </ConfirmArea>
            <Btn disabled={isEmpty(formValues.country)} btnLabel={t('次へ')} marginTop='10vh' OnClick={()=>this.props.clickCheckInButton(formValues.country)}/>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

CustomerEdit.propTypes = {}

const mapStateToProps = state => {
  const selectedReserve = state.reserve.selectedReserve
  return {
    formValues: state.form.customerEdit ? state.form.customerEdit.values : {},
    initialValues: {
      ReceiptName: selectedReserve[0].detail.ReceiptName,
      country: i18n.language === 'ja' ? 'kokunai' : ''
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: (path) => dispatch(push(path)),
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    },
    clickNext: () => dispatch(push('/checkin/edit/option')),
    clickCheckInButton: () => {
      dispatch(reserveAction.hasRequiredItem())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'customerEdit',
    destroyOnUnmount: false,
  })(withTranslation()(CustomerEdit)))

const ConfirmArea = styled.div`
  margin:0 auto;
  // width: 80vw;
`
const Residence = styled.div`
  background-color:#F2F0F0;
  border:1px solid ${GREY_COLOR};
  box-shadow:0px 2px  ${GREY_COLOR};
  float:left;
  height:4.5vw;
  line-height:4.5vw;
  text-align:center;
  // margin:2vh 0;
  width:15vw;
`
const TableList = styled.div`
  width: 60vw;
`

const Notes = styled.div`
  font-size: 1rem;
  padding-top: 1.5vh;
`

const TableHeader = styled(Col)`
  background-color:${GREY_COLOR};
  border-bottom:1px solid #fff;
  color:#333;
  line-height:1.5rem;
  font-size:1.5rem;
  padding:1.5vh;
  &.en {
    line-height:2rem;
  }
`
const TableData = styled(Col)`
  border-top:1px solid ${GREY_COLOR};
  border-right:1px solid ${GREY_COLOR};
  // border-right:1px solid ${GREY_COLOR};
  // border-bottom:1px solid ${GREY_COLOR};
  font-size:1.5rem;
  display: flex !important;
  align-items: center !important;
  padding:1.5vh;
`
const PageNumber = styled.div`
  margin-top:1.5vh;
  margin-right:1.5vw;
`
const NextPage = styled.div`
  margin-top:1.5vh;
`
const clear_both = {
  clear:'both'
}

const Confirm = styled.span`
  margin-left: 0.5vw;
  background-color: ${RED_COLOR};
  color: ${WHITE_COLOR};
  font-size:1rem;
  margin: 1vw;
  padding: 0.5vw 1vw;
  border-radius:3vw;
`
const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`