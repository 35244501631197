import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
} from 'reactstrap'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {
  containerStyle,
  GREY_COLOR,
  RED_COLOR,
  WHITE_COLOR
} from '../../constants/common'
import CustomInput from '../../components/atomics/CustomInput'
import styled from 'styled-components'
import { reduxForm } from 'redux-form'   
import {actions as reserveAction} from '../../modules/reserve'
import Wizard from '../../components/atomics/StepWizard'
import Btn from '../../components/atomics/Button'
import TitleHeader from '../../components/atomics/TitleHeader'
import IconBtn from '../../components/atomics/IconButton'
import {actions as confirmAction} from '../../modules/confirm'
import {isEmpty} from '../../util/common'

/**
 * 予約検索
 */
class CustomerEditOption extends Component {
  render() {
    const {country, hasZipCode, t, i18n} = this.props
    let msg = t('手続きを中止した場合、入力内容は保存されません\nチェックイン手続きを中止しますか？')
    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn btnLabel={t('戻る')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/back.png' OnClick={() => this.props.clickBtn('/checkin/reconfirm')}/>
          <Wizard index={3}/> 
          <IconBtn btnLabel={t('中止')} btnImage='https://dfc78s572732n.cloudfront.net/icon/20191031/cancel.png' OnClick={() => this.props.clickCancel('/checkin', msg)}/>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader headerWidth='100vw' marginTop='2vh' headerTitle={t('予約者情報を入力してください')}/>
            </Row>
            <ConfirmArea style={{marginTop:'7.5vh'}}>
              <div height='68vh'>
                <Row style={{margin:'0'}}>
                  <TableHeader className={[i18n.language]} xs={2}>{t('氏名')}</TableHeader>
                  <TableData xs={5}>
                    <CustomInput bordered name="GuestName" component="input" type="text" placeholder="" />
                  </TableData>
                  <TableHeader className={[i18n.language]} xs={2}>{t('同伴者')}</TableHeader>
                  <TableData>
                    <CustomInput bordered name="with" component="input" type="text" placeholder="" />
                  </TableData>
                </Row>
                <Row style={{margin:'0'}}>
                  <TableHeader className={[i18n.language]} xs={2}>{t('郵便番号')}<Confirm>{t('必須')}</Confirm></TableHeader>
                  <TableData xs={2}>
                    <CustomInput bordered name="ZipCode" component="input" type="text" placeholder="" />
                  </TableData>
                  <TableHeader className={[i18n.language]} xs={2}>{t('住所')}</TableHeader>
                  <TableData>
                    <CustomInput bordered name="ReservationAddress" component="input" type="text" placeholder="" />
                  </TableData>
                </Row>
                <Row style={{margin:'0'}}>
                  <TableHeader className={[i18n.language]} xs={2}>{t('電話番号')}</TableHeader>
                  <TableData xs={3}>
                    <CustomInput bordered name="Telephone" component="input" type="text" placeholder="" />
                  </TableData>
                  <TableHeader className={[i18n.language]} xs={1}>{t('年齢')}</TableHeader>
                  <TableData xs={2}>
                    <CustomInput bordered name="age" component="input" type="text" placeholder="" />
                  </TableData>
                  <TableHeader className={[i18n.language]} xs={1}>{t('職業')}</TableHeader>
                  <TableData>
                    <CustomInput bordered name="job" component="input" type="text" placeholder="" />
                  </TableData>
                </Row>  
                <Row style={{margin:'0'}}>
                  <TableHeader className={[i18n.language]} xs={2}>{t('前泊地')}</TableHeader>
                  <TableData xs={3}>
                    <CustomInput bordered name="previousstay" component="input" type="text" placeholder="" />
                  </TableData>
                  <TableHeader className={[i18n.language]} xs={2}>{t('行先地')}</TableHeader>
                  <TableData>
                    <CustomInput bordered name="nextstay" component="input" type="text" placeholder="" />
                  </TableData>
                </Row>
                <Row style={{margin:'0'}}>
                  <TableHeader className={[i18n.language]} xs={2}>{t('宿泊人数')}</TableHeader>
                  <SubTableHeader className={[i18n.language]} xs={1}>{t('男性')}</SubTableHeader>
                  <TableData style={{borderBottom:'1px solid ' + GREY_COLOR}}>
                    <CustomInput bordered name="Person_M" component="input" type="text" placeholder="" disabled={true}/>
                  </TableData>
                  <SubTableHeader className={[i18n.language]} xs={1}>{t('女性')}</SubTableHeader>
                  <TableData style={{borderBottom:'1px solid ' + GREY_COLOR}}>
                    <CustomInput bordered name="Person_F" component="input" type="text" placeholder="" disabled={true}/>
                  </TableData>
                  <SubTableHeader className={[i18n.language]} xs={1}>{t('子供')}</SubTableHeader>
                  <TableData style={{borderBottom:'1px solid ' + GREY_COLOR}}>
                    <CustomInput bordered name="Person_C" component="input" type="text" placeholder="" disabled={true}/>
                  </TableData>
                </Row>
                <Btn disabled={isEmpty(country) || (country === 'kokunai' && isEmpty(hasZipCode))} btnLabel={t('次へ')} btnFloat='right' marginTop='5vh' OnClick={()=>this.props.clickCheckInButton()}/>
                <RowCenter style={{margin:'0'}} onClick={()=>this.props.clickPrev()}>
                  <PrevPage>{'<<'}</PrevPage>
                  <PageNumber>2/2</PageNumber>
                </RowCenter>
              </div>
            </ConfirmArea>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

CustomerEditOption.propTypes = {}

const mapStateToProps = state => {
  const selectedReserve = state.reserve.selectedReserve
  return {
    country: state.form.customerEdit.values.country,
    hasZipCode: state.form.customerEditOption ===  undefined ? '' : state.form.customerEditOption.values.ZipCode,
    initialValues: {
      GuestName: selectedReserve[0].GuestName,
      ZipCode: selectedReserve[0].detail.ZipCode,
      Telephone: selectedReserve[0].detail.Telephone,
      ReservationAddress: selectedReserve[0].ReservationAddress,
      Birthday: selectedReserve[0].Birthday,
      MailAddress: selectedReserve[0].detail.MailAddress,
      Person_M: selectedReserve[0].detail.Person_M,
      Person_F: selectedReserve[0].detail.Person_F,
      Person_C: selectedReserve[0].detail.Person_C,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickBtn: path => dispatch(push(path)),
    clickCancel: (path, msg) => {
      dispatch(confirmAction.init())
      dispatch(confirmAction.openConfirm())
      dispatch(confirmAction.setConfirmMessages(msg))
      dispatch(confirmAction.setTransitionUrl(path))
    },
    clickPrev: () => dispatch(push('/checkin/edit')),
    clickCheckInButton: () => {
      dispatch(reserveAction.hasRequiredItem())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'customerEditOption',
    destroyOnUnmount: false,
  })(withTranslation()(CustomerEditOption)))

const ConfirmArea = styled.div`
  margin:0 auto;
  // width: 80vw;
`

const TableHeader = styled(Col)`
  background-color:${GREY_COLOR};
  border-bottom:1px solid #fff;
  color:#333;
  font-size:1.5rem;
  height:7vw;
  line-height:7vw;
  &.en {
    font-size:0.9rem;
    line-height:2vw;
    padding:20px 15px;
  }
`

const SubTableHeader = styled(Col)`
  background-color:#F2F0F0;
  border-bottom:1px solid #fff;
  color:#333;
  font-size:1.5rem;
  height:7vw;
  line-height:7vw;
  &.en {
    font-size:0.9rem;
  }
`
const TableData = styled(Col)`
  border-top:1px solid ${GREY_COLOR};
  border-right:1px solid ${GREY_COLOR};
  // border-right:1px solid ${GREY_COLOR};
  // border-bottom:1px solid ${GREY_COLOR};
  font-size:1.5rem;
  height:7vw;
  display: flex !important;
  align-items: center !important;
`
const PageNumber = styled.div`
  margin-top:1.5vh;
  margin-left:1.5vw;
`
const PrevPage = styled.div`
  margin-top:1.5vh;
`
const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
const Confirm = styled.span`
  margin-left: 0.5vw;
  background-color: ${RED_COLOR};
  color: ${WHITE_COLOR};
  font-size: 0.7rem;
  margin: 0 0 0 0.5vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
`