import { store } from '../index'

export const TYPE_CREDIT = 'credit'
export const TYPE_GINREN = 'ginren'

export const hasNative = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vega3000


const postMessage = (type, method, option) => {
  if (!store) {
    return
  }

  const {
    setting
  } = store.getState()

  if (!hasNative) {
    return
  }
  try {
    window.webkit.messageHandlers.vega3000.postMessage(JSON.stringify({
      type: type,
      method: method,
      ip: setting.vega3000Url,
      port: 9999,
      amount: option.amount,
      tax: option.tax,
    }))
  } catch (e) {
    // エラー通知
  }
}

export const start = (type, amount, tax) => {
  postMessage(type, 'start', {amount, tax})

  const callbacks = {
    /** func = (result) => {} */
    successCallback: null,
    success(func) {
      this.successCallback = func
      return this
    },

    /** func = (result) => {} */
    failureCallback: null,
    failure(func) {
      this.failureCallback = func
      return this
    },

    /** func = () => {} */
    cancelCallback: null,
    cancel(func) {
      this.cancelCallback = func
      return this
    },
    
    /** func = (page) => {} */
    transitionCallback: null,
    transition(func) {
      this.transitionCallback = func
      return this
    },

    /** func = (message) => {} */
    errorCallback: null,
    error(func) {
      this.errorCallback = func
      return this
    }
  }

  // 端末未接続確認
  let connectionCheck = setTimeout(() => {
    typeof(callbacks.errorCallback) === 'function' && callbacks.errorCallback('決済端末との接続に問題があります。\nスタッフにお声がけ下さい。')
  }, 8 * 1000)
  const connectionCheckOK = () => {
    if (connectionCheck) {
      clearTimeout(connectionCheck)
      connectionCheck = null
    }
  }

  // 決済終了
  window.callbackVega3000Response = (result) => {
    connectionCheckOK()

    if (result.resultCode === 'L11') {
      // 端末キャンセル
      typeof(callbacks.cancelCallback) === 'function' && callbacks.cancelCallback()
      return
    }
    if (result.resultCode !== '000') {
      // 失敗
      typeof(callbacks.failureCallback) === 'function' && callbacks.failureCallback(result)
      return
    }

    typeof(callbacks.successCallback) === 'function' && callbacks.successCallback(result)
  }

  // 中断
  window.callbackVega3000ProcessingInterruption = () => {
    connectionCheckOK()
    typeof(callbacks.cancelCallback) === 'function' && callbacks.cancelCallback()
  }

  // 画面遷移
  window.callbackVega3000TransitionAlert = (page) => {
    connectionCheckOK()
    typeof(callbacks.transitionCallback) === 'function' && callbacks.transitionCallback(page)
  }

  return callbacks
}

export const stop = (type) => {
  postMessage(type, 'stop', {})
  window.callbackVega3000Response = () => {}
  window.callbackVega3000ProcessingInterruption = () => {}
  window.callbackVega3000TransitionAlert = () => {}
  window.callbackVega3000ErrorMessage = () => {}
}

export const report = (type) => {
  postMessage(type, 'report', {})

  const callbacks = {
    /** func = (result) => {} */
    successCallback: null,
    success(func) {
      this.successCallback = func
      return this
    },

    /** func = (result) => {} */
    failureCallback: null,
    failure(func) {
      this.failureCallback = func
      return this
    },

    /** func = (message) => {} */
    errorCallback: null,
    error(func) {
      this.errorCallback = func
      return this
    },
  }

  // 端末未接続確認
  let connectionCheck = setTimeout(() => {
    typeof(callbacks.errorCallback) === 'function' && callbacks.errorCallback('決済端末との接続に問題があります。')
  }, 10 * 1000)
  const connectionCheckOK = () => {
    if (connectionCheck) {
      clearTimeout(connectionCheck)
      connectionCheck = null
    }
  }

  // 決済終了
  window.callbackVega3000Response = (result) => {
    connectionCheckOK()
    stop(type)

    if (result.resultCode !== '000') {
      // 失敗
      typeof(callbacks.failureCallback) === 'function' && callbacks.failureCallback(result)
      return
    }

    typeof(callbacks.successCallback) === 'function' && callbacks.successCallback(result)
  }

  // エラー
  window.callbackVega3000ErrorMessage = (message) => {
    connectionCheckOK()
    stop(type)

    typeof(callbacks.errorCallback) === 'function' && callbacks.errorCallback(message)
  }

  return callbacks
}


export const debugSuccess = (result) => {
  window.callbackVega3000Response({
    ...result,
    resultCode: '000',
  })
  setTimeout(() => {
    debugTransition('001')
  }, 3000)
}

export const debugFailure = (code, message) => {
  window.callbackVega3000Response({
    resultCode: code,
    message,
  })
}

export const debugTransition = (page) => {
  window.callbackVega3000TransitionAlert(page)
}


// 通知処理を初期化しておく
if (hasNative) {
  stop()
}
