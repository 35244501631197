import { createActions } from 'redux-actions'
import { select, put, call, takeEvery, race } from 'redux-saga/effects'
import {delay} from '@redux-saga/core/effects'
import {actions as loadingAction} from './loading'
import {actions as errorAction} from './error'
import {MESSAGES} from '../constants/messages'
import {EPSON_URL, SUMAKAGI_REQUEST_HEADER} from '../constants/api-config'
import {TAP_REQUEST_HEADER} from '../constants/settings'
import rollbar from './rollbar'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    api: payload => payload,
    print: payload => payload,
  },
  { prefix: 'hoshino/middleware'},
)
/***************************************************************
 *SAGA
 ***************************************************************/
export function* middlewareSaga() {
  yield takeEvery(actions.api, api)
  yield takeEvery(actions.print, print)
}

export function* api(action) {
  const {auth} = yield select()
  const {method, request, reqAuth, isCors, openLoadingFlag = 1, closeLoadingFlag = 1, methodOverride, hasXUserId, successCB, failedCB, doNotDisplayError = false, hostName, hostNameFlag, exceptionSubMessages = '', sleepTime} = action.payload
  let {url} = action.payload

  //ローディング表示
  if (openLoadingFlag === 1) {
    yield put(loadingAction.openLoading())
  } else if (openLoadingFlag === 2) {
    yield put(loadingAction.openLoading())
    yield put(loadingAction.onContinue())
  } else if (openLoadingFlag === 3) {
    yield put(loadingAction.openLoading(true))
  }

  try {
    if(!url.match(/http/)) {
      url = hostName + url
    }
    let options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...TAP_REQUEST_HEADER(url),
        ...SUMAKAGI_REQUEST_HEADER(url),
      },
    }
    if(isCors){
      options.mode = 'cors'
    }
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      options.body = JSON.stringify(request)
    }
    if (reqAuth) {
      options.headers.Authorization = 'Bearer ' + (hostNameFlag === 1 ? auth.auth.access_token : auth.memberUser.access_token)
    }
    if(methodOverride){
      options.headers['X-HTTP-Method-Override'] = 'GET'
    }
    if(hasXUserId) {
      options.headers['X-USER-ID'] = localStorage.getItem('userid')
    }

    if(sleepTime){
      yield sleep(sleepTime)
    }

    const { response, timeout } = yield race({
      response: call(fetch, url, options),
      timeout: delay(8000),
    })
    if (!response) {
      // 強制タイムアウト
      console.log(url)
      console.log(options)
      yield put(errorAction.displayError('API強制タイムアウト'))
      rollbar.error('API強制タイムアウト')
      return null
    }

    if (response.status >= 200 && response.status <= 299) {
      //200--------------------------------------------------------
      let json = ''
      try {
        json = yield response.json()
      } catch (e) {
        // レスポンスが空の場合、エラーが投げられるのでハンドリングだけ
      }

      if(successCB){
        if(typeof successCB === 'object'){
          yield put(successCB.cb({json: json, ...successCB.args}))
        }else{
          yield put(successCB(json))
        }
      }

      return json

    } else if (response.status === 401) {
      //401--------------------------------------------------------
      if (window.location.pathname === '/store/') {
        // TODO auth実装次第戻すこと
        // yield put(forceLogout())
        yield put(errorAction.displayError('401: 認証エラーです。再度ログインしてください'))
      } else {
        yield put(errorAction.displayError('401: 認証エラーです。'))
      }

    } else if (response.status === 504) {
      //504-------------------- ------------------------------------
      yield put(errorAction.displayError('504: 接続タイムアウトです'))
    } else {
      //その他エラー-------------------------------------------------
      let json = ''
      try {
        json = yield response.json()
      } catch (e) {
        // レスポンスが空の場合、エラーが投げられるのでハンドリングだけ
      }
      if (!doNotDisplayError) {
        const {message, messages} = json
        yield put(errorAction.displayError(message || messages))
        yield put(loadingAction.offContinue())

      }
      if(typeof failedCB === 'object'){
        yield put(failedCB.cb({json: json, ...failedCB.args}))
      } else if (failedCB){
        yield put(failedCB(json))
      }
    }
  } catch (e) {
    if (!doNotDisplayError) {
      yield put(errorAction.displayError(MESSAGES.ERROR.NETWORK_FAILED, exceptionSubMessages))
    }
  } finally {
    if (openLoadingFlag !== 2 && openLoadingFlag !== 4) {
      yield put(loadingAction.offContinue())
    }
  }

  return null
}

//スリープ処理
function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export function* print(action) {
  const {
    setting: {
      epsonUrl
    }
  } = yield select()

  const printerName = 'local_printer'
  const url = `https://${epsonUrl}/cgi-bin/epos/service.cgi?devid=${printerName}&timeout=60000`

  let {isCors, successCB, failedCB, exceptionSubMessages = ''} = action.payload

  let body = `<?xml version="1.0" encoding="UTF-8"?>
  <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
      <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        ${action.payload.body}
        <cut type="feed"/>
      </epos-print>
    </s:Body>
  </s:Envelope>`

  try {
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/xml; charset=UTF-8',
        'SOAPAction': '""',
      },
      body: body,
    }
    if(isCors){
      options.mode = 'cors'
    }

    const response = yield call(fetch, url, options)

    let is_success = true;
    const document = yield response.text().then((stringContainingXMLSource) => {
      const parser = new DOMParser();
      return parser.parseFromString(stringContainingXMLSource, 'text/xml');
    });
    // パース成功判定
    if(document.getElementsByTagName('parsererror').length){
      is_success = false
    }
    // レスポンスコード判定
    if (is_success && response.status !== 200 && response.status !== 201) {
      is_success = false
    }
    // 印刷ステータス判定
    if (is_success) {
      let dom_response = document.getElementsByTagName('response')
      let print_success = dom_response[0].getAttribute('success')
      if (print_success === 'false') {
        is_success = false
      }
    }

    if (is_success) {
      //200--------------------------------------------------------
      if (successCB){
        if (typeof successCB === 'object'){
          yield put(successCB.cb({document: document, ...successCB.args}))
        } else {
          yield put(successCB(document))
        }
      }

      return true

    } else {
      //その他エラー-------------------------------------------------
      yield put(errorAction.displayError('印字処理に失敗しました。'))

      if (failedCB) {
        if (typeof failedCB === 'object'){
          yield put(failedCB.cb({document: document, ...failedCB.args}))
        } else {
          yield put(failedCB(document))
        }
      }
    }
  } catch (e) {
    yield put(errorAction.displayError(MESSAGES.ERROR.PRINTER_FAILED, exceptionSubMessages))
  } finally {
  }

  return false
}
