import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Row,
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import {push} from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import CustomContainer from '../../components/atomics/CustomContainer'
import {RowCenter} from '../../util/styled'
import {containerStyle} from '../../constants/common'
import { reduxForm } from 'redux-form'
import Wizard from '../../components/atomics/StepWizard'
import TitleHeader from '../../components/atomics/TitleHeader'
import {actions as reserveAction} from '../../modules/reserve'
import Btn from '../../components/atomics/Button'
import IconBtn from '../../components/atomics/IconButton'


class SkipPassport extends Component {

  render() {
    const {t, i18n, image, imageCount, sum} = this.props

    return (
      <section style={{backgroundColor:'#fff', height:'100vh', overflow:'hidden'}}>
        <div style={{height:'10vh', display:'flex'}}>
          <IconBtn></IconBtn>
          <Wizard index={3}/>
          <IconBtn></IconBtn>
        </div>
        <Line/>
        <CustomContainer>
          <Container style={containerStyle}>
            <div>
                <MsgArea>
                  <Msg className={[i18n.language]}>
                    <div>{t('お客様の住所が日本国外の場合は\nパスポート登録が義務付けられております')}</div>
                    <div style={{paddingTop:'4vh'}}>{t('パスポートの読み込みをスキップしますか？')}</div>
                  </Msg>
                </MsgArea>
              <div>
                <Btn btnLabel={t('読み込む')} btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' style={{right: "auto",}} OnClick={()=>this.props.click_No()}/>
                <Btn btnLabel={t('スキップする')} btnFloat='right' btnWidth='30vw' btnHeight='10vh' fontSize='2rem' bottom='30vh' OnClick={()=>this.props.click_Skip()}/>
              </div>
            </div>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

SkipPassport.propTypes = {}

const mapStateToProps = state => {
  return {
    image: state.reserve.passportImage,
    imageCount: state.reserve.imageCount,
    sum: state.reserve.selectedReserve.map(reserve => reserve.NumberOfGuests).reduce((sum, num) => sum + num),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    click_No: () => {
      dispatch(push('/checkin/read_passport'))
    },
    click_Skip: () => {
      dispatch(reserveAction.checkIn())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'SkipPassport',
    destroyOnUnmount: false,
  })(withTranslation()(SkipPassport)))

const clear_both = {
  clear:'both'
}

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top:0;
`
const MsgArea = styled.div`
  font-size:2.7rem;
  line-height:6vw;
  margin:6vw auto;
  text-align:center;
`
const Msg = styled.p`
  font-size:2.7rem;
  text-align: center;
  white-space: pre-line;
  &.en {
    font-size:2rem;
  }
`