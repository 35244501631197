import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Row,
  Container,
  Button,
  Table,
} from 'reactstrap'
import { reduxForm } from 'redux-form'
import {ACC_COLOR, containerStyle} from '../../constants/common'
import {push} from 'connected-react-router'
import Header from '../../components/molecules/Header'
import CustomContainer from '../../components/atomics/CustomContainer'
import Paper from '../../components/atomics/Paper'
import CustomCard from '../../components/atomics/CustomCard'
import CustomInput from '../../components/atomics/CustomInput'
import {actions as reserveAction} from '../../modules/reserve'
import {ColAlignRight, RowCenter, StyledTh, StyledThead} from '../../util/styled'
import {isEmpty} from '../../util/common'

/**
 * 予約検索
 */
class ReserveSearch extends Component {
  render() {
    const {searchResult = [], formValues = {}} = this.props

    return (
      <section>
        <Header headerTitle={'宿泊予約一覧'}/>
        <CustomContainer>
          <Container style={containerStyle}>
            <Row>
              <ColAlignRight xs={{size: 3, offset: 9}}>
                <Button style={qrButtonStyle}>QRを読み込む</Button>
              </ColAlignRight>
            </Row>
            <Row>
              <Col xs={12}>
                <Paper>
                  <Row>
                    <Col xs={6}>
                      <RowCenter>
                        <CustomCard headerTitle={'お名前(フリガナ)'} cardWidth={'95%'}>
                          <CustomInput name="name" component="input" type="text" placeholder="タップして入力" />
                        </CustomCard>
                        <Button disabled={isEmpty(formValues.name)} onClick={()=>this.props.searchReserve('name')}>お名前で検索</Button>
                      </RowCenter>
                    </Col>
                    <Col xs={6}>
                      <RowCenter>
                        <CustomCard headerTitle={'予約番号'} cardWidth={'95%'}>
                          <CustomInput name="reserve_num" component="input" type="text" placeholder="タップして入力" />
                        </CustomCard>
                        <Button disabled={isEmpty(formValues.reserve_num)} onClick={()=>this.props.searchReserve('reserve_num')}>予約番号で検索</Button>
                      </RowCenter>
                    </Col>
                  </Row>
                </Paper>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Paper>
                  <Row>
                    <Table bordered striped>
                      <StyledThead>
                        <tr>
                          {TABLE_HEADERS.map((item, key) =>(<StyledTh key={key}>{item}</StyledTh>))}
                        </tr>
                      </StyledThead>
                      <tbody>
                        {searchResult.map(item => {
                          return (
                            <tr key={item.ReserveNo} onClick={()=>this.props.clickReserveRow(item)}>
                              <td>{item.memo ? '⚠︎' : ''}</td>
                              <td>{item.ReserveNo}</td>
                              <td>{item.detail ? item.detail.GuestName_Jpn : ''}</td>
                              <td>{item.GuestName}</td>
                              <td>{item.room ? item.room.RoomNo : ''}</td>
                              <td>{item.RoomNights}</td>
                              <td>{item.NumberOfGuests}</td>
                              <td>{item.ReservationRouteName}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                </Paper>
              </Col>
            </Row>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

ReserveSearch.propTypes = {}

const mapStateToProps = state => {
  return {
    searchResult: state.reserve.searchResult,
    formValues: state.form.reserveSearch ? state.form.reserveSearch.values : {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchReserve: searchType => dispatch(reserveAction.searchReserve(searchType)),
    clickReserveRow: item => {
      dispatch(reserveAction.selectReserve(item))
      dispatch(push('/reserve/confirm_passport'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'reserveSearch'
  })(ReserveSearch))

const qrButtonStyle = {
  background: ACC_COLOR,
  border: ACC_COLOR,
  height: '70px',
  width: '150px',
}
const TABLE_HEADERS = [
  '詳細',
  '予約番号',
  '代表者氏名',
  'フリガナ',
  '部屋番号',
  '宿泊数',
  '大人',
  '申込経路',
]