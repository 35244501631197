import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Container,
  Button,
  Label,
  Input,
  FormGroup
} from 'reactstrap'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { containerStyle } from '../../constants/common'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import TitleHeader from '../../components/atomics/TitleHeader'
import CustomContainer from '../../components/atomics/CustomContainer'
import { actions as settingAction } from '../../modules/setting'
import {push} from 'connected-react-router'

class ConfigPage extends Component {
  state = { 
    count: 0, 
    interval: 0
  };

  clickDummyBtn = () => {
    this.setState({ count: ++this.state.count });
    if (this.state.count === 1) {
      this.count = setInterval(() => {
        this.setState({interval: this.state.interval + 1})
        if (this.state.interval >= 3) {
          this.setState({count: 0, interval: 0})
          clearInterval(this.count)
        }
      }, 1000)
    }
    if (this.state.count === 5) {
      this.props.clickBtn('/config/setting_select')
    }
  }

  render() {
    const { t, i18n, cico, setParams, epsonUrl, ipadAppVersion } = this.props
    // console.log(vega3000Url)
    return (
      <section
        style={{ backgroundColor: '#fff', height: '100vh', overflow: 'hidden' }}
      >
        <div style={{ height: '10vh', display: 'flex' }}>
          <Row style={{ position: 'absolute', top: '3vh', left: '5vh' }}>
            <Logo className={[i18n.language]} onClick={this.clickDummyBtn}>
              <img
                src={`https://dfc78s572732n.cloudfront.net/logo/black/${i18n.language}/logo-bk3.png`}
                alt=""
                style={{ height: '4vw' }}
              />
            </Logo>
          </Row>
          <Row style={{ position: 'absolute', top: '3vh', right: '15vh' }}>
            <Button onClick={() => this.props.clickBtn('/config/setting_select')}>
              <IoMdArrowRoundBack />
            </Button>
          </Row>
        </div>
        <Line />
        <CustomContainer>
          <Container style={containerStyle}>
            <Row style={clear_both}>
              <TitleHeader
                headerWidth="100vw"
                marginTop="2vh"
                headerTitle={t('設定')}
              />
              <Col xs={12}>
                <FormGroup>
                  <Row>
                    <Col xs={2}>
                      <Label for="selectPrinter">
                        <Label className={i18n.language}>
                          {t('プリンター設定')}
                        </Label>
                      </Label>
                    </Col>
                    <Col>
                      <Input type="select" value={epsonUrl} onChange={(e)=>(setParams({epsonUrl: e.target.value}))}>
                        <option key="empty" value=""></option>
                        {cico.printer.map((item, key) => (
                          <option key={key} value={item.value_string}>
                            {`${item.key_name} ( ${item.value_string} )`}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    epsonUrl: state.setting.epsonUrl,
    cico: state.setting.cico
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setParams: params => {
      dispatch(settingAction.setParams(params))
    },
    clickBtn: (path, flg) => {
      dispatch(push(path))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ConfigPage))

/* CSS定義 */
const clear_both = {
  clear: 'both'
}

const Logo = styled.div`
  margin-right: auto;
`

const Line = styled.hr`
  clear: both;
  border: none;
  border-top: 1px #888888 solid;
  height: 1px;
  margin-top: 0;
  opacity: 0;
`
