import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  Col,
  Container,
  Button,
  Table,
} from 'reactstrap'
import {push, goBack} from 'connected-react-router'
import Header from '../../components/molecules/Header'
import CustomContainer from '../../components/atomics/CustomContainer'
import Paper from '../../components/atomics/Paper'
import {RowCenter, StyledTh, StyledThead} from '../../util/styled'
import {ACC_COLOR, containerStyle} from '../../constants/common'
import CustomCard from '../../components/atomics/CustomCard'
import styled from 'styled-components'

/**
 * 予約検索
 */
class ReserveRoomList extends Component {
  render() {
    const {selectedReserve} = this.props
    const {
      GuestName,
      Telephone,
      Cellphone,
      detail,
    } = selectedReserve

    //TODO dummy
    const rooms = selectedReserve.rooms ? selectedReserve.rooms : [{
      id: '',
      adult_num: '',
      child_a_num: '',
      child_b_num: '',
      child_c_num: '',
      child_d_num: '',
      key_type: 'カード'
    }]

    return (
      <section>
        <Header headerTitle={'宿泊者照会'}/>
        <CustomContainer>
          <Container style={containerStyle}>
            <RowCenter>
              <Col xs={8}>
                <Paper height='75vh'>
                  <RowCenter>
                    <Message>チェックインする部屋を選択してください。</Message>
                    <Table bordered striped>
                      <StyledThead>
                        <tr>
                          {TABLE_HEADERS.map((item, key) =>(<StyledTh key={key}>{item}</StyledTh>))}
                        </tr>
                      </StyledThead>
                      <tbody>
                        {rooms.map(room => {
                          return (
                            <tr key={room.id} onClick={()=>this.props.clickReserveRow()}>
                              <td>{room.id}</td>
                              <td>{room.adult_num}</td>
                              <td>{room.child_a_num}</td>
                              <td>{room.child_b_num}</td>
                              <td>{room.child_c_num}</td>
                              <td>{room.child_d_num}</td>
                              <td>{room.key_type}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <Button style={checkInButtonStyle} onClick={()=>this.props.clickCheckInButton()}>
                      チェックインする
                    </Button>
                  </RowCenter>
                </Paper>
              </Col>
              <Col xs={4}>
                <Paper height='75vh'>
                  <RowCenter>
                    <CustomCard headerTitle={'氏名(フリガナ)'}>
                      {GuestName}
                      {`(${detail.GuestName_Jpn})`}
                    </CustomCard>
                    <CustomCard headerTitle={'郵便番号'} cardWidth={'93%'}>
                      {detail.ZipCode}
                    </CustomCard>
                    <CustomCard headerTitle={'連絡先(固定電話)'} cardWidth={'93%'}>
                      {Telephone}
                    </CustomCard>
                    <CustomCard headerTitle={'連絡先(携帯電話)'} cardWidth={'93%'}>
                      {Cellphone}
                    </CustomCard>
                    <Button style={editButtonStyle} onClick={()=>this.props.clickEditCustomerButton()}>
                    お客様情報を修正する
                    </Button>
                  </RowCenter>
                </Paper>
              </Col>
            </RowCenter>
          </Container>
        </CustomContainer>
      </section>
    )
  }
}

ReserveRoomList.propTypes = {}

const mapStateToProps = state => {
  return {
    selectedReserve: state.reserve.selectedReserve
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clickReturn: () => dispatch(goBack()),
    clickCheckInButton: () => dispatch(push('/reserve/key_register')),
    clickEditCustomerButton: () => dispatch(push('/customer/edit')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveRoomList)

const checkInButtonStyle = {
  background: ACC_COLOR,
  border: ACC_COLOR,
  height: '48px',
  width: '80%',
}
const editButtonStyle = {
  background: ACC_COLOR,
  border: ACC_COLOR,
  height: '48px',
  width: '90%',
}
const TABLE_HEADERS = [
  '号室',
  '大人',
  'A',
  'B',
  'C',
  'D',
  '鍵',
]
const Message = styled.p`
  color: ${ACC_COLOR};
  font-size: 20px;
`